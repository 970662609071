import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { SMALL, LARGE, EXPAND } from 'components/constants'
import { BRAND, NOTIFY } from 'components/constants'

import Accordion from 'components/shared/Accordion'
import Icon from 'components/shared/Icon'
import CartItemPriceBreakdown from './CartItemPriceBreakdown'
import CartLineItem from './CartLineItem'
import CheckoutSerialFields from 'components/checkout/shared/CheckoutSerialFields'
import FulfillmentPicker from './FulfillmentPicker'
import ProductPricingDropdown from 'components/products/ProductPricingDropdown'
import QuantityPicker from 'components/shared/QuantityPicker'
import Record from 'components/shared/Record'
import Tag from 'components/shared/Tag'

import getAnimation from 'helpers/getAnimation'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

export default function CartItem({
  className,
  outlets,
  cartItem = {},
  user = {},
  onDecrement,
  onIncrement,
  pushDiscount,
  onSetSerials,
  onSetPrice,
  onSetFulfillment,
  onDeleteFulfillment,
  animation,
  onClickDiscount,
}) {
  const [quantity, setQuantity] = useState(cartItem.quantity)
  const [discount, setDiscount] = useState(cartItem.discount || 0)
  const [fulfillmentPickerOpen, setFulfillmentPickerOpen] = useState(false)

  function increment() {
    setQuantity(quantity + 1)
    onIncrement(cartItem.id)
  }

  function decrement() {
    setQuantity(quantity - 1)
    onDecrement(cartItem.id)
  }

  useEffect(() => {
    pushDiscount && setDiscount(pushDiscount.amount)
  ,[pushDiscount]})

  return (
    <motion.section
      {...getAnimation(animation)}
      className={classNames({
        'expand-x bg-white color-shade-7': true,
        [className]: className,
      })}
    >
      <header className='sticky top-0 z-2 bg-white shadow-2 flex col ps-2 mb-2'>
        <CartLineItem
          isReturnItem={cartItem.quantity < 0}
          showPrice={false}
          cartItem={cartItem}
          imageHeight={90}
          size={LARGE}
        />
        <QuantityPicker
          width='40%'
          size={SMALL}
          theme={BRAND}
          shade={4}
          quantity={quantity}
          onDecrement={decrement}
          onIncrement={increment}
          skipOverZero={true}
        />
        <div className='relative flex align-items-baseline mt-2 mb-3'>
          {cartItem.variable_pricing_amounts.length > 0 && (
            <ProductPricingDropdown
              name='cart_item_price_selector'
              product={cartItem}
              onSetPrice={(price) => onSetPrice(price)}
            />
          )}
          {cartItem.variable_pricing_amounts.length === 0 && (
            <Record
              label='Unit price'
              className='ps-3'
              data-unit-price
            >
              {getFormattedPrice(cartItem.price - discount)}
            </Record>
          )}
          {quantity > 1 && (
            <Record
              label='Total price'
              className='ps-4'
              data-total
            >
              {getFormattedPrice((cartItem.price * quantity) - (discount * quantity))}
              {discount > 0 && <Tag theme={NOTIFY} size={SMALL} text={getFormattedPrice(discount * quantity) + ' discount'} />}
              {discount < 0 && <Tag theme={NOTIFY} size={SMALL} text={getFormattedPrice((discount*-1) * quantity) + ' extra'} />}
            </Record>
          )}
        </div>
      </header>
      <div className='relative z-neutral flex col'>
        <menu className='bg-shade-2 flex col gap-1px'>
          {user.maximumDiscountPercentage !== null &&
            user.maximumDiscountPercentage > 0 && (
              <button 
                className={'flex align-items-center justify-space-between ps-4 bg-white input-height-2 font-size-2 font-weight-3 shadow-1'}
                onClick={() => onClickDiscount({cartItem: cartItem, user: user})}>
                <span>Adjust Price</span>
                <span className='opacity-05 flex align-items-center'>
                  <Icon
                    type={EXPAND}
                    size={18}
                  />
                </span>
              </button>
          )}
          <Accordion
            headline={quantity > 1 ? 'Serial numbers' : 'Serial number'}
            open={cartItem.requires_serial}
            rounded={0}
          >
            <CheckoutSerialFields
              id={cartItem.id}
              className='pb-4 ps-4 gap-3'
              required={cartItem.requires_serial}
              serialNumbers={cartItem.serial_numbers}
              quantity={quantity}
              onChange={(serials) => onSetSerials(cartItem.id, serials)}
            />
          </Accordion>
          {((cartItem.cost_price !== undefined &&
            cartItem.cost_price !== null) || 
            (cartItem.minimum_sell_price !== undefined &&
              cartItem.minimum_sell_price !== null)
            ) && (
            <Accordion
              headline='Cost &#38; sell information'
              rounded={0}
            >
              <CartItemPriceBreakdown
                className='ps-4'
                costPrice={cartItem.cost_price}
                minSellPrice={cartItem.minimum_sell_price}
                price={cartItem.price}
                quantity={quantity}
              />
            </Accordion>
          )}
          <Accordion
            rounded={0}
            headline={'Hold, Transfer, Special Order'}
            className='overflow-unset z-1'
            openCallback={(open) => setFulfillmentPickerOpen(open)}
          >
            {<FulfillmentPicker
              id={cartItem.id}
              cartItem={cartItem}
              open={fulfillmentPickerOpen}
              outlets={outlets}
              onChange={(value) => () => alert(value)}
              onSetFulfillment={(payload) => onSetFulfillment(payload)}
              onDeleteFulfillment={(payload) => onDeleteFulfillment(payload)}
            />}
          </Accordion>
        </menu>
      </div>
    </motion.section>
  )
}
