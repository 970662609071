import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { COLUMN, ROW } from 'components/constants'
import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const ButtonMenu = ({
  animation,
  children,
  className,
  gridTemplateColumns,
  delay = 0,
  direction = ROW,
  id,
  dataTestId,
  maxWidth,
  rounded = 0,
  scroll = false,
  shade,
  size,
  style,
  theme,
}) => {
  return (
    <motion.menu
      data-button-menu={dataTestId}
      id={id}
      {...getAnimation(animation, delay)}
      style={{ ...style,
        gridTemplateColumns,
        maxWidth: maxWidth,
      }}
      className={classNames({
        'grid': !!gridTemplateColumns,
        'flex': direction === ROW && !gridTemplateColumns,
        'flex col': direction === COLUMN && !gridTemplateColumns,
        'expand-x': direction === COLUMN,
        'overflow-y-auto': scroll === true,
        'offset-button-animation': !!animation && direction === ROW,
        ['rounded-' + rounded]: rounded > 0,
        ['input-height-' + size]: size,
        [getThemeStyleClass(theme, shade)]: theme,
        [className]: className,
      })}>
      {children}
    </motion.menu>
  )
}

export default ButtonMenu
