import React from 'react'

const OnlineStatus = ({ online, syncing, onClick }) => {
  const backgroundColor = getBackgroundColor()
  const size = 10

  return (
    <span
      id='onlineStatus'
      className='flex gap-2 align-items-center align-self-stretch ps-4 cursor-pointer'
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <span
        id='onlineStatusDot'
        style={{
          width: size,
          height: size,
          borderRadius: '100%',
          backgroundColor,
        }}
      />
      <span className='font-weight-3 font-size-3'>
        {online && syncing && 'Syncing'}
        {online && !syncing && 'Online'}
        {!online && 'Offline'}
      </span>
    </span>
  )

  function getBackgroundColor() {
    if (online && syncing) {
      return 'orange'
    } else if (online) {
      return 'green'
    } else {
      return 'red'
    }
  }
}

export default OnlineStatus
