import React from 'react'
import classNames from 'classnames'

import { SMALL } from 'components/constants'
import { BRAND, LAYBY } from 'components/constants'

import Tag from 'components/shared/Tag'
import Record from 'components/shared/Record'

import getFormattedPrice from 'components/helpers/getFormattedPrice'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

const CheckoutSummaryBlock = ({
  onClick,
  className,
  paid,
  type,
  total,
  cartCount,
  withRefund = false,
  refundAmount = 0,
  refunded = 0,
  shade = 2,
  theme = BRAND,
}) => {
  if (onClick)
    return (
      <button
        type='button'
        onClick={onClick}
        className={classNames({
          'text-left flex gap-6 rounded-3 pe-4 pl-5 pr-6': true,
          [getThemeStyleClass(theme, shade)]: true,
          [className]: className,
        })}
      >
        {getTotal(total)}
        {getPaid(paid)}
        {getTotalRefund(refundAmount)}
        {getRefunded(refunded)}
        {getItemCount(cartCount)}
      </button>
    )
  return (
    <div
      className={classNames({
        'flex gap-6 rounded-3 pe-4 pl-5 pr-6': true,
        [getThemeStyleClass(theme, shade)]: true,
        [className]: className,
      })}
    >
      {getTotal(total)}
      {getPaid(paid)}
      {getTotalRefund(refundAmount)}
      {getRefunded(refunded)}
      {getItemCount(cartCount)}
    </div>
  )
  function getTotal(total) {
    return (
      <Record label={'Order total'} data-owed>
        {getFormattedPrice(convertToAbsoluteAmountValue(total))}
        {type === LAYBY && (
          <Tag text='layby' theme={LAYBY} size={SMALL} />
        )}
      </Record>
    )
  }

  function getPaid(paid) {
    if (paid === null || paid === undefined) return null

    return (
      <Record label={'Amount paid'} data-paid>
        {getFormattedPrice(convertToAbsoluteAmountValue(paid))}
      </Record>
    )
  }

  function getTotalRefund(refundAmount) {
    if (!withRefund) return null

    return (
      <Record label={'Refund total'} data-paid>
        {getFormattedPrice(convertToAbsoluteAmountValue(refundAmount))}
      </Record>
    )
  }

  function getRefunded(refunded) {
    if (!withRefund) return null

    return (
      <Record label={'Amount refunded'} data-paid>
        {getFormattedPrice(convertToAbsoluteAmountValue(refunded))}
      </Record>
    )
  }

  function getItemCount(cartCount) {
    return <Record label='Items'>{convertToAbsoluteAmountValue(cartCount)}</Record>
  }
}

export default CheckoutSummaryBlock
