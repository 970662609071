import getSubtotal from './getSubtotal'
import getTaxTotal from './getTaxTotal'
import getTotal from './getTotal'
import getDiscountTotal from './getDiscountTotal'
import getOrderDiscountPercent from './getOrderDiscountPercent'

export default function getPaymentData(
  cartItems = [],
  taxInclusive = false,
) {
  const subtotal = getSubtotal(cartItems)
  const discount = getDiscountTotal(cartItems)
  const orderDiscount = getOrderDiscountPercent(cartItems)
  const taxes = cartItems.flatMap(i => i.taxes)
  const taxTotal = getTaxTotal(taxes)
  return {
    subtotal,
    discount,
    orderDiscount,
    taxTotal,
    taxes,
    total: getTotal(
      subtotal,
      taxTotal,
      discount,
      orderDiscount,
      taxInclusive,
    ),
  }
}
