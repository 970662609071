import { SET_VIEW_ID } from '../constants'

export default function (state = null, action) {
  switch (action.type) {
    case SET_VIEW_ID:
      const id = action.id || "DASHBOARD"
      setLocalStorage(id)
      return id
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('viewId', JSON.stringify(payload))
}
