import { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'

import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchPrintableLabels } from 'data/api/fetchPrintableLabels'

import UpdateContext from 'components/settings/actions/UpdateContext'

import getLocalStorage from 'data/helpers/getLocalStorage'
import getSyncTimeout from 'data/helpers/getSyncTimeout'
import setLocalStorage from 'data/helpers/setLocalStorage'
import SetSyncInProgress from 'components/online/actions/SetSyncInProgress'
import SetPrintableLabels from 'components/printableLabels/actions/SetPrintableLabels'

const useSyncPrintableTemplates = ({ poll = false, executeOnLoad = true }) => {
  const syncTimeout = getSyncTimeout()
  const dispatch = useDispatch()
  const printingContexts = useSelector((state) => state.settings.contexts)
  const [printableLabels, setPrintableLabels] = useState(getLocalStorage('printableLabels', []))
  const [fetching, setFetching] = useState(false)

  let syncInterval = null

  useEffect(() => {
    if (executeOnLoad === false) return

    if (navigator.onLine) {
      setFetching(true)
      fetchPrintableTemplates()

      if (poll = false) return
      syncInterval = setInterval(() => {
        fetchCacheVersions('printable_labels', () => {
          fetchPrintableTemplates()
        })
      }, syncTimeout)
    }
    return () => clearInterval(syncInterval)
  }, [])

  // Update fetching state and local storage when printable label change
  // TODO: Work on this after world tour, it has some duplicates
  useEffect(() => {
    setPrintableLabels(printableLabels)
    dispatch(SetPrintableLabels(printableLabels))
    setLocalStorage('printableLabels', printableLabels)
    updatePrintingContext()
  }, [printableLabels])

  useEffect(() => {
    dispatch(SetSyncInProgress('printable_labels', fetching))
  }, [fetching])

  function fetchPrintableTemplates(successCallback = undefined) {
    fetchPrintableLabels((printableLabels) => {
      setFetching(false)
      setPrintableLabels(printableLabels)
      if (successCallback) successCallback()
    }, () => {
      setFetching(false)
    })
  }

  // Always make sure to update the selected printing context in local storage
  function updatePrintingContext() {
    if (printableLabels.length === 0 || printingContexts.length === 0) return;
  
    printableLabels.forEach(printableLabel => {
      printingContexts.forEach(context => {
        if (context.template !== null && (context.templateId === printableLabel.id)) {
          const updatedContext = { ...context, templateName: printableLabel.name, template: printableLabel.label_template }
          dispatch(UpdateContext(updatedContext))
        }
      })
    })
  }

  return {
    printableLabels,
    fetching,
    fetchPrintableTemplates,
    setPrintableLabels,
  }
}

export default useSyncPrintableTemplates
