import React from 'react'
import classNames from 'classnames'
import { parsePhoneNumber } from 'libphonenumber-js'

import { log } from 'dev/log'
import { COUNTRY_CODE } from 'components/constants'

import ButtonLineItem from 'components/shared/ButtonLineItem'
import Icon from 'components/shared/Icon'

const SupplierLineItem = ({
  supplier,
  className,
  children,
  gridTemplateColumns = '1fr 1fr 1fr auto',
  first = false,
  last = false,
  icon,
  onClick,
}) => {

  function renderPhoneNumber() {
    if (supplier.phone === null) return null

    // We don't have control to libphonenumber-js
    // as to what is an invalid phone number 
    try {
      return (
        <span className='font-size-3 font-weight-2 opacity-07'>
          {parsePhoneNumber(supplier.phone, COUNTRY_CODE).formatNational()}
        </span>
      )
    } catch (error) {
      log(error)
    }
  }

  return (
    <ButtonLineItem
      padding={4}
      gridTemplateColumns={gridTemplateColumns}
      onClick={() => onClick(supplier)}
      className={classNames({
        'bg-shade-0 align-items-center': true,
        'rounded-top-2': first === true,
        'rounded-bottom-2': last === true,
        [className]: className,
      })}
    >
      <span className='font-size-3 font-weight-3'>
        {supplier.name}
      </span>
      {renderPhoneNumber()}
      {children}
      {icon && <Icon size={24} type={icon} />}
    </ButtonLineItem>
  )
}

export default SupplierLineItem