import { log } from 'dev/log'

export default function getLocalStorage(key, defaultValue = null) {
  try {
    return JSON.parse(window.localStorage.getItem(key)) || defaultValue
  } catch(e) {
    log(window.localStorage.getItem(key))
    log(e)
    return defaultValue
  }
}
