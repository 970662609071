import React, { useState, useEffect } from 'react';

const ToggleSwitch = ({ onToggle, checked = false, disabled = false }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const toggleSwitch = () => {
    setIsChecked(!isChecked)

    if (onToggle) {
      onToggle(!isChecked)
    }
  }

  return (
    <div
      className={`toggle-switch ${isChecked ? 'checked' : ''}`}
      onClick={() => {
        if (disabled) return
        toggleSwitch()
      }}
    >
      <div className="toggle-slider" />
    </div>
  )
}

export default ToggleSwitch