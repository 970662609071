import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import htmlParser from 'html-react-parser'

import { BRAND, MEDIUM, NOTIFY, SUCCESS } from 'components/constants'
import { SLIDE_NEXT } from 'components/constants'
import { modalCopy } from 'components/fulfillments/constants'

import Form from 'components/shared/Form'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Checkbox from 'components/shared/Checkbox'

const FulfillmentSummaryModal = ({
  errorSyncing = false,
  width = 620,
  rounded = 4,
  theme = BRAND,
  onConfirm,
  onClose,
  zIndex = 2,
  syncing = false,
  fulfillmentItems = [],
  onCloseFulfillment,
  onUpdateFulfillmentItems,
  status,
}) => {
  let gridTemplateColumns = '2fr 1fr 1fr'
  const [modalHasConfirmed, setModalHasConfirmed] = useState(false);
  const [hasDeficits, setHasDeficits] = useState(false)
  const [confirmed, setConfirmed] = useState({})
  const [buttonActive, setButtonActive] = useState(hasDeficits ? false : true)

  // Check if there are deficits on load
  useEffect(checkDeficits, [])

  useEffect(setConfirmedItems, [fulfillmentItems])

  function setConfirmedItems() {
    let confirmed = {}
    fulfillmentItems.forEach((item) => {
      if (item.deficit > 0) confirmed[item.id] = false
      else confirmed[item.id] = true
    })
    setConfirmed(confirmed)
  }

  function checkDeficits() {
    let items = [...fulfillmentItems]
    let hasDeficits = false

    items.forEach((item) => {
      if (item.deficit > 0) hasDeficits = true
    })

    if (!hasDeficits) gridTemplateColumns = "2fr 1fr 1fr 1fr"

    setButtonActive(!hasDeficits)
    setHasDeficits(hasDeficits)
    onUpdateFulfillmentItems(items)
  }

  function handleButtonsStates(confirmed) {
    if (Object.keys(confirmed).every((key) => confirmed[key] === true)) {
      setButtonActive(true)
    } else if (buttonActive) {
      setButtonActive(false)
    }
  }

  function handleCheck(val, item) {
    let updatedConfirmed = { ...confirmed }
    updatedConfirmed[item.id] = val
    setConfirmed(updatedConfirmed)
    handleButtonsStates(updatedConfirmed)
  }

  function handleConfirm() {
    onConfirm(() => setModalHasConfirmed(true))
  }

  function renderLineItem(item) {
    return (
      <div
        key={item.id}
        className="grid"
        style={{ gridTemplateColumns }}
        data-testid={`fulfillment-item-${item.id}`}
      >
        <span className="pe-2" key={item.name} style={{ gridColumn: "1/2" }}>{item.description}</span>
        <span data-testid="expected-quantity" className="pe-2" style={{ gridColumn: "2/3" }}>
          {item.quantity}
        </span>
        <span data-testid="missing-quantity" className="pe-2" style={{ gridColumn: "3/4" }}>
          <span className={(item.deficit > 0) ? "theme-notify-3 ps-2 pe-1 rounded-2" : ""}>
            {item.deficit || 0}
          </span>
        </span>
        {item.deficit > 0 &&
          <span className="pe-2" style={{ gridColumn: "4/5" }}>
            <Checkbox
              dataTestId={`confirm-checkbox-${item.id}`}
              id={`confirm_${item.id}`}
              checked={false}
              onChange={(val) => handleCheck(val, item)}
            />
          </span>
        }
      </div>
    )
  }

  return (
    <div data-testid="fulfillment-items-confirmation-modal">
      {!modalHasConfirmed && (
        <Form
          maxWidth={width}
          shade={0}
          shadow={4}
          rounded={rounded}
          theme={theme}
          className={classNames({ ['z-' + zIndex]: !!zIndex })}
        >
          <Container
            animation={SLIDE_NEXT}
            className='p-5'
          >
            {errorSyncing === true && (
              <p className="mb-4 font-size-3 color-notify-5">
                Sorry.. We encountered a problem updating the record. Please check your connection and try again.
              </p>
            )}
            {errorSyncing === false && (
              <>
                <p className="mb-4 font-size-3">
                  {hasDeficits ? htmlParser(modalCopy[status]['inDeficit']) : htmlParser(modalCopy[status]['allFilled']) }
                </p>
                <div className="grid" style={{ gridTemplateColumns: gridTemplateColumns }}>
                  <span style={{ gridColumn: "1/2" }}>Item</span>
                  <span style={{ gridColumn: "2/3" }}>Expected</span>
                  <span style={{ gridColumn: "3/4" }}>Missing</span>
                  {hasDeficits &&
                    <span style={{ gridColumn: "4/5" }}>Confirm</span>
                  }
                  <hr className="border-bottom color-shade-5 font-size-2 font-weight-2 expand-x" style={{ gridColumn: hasDeficits ? "1/5" : "1/4", borderWidth: "0 0 1px 0" }} />
                </div>
                <div className="overflow-y-scroll" style={{ maxHeight: "40dvh" }}>
                  {fulfillmentItems.map((item) => renderLineItem(item))}
                </div>
              </>
            )}
          </Container>
          <ButtonMenu className='flex mt-4'>
            <Button
              shade={3}
              rounded={0}
              disabled={!buttonActive || syncing}
              theme={theme}
              onClick={() => handleConfirm()}
              size={MEDIUM}
              className={'grow'}
            >
              Confirm
            </Button>
            <Button
              shade={2}
              rounded={0}
              theme={NOTIFY}
              onClick={() => onClose()}
              size={MEDIUM}
              className={'grow'}
            >
              Cancel
            </Button>
          </ButtonMenu>
        </Form>
      )}
      {modalHasConfirmed && (
        <Form
          maxWidth={width}
          shade={0}
          shadow={4}
          rounded={rounded}
          theme={theme}
          className={classNames({ ['z-' + zIndex]: !!zIndex })}
          fullWidth={true}
        >
          <Container
            animation={SLIDE_NEXT}
            className='p-5'
          >
            <div className='text-center'>
              <h3>This record has been updated successfully</h3>
            </div>
          </Container>
          <ButtonMenu className='flex'>
            <Button
              shade={3}
              rounded={0}
              theme={SUCCESS}
              onClick={() => onClose()}
              size={MEDIUM}
              style={{ width: "50%" }}
            >
              Continue
            </Button>
            <Button
              shade={3}
              rounded={0}
              disabled={!buttonActive}
              theme={theme}
              onClick={() => onCloseFulfillment()}
              size={MEDIUM}
              className={''}
              style={{ width: "50%" }}
            >
              Go back
            </Button>
          </ButtonMenu>
        </Form>
      )}
    </div>
  )
}

export default FulfillmentSummaryModal