import { LOCALE, CURRENCY_CODE } from 'constants/settings'
import getCurrencyCode from './getCurrencyCode'

export default function getFormattedPrice(
  amount = null,
  stripTrailingZeros = false,
  breakStringAtCharacter,
) {
  if (amount === null) return null
  const options = {
    style: 'currency',
    currency: getCurrencyCode(),
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: stripTrailingZeros ? 0 : 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: stripTrailingZeros ? 'stripIfInteger' : 'auto',
  }

  let priceString = new Intl.NumberFormat(LOCALE, options).format(amount)
  if (breakStringAtCharacter && priceString.length > breakStringAtCharacter) {
    let result = '';
    while (priceString.length > 0) {
      result += priceString.substring(0, breakStringAtCharacter) + '\n';
      priceString = priceString.substring(breakStringAtCharacter);
    }
    priceString = result;
  }

  return priceString
}
