import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { LARGE, HUGE } from 'components/constants'
import { COLUMN } from 'components/constants'
import { BRAND, NEUTRAL } from 'components/constants'
import { SLIDE_UP, NOTIFY } from 'components/constants'
import { CARD,  DOLLAR } from 'components/constants'
import { TYRO_STATUSES } from 'components/checkout/constants'

import {
  PRINTER_TYPES,
  PRINTABLE_TYPES,
} from 'constants/settings'
import useHandlePrinting, { FAILED_CONNECTION } from 'hooks/useHandlePrinting'

import Banner from 'components/shared/Banner'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import getMaximumRefundAmount from 'components/checkout/helpers/getMaximumRefundAmount'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

export default function TyroPaymentForm({
  animation,
  className,
  drawerError = null,
  isRefund = false,
  center = true,
  layby = false,
  syncingOrder = false,
  theme = BRAND,
  onSubmit,
  owed = 0,
  payments = [],
}) {
  const [amount, setAmount] = useState()
  const [paymentStatus, setPaymentStatus] = useState(TYRO_STATUSES.pending)
  const settings = useSelector((state) => state.settings.tyro)

  const {
    deviceError,
    isDeviceAvailable,
    print,
  } = useHandlePrinting(PRINTER_TYPES.RECEIPT, PRINTABLE_TYPES.ORDER)
  const printerAvailable = isDeviceAvailable(PRINTER_TYPES.RECEIPT)

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('amount').focus()
  }, [])

  const tyroReceiptTemplate = "{document\n  word-wrap=true\n}\n {text \"{{ data.receipt  }}\" }";

  function onPrintReceipt(str) {
    print({ receipt: str }, tyroReceiptTemplate)
  }

  function submit(amount) {
    const amountStr = Math.round(amount * 100).toString()

    setPaymentStatus(TYRO_STATUSES.pending)
    if (isRefund) {
      window.tyroClient.initiateRefund({
        amount: amountStr,
        integratedReceipt: !!settings.printTyroReceipt,
      }, {
          receiptCallback: handleMerchantReceipt,
          transactionCompleteCallback: transactionCompleteCallbackImpl
      })
    } else {
      window.tyroClient.initiatePurchase({
          amount: amountStr,
          enableSurcharge: true,
          integratedReceipt: !!settings.printTyroReceipt,
      }, {
          receiptCallback: handleMerchantReceipt,
          transactionCompleteCallback: transactionCompleteCallbackImpl
      });
    }
  }

  function handleMerchantReceipt(receipt) {
    if (receipt.signatureRequired || settings.alwaysPrintMerchantReceipt) {
      onPrintReceipt(receipt.merchantReceipt);
    }
  }

  function paymentResponse(transaction) {
    return JSON.stringify({
      cardType: transaction.cardType,
      transactionReference: transaction.transactionReference,
      authorisationCode: transaction.authorisationCode,
      issuerActionCode: transaction.issuerActionCode,
      elidedPan: transaction.elidedPan,
      rrn: transaction.rrn,
      surchargeAmount: transaction.surchargeAmount,
      tipAmount: transaction.tipAmount,
    })
  }

  function transactionCompleteCallbackImpl(transaction) {
    const surcharge = parseFloat(transaction.surchargeAmount) 
    const amount = parseFloat(transaction.baseAmount) 
    const tipAmount = transaction.tipAmount ? (parseFloat(transaction.tipAmount) / 100.0) : 0.0

    const newOrderTotal = owed + surcharge + tipAmount

    if (settings.printTyroReceipt && transaction.customerReceipt) {
      onPrintReceipt(transaction.customerReceipt)
    }

    switch (transaction.result) {
        case TYRO_STATUSES.approved:
          onSubmit({
            amountTendered: amount + tipAmount,
            amountOwed: newOrderTotal, 
            paymentDetails: transaction.transactionId,
            paymentType: transaction.cardType,
            surcharge: surcharge,
            response: paymentResponse(transaction),
            tip: tipAmount
          })
        case TYRO_STATUSES.declined:
        case TYRO_STATUSES.cancelled:
        case TYRO_STATUSES.error:
        case TYRO_STATUSES.reversed:
        default:
    }
    setPaymentStatus(transaction.result)
  }

  function headline() {
    if (layby === true) return 'Payment amount'
    if (payments.length > 0) return 'Amount charged'
    else return 'Amount to pay'
  }

  return (
    <Form
      gridTemplateRows='1fr auto'
      shade={0}
      theme={NEUTRAL}
      animation={animation}
      className={classNames({
        'expand': true,
        [className]: className,
      })}
    >
      <Container maxWidth={800} center={center}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon shade={3} type={CARD} size={LARGE} theme={theme} />
          Card (Integrated)
        </span>
        <Heading>{headline()}</Heading>
        <div
          className={classNames({
            'flex gap-2': true,
            'row-reverse justify-end': layby === true,
          })}>
          <span>
            <Field
              id='amount'
              name='amount'
              label='Custom amount'
              width={340}
              border={true}
              icon={DOLLAR}
              type='number'
              max={isRefund ? owed : undefined}
              onChange={(value) => {
                setAmount(getMaximumRefundAmount(value, owed, isRefund))
              }}
            />
          </span>
        </div>
      </Container>
      {(paymentStatus !== TYRO_STATUSES.pending && paymentStatus !== TYRO_STATUSES.approved) && (
        <Banner
          direction={COLUMN}
          animation={SLIDE_UP}
          theme={NOTIFY}
          shade={3}
          message={`Oops! Something went wrong. The transaction returned ${paymentStatus}.`}
        />
      )}
      {(deviceError !== null || printerAvailable === false) && (
        <Banner
          direction={COLUMN}
          animation={SLIDE_UP}
          theme={NOTIFY}
          shade={3}
          message={deviceError || FAILED_CONNECTION}
        />
      )}
      {drawerError}
      <ButtonMenu
        direction={COLUMN}
        animation={SLIDE_UP}
        delay={{ delay: 0.2 }}>
        {!amount && (
          <Button
            shade={5}
            size={HUGE}
            theme={theme}
            disabled={layby || syncingOrder}
            onClick={() => submit(owed)}
          >
            {isRefund === true && <>Amount to refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(owed))}</>}
            {isRefund === false && <>{layby ? 'Amount remaining' : 'Amount to pay'}&nbsp;{getFormattedPrice(owed)}</>}
          </Button>
        )}
        {amount && amount !== 0 && (
          <Button
            shade={5}
            size={HUGE}
            theme={theme}
            disabled={syncingOrder}
            onClick={() => submit(amount)}
          >
            {isRefund === true && <>Submit refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(amount))}</>}
            {isRefund === false && <>Submit payment&nbsp;{getFormattedPrice(amount)}</>}
          </Button>
        )}
      </ButtonMenu>
    </Form>
  )
}
