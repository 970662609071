import React, { useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence } from 'framer-motion'
import { startCase } from 'lodash'

import { BRAND, NEUTRAL, MEDIUM, LARGE, CHECK_MARK, NOTIFY } from 'components/constants'
import { NEXT } from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import SettingLineItem from 'components/settings/SettingLineItem'
import ToggleSwitch from 'components/shared/ToggleSwitch'
import Icon from 'components/shared/Icon'

const PrinterDetails = ({
  printer,
  onDrawerUpdate,
  drawer,
  animation = NEXT,
  className,
  theme = BRAND,
  shadow = 0,
  rounded = 0,
  width,
  height,
  interpolateDeviceLabel,
  onBack,
  onConnect,
  onDisconnect,
  onDrawerOptionClick,
}) => {
  const [showDrawerOptions, setShowDrawerOptions] = useState(printer.drawerPresent)

  return (
    <Article
      gridTemplateRows='auto auto 1fr auto'
      className={classNames({
        'overflow-hidden expand': true,
        [className]: className,
      })}
      shade={1}
      theme={NEUTRAL}
      rounded={rounded}
      shadow={shadow}
      maxWidth={width}
      maxHeight={height}
      animation={animation}
    >
      <ButtonMenu
        shade={1}
        theme={NEUTRAL}
        className='gap-1px p-4 mb-4 justify-space-between align-items-center'
      >
        <Button
          shade={1}
          rounded={4}
          size={MEDIUM}
          theme={theme}
          onClick={onBack}
        >
          <>Back</>
        </Button>
        <span className='grow font-weight-3 font-size-5 text-center'>
          {printer.device === null ? printer.label : interpolateDeviceLabel(JSON.parse(printer.device))}
        </span>
        {printer.device !== null ? (
          <span>
            <Button
              shade={1}
              rounded={4}
              size={MEDIUM}
              theme={NOTIFY}
              onClick={() => onDisconnect(printer)}
            >
              <>Disconnect</>
            </Button>
          </span>
        ) : (
          <span style={{ width: 100 }}></span>
        )}
      </ButtonMenu>

      {printer.shouldInquireAboutDrawerPresence && (
        <div className="ps-8">
          <section className="bg-white rounded-2 p-4 mb-2 flex justify-space-between">
            <span className={
              classNames({
                "font-size-2": true,
                "color-shade-3": printer.device === null,
              })
            }>
              Connect cash drawer
            </span>
            <ToggleSwitch
              checked={printer.drawerPresent}
              disabled={printer.device === null}
              onToggle={(checked) => {
                setShowDrawerOptions(checked)
                onDrawerUpdate(checked)
              }}
            />
          </section>

          {printer.drawerPresent && showDrawerOptions && (
            <>
              <div className="p-4 mb-2 font-weight-2 font-size-2 color-shade-3">
                Payment methods that should trigger cash drawer to open
              </div>

              <section className="mb-2 flex col overflow-y-auto">
                {drawer.openDrawerRules.map((rule, index) => {
                  return (
                    <SettingLineItem
                      key={rule.paymentMethod}
                      first={index === 0}
                      last={index === drawer.openDrawerRules.length - 1}
                      padding={4}
                      className="flex border-ends font-weight-2 justify-space-between items-center align-items-center"
                      onClick={() => {
                        onDrawerOptionClick(rule)
                      }}
                    >
                      <div>{startCase(rule.paymentMethod.toLowerCase())}</div>
                      {rule.openDrawer && (
                        <span className="theme-brand color-brand-5">
                          <Icon type={CHECK_MARK} fillcolor="green" />
                        </span>
                      )}
                    </SettingLineItem>
                  )
                })}
              </section>
            </>
          )}
        </div>
      )}

      {printer.device === null && (
        <AnimatePresence>
          <ButtonMenu
            className='absolute z-2 flex justify-center pe-5 pointer-events-x'
            style={{ bottom: 'var(--sc-spacing-5)', left: 0, right: 0 }}
          >
            <Button
              shade={5}
              rounded={4}
              theme={BRAND}
              size={LARGE}
              className='shadow-3 pointer-events-all'
              style={{ width: 380 }}
              onClick={() => onConnect(printer)}
            >
              Connect
            </Button>
          </ButtonMenu>
        </AnimatePresence>
      )}
    </Article>
  )
}

export default PrinterDetails