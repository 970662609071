import {
  PRINTER_TYPES,
  PRODUCT_LABEL_CONTEXT,
  RECEIPT_CONTEXT,
  PACKING_SLIP_CONTEXT,
} from "constants/settings"

import { CASH, CARD, CARD_INTEGRATED, BANK, CHEQUE, VOUCHER } from 'components/constants'

const PRINTERS = [
  { key: PRINTER_TYPES.LABEL, label: 'Label', device: null },
  {
    key: PRINTER_TYPES.RECEIPT,
    label: 'Receipt',
    device: null,
    shouldInquireAboutDrawerPresence: true,
    drawerPresent: false,
  },
]

const CONTEXTS = [
  { key: PRODUCT_LABEL_CONTEXT, templateId: null, templateName: null, printerType: 'label', label: 'Product Label', template: null },
  { key: RECEIPT_CONTEXT, templateId: null, templateName: null, printerType: 'receipt', label: 'Receipt', template: null },
  { key: PACKING_SLIP_CONTEXT, templateId: null, templateName: null, printerType: 'label', label: 'Packing slip', template: null },
]

export default {
  printers: PRINTERS,
  contexts: CONTEXTS,
  drawer: {
    printerKey: null,
    openDrawerRules: [
      {paymentMethod: CASH, openDrawer: false},
      {paymentMethod: CARD, openDrawer: false},
      {paymentMethod: CARD_INTEGRATED, openDrawer: false},
      {paymentMethod: BANK, openDrawer: false},
      {paymentMethod: CHEQUE, openDrawer: false},
      {paymentMethod: VOUCHER, openDrawer: false},
    ]
  },
  devices: [],
  tyro: { printTyroReceipt: false, alwaysPrintMerchantReceipt: false }
}