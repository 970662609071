import React from 'react'
import classNames from 'classnames'

import { BRAND, NEUTRAL, LIST, GRID } from 'components/constants'

import SearchInput from 'components/shared/SearchInput'
import StickyHeader from 'components/shared/StickyHeader'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Icon from 'components/shared/Icon'

const ProductSearchNav = ({
  batchButtonActionLabel,
  batchSelectable,
  batchSelectedAction,
  filter,
  handleKeyUp,
  keyword,
  layout,
  selectedProducts,
  toggle,
  showViewSelector = true,
}) => {
  return (
    <StickyHeader
      className={classNames({
        'flex wrap gap-2': true,
        'ps-3 pe-2': layout === GRID,
        'bg-shade-1 pt-2 ps-3': layout === LIST,
      })}
    >
      <SearchInput
        id='search'
        className='grow'
        defaultValue={keyword}
        onChange={(value) => filter(value)}
        onKeyUp={(event) => handleKeyUp && handleKeyUp(event)}
        placeholder='Filter products by name or code'
      />
      {batchSelectable && (
        <ButtonMenu
          className='shadow-3 rounded-2 gap-1px overflow-hidden'
          shade={1}
          theme={NEUTRAL}>
          <Button
            id="product-grid-button"
            onClick={() => batchSelectedAction(selectedProducts)}
            size={2}
            theme={BRAND}
            padding={4}
            rounded={0}
            shade={5}
            disabled={selectedProducts.length === 0}>
            {batchButtonActionLabel}
          </Button>
        </ButtonMenu>
      )}
      {showViewSelector && (
        <ButtonMenu
          className='shadow-3 rounded-2 gap-1px overflow-hidden'
          shade={1}
          theme={NEUTRAL}
        >
          <Button
            id="product-grid-button"
            onClick={toggle}
            size={2}
            theme={NEUTRAL}
            padding={4}
            rounded={0}
            shade={0}
            disabled={layout === GRID || keyword.length}>
            <Icon type={GRID} size={20} />
          </Button>
          <Button
            id="product-list-button"
            onClick={toggle}
            size={2}
            theme={NEUTRAL}
            padding={4}
            rounded={0}
            shade={0}
            disabled={layout === LIST || keyword.length}>
            <Icon type={LIST} size={20} />
          </Button>
        </ButtonMenu>
      )}
    </StickyHeader>
  )
}

export default ProductSearchNav
