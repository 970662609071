import React, {useEffect, useState} from 'react'
import classNames from 'classnames'

import { MEDIUM, LARGE, DEV_MODE } from 'components/constants'
import { BRAND } from 'components/constants'
import { END_SHIFT } from 'components/shifts/constants'
import { DASHBOARD, STYLE_GUIDE, COLUMN } from 'components/constants'
import { ORDERS } from 'components/orders/constants'
import { PRINTABLE_LABELS } from 'components/printableLabels/constants'
import { SHIPMENTS } from 'components/shipments/constants'
import { SUPPLIERS } from 'components/suppliers/constants'
import { FULFILLMENTS } from 'components/fulfillments/constants'
import { SETTINGS } from 'constants/settings'

import AppVersion from 'components/shared/AppVersion'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'
import getFormattedDate from 'components/helpers/getFormattedDate'
import getUserFullName from 'components/users/helpers/getUserFullName'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

import useHandlePrinting from 'hooks/useHandlePrinting';

const Sidebar = ({
  theme = BRAND,
  shade = 0,
  size = LARGE,
  user = {},
  shift = {},
  onSetView,
  onHide,
}) => {

  const [labelPrinterAvailable, setLabelPrinterAvailable] = useState(false)
  
  function click(set) {
    onHide()
    onSetView(set)
  }

  const { printers } = useHandlePrinting()
  
  useEffect(() => {
    if(printers.find(printer => printer.key === 'label' && printer.device !== null)) {    
      setLabelPrinterAvailable(true)
    } else {
      setLabelPrinterAvailable(false)
    }
  },[printers])

  if (!labelPrinterAvailable && window.mockLabelPrinter) { setLabelPrinterAvailable(true) } // necessary for testing

  const NavButton = ({ onClick, children }) => {
    return (
      <Button
        tabIndex='-1'
        className='justify-start text-left font-weight-3'
        padding={4}
        rounded={3}
        onClick={onClick}
        size={size}
        theme={theme}
        shade={shade}
      >
        {children}
      </Button>
    )
  }

  return (
    <nav
      id='nav'
      role='navigation'
      className={classNames({
        'fixed y-0 left-0 expand z-4 flex col justify-space-between overflow-x-auto': true,
        'shadow-4': true,
        [getThemeStyleClass(theme, shade)]: true,
      })}
      style={{
        width: 'var(--sc-nav-width)',
        transform: 'translateX(calc(var(--sc-nav-width) * -1))'
      }}
    >
      <ButtonMenu className='grow p-2' direction={COLUMN}>
        <NavButton onClick={() => click(DASHBOARD)}>
          Dashboard
        </NavButton>
        <NavButton onClick={() => click(ORDERS)}>
          Orders
        </NavButton>
        <NavButton onClick={() => click(FULFILLMENTS)}>
          Pickups
        </NavButton>
        {DEV_MODE && (
          <NavButton onClick={() => click(SHIPMENTS)}>
            Stock transfers
          </NavButton>
        )}
        <NavButton onClick={() => click(SUPPLIERS)}>
          Suppliers
        </NavButton>
        {labelPrinterAvailable && (
          <NavButton onClick={() => click(PRINTABLE_LABELS)}>
            Labels
          </NavButton>
        )}
        <NavButton onClick={() => click(SETTINGS)}>
          Settings
        </NavButton>
        {/* {DEV_MODE && (
          <NavButton onClick={() => click(CUSTOMERS)}>
            Customers
          </NavButton>
        )} */}
        {DEV_MODE && (
          <NavButton onClick={() => click(STYLE_GUIDE)}>
            Components
          </NavButton>
        )}
      </ButtonMenu>
      <AppVersion className="ps-5" />
      <div className='bg-brand-2 color-brand-8 rounded-3 flex align-items-start col gap-2 p-3 m-3'>
        {Object.keys(shift).length > 0 && (
          <div className='font-weight-2 font-size-2 expand-x flex col gap-2 ps-2 pe-2'>
            <div className='flex justify-space-between'>
              <span>Manager</span>
              <strong className='font-weight-3 capitalize'>
                {getUserFullName(user)}
              </strong>
            </div>
            <span className='flex justify-space-between'>
              <span>Start balance</span>
              <strong className='font-weight-3'>
                {getFormattedPrice(shift.startBalance)}
              </strong>
            </span>
            <span className='flex justify-space-between'>
              <span>Started</span>
              <strong className='font-weight-3'>
                {getFormattedDate(shift.startedAt)}
              </strong>
            </span>
          </div>
        )}
        <Button
          className='expand-x'
          shade={5}
          rounded={3}
          size={MEDIUM}
          theme={BRAND}
          onClick={() => click(END_SHIFT)}
        >
          End current shift
        </Button>
      </div>
    </nav>
  )
}

export default Sidebar
