import { USE_DUMMY_DATA } from 'constants/settings'

import authenticatedFetch from './authenticatedFetch'
import { log } from 'dev/log'

export function syncOrder(
  order = {},
  onSuccess,
  onError = () => alert('Error syncing order'),
  onOffline,
) {
  log('Syncing order...')

  if (USE_DUMMY_DATA) return onSuccess()

  if (navigator.onLine) {
    authenticatedFetch('orders',
      {
        method: 'POST',
        body: JSON.stringify(prepareOrder(order)),
      },
      onSuccess,
      onError,
      onOffline
    )
  }

  // Offline
  else onOffline()
}

function prepareOrder(order) {
  const userId = order.user ? order.user.id : null

  return { ...order, userId: userId, items: order.items.map(item => prepareOrderItem(item)) }
}

function prepareOrderItem(item) {
  let newItem = { ...item, transaction_discount: (item.price * item.quantity * (item.order_level_discount / 100))  }
  delete newItem.drop
  return newItem
}