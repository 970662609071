import { FULFILLMENT_STATUSES, FULFILLMENT_TYPES } from 'components/fulfillments/constants'

export default function getNextFulfillmentStatus(currentStatus, fulfillmentType) {
  switch (currentStatus) {
    case FULFILLMENT_STATUSES.REQUESTED:
      if (fulfillmentType === FULFILLMENT_TYPES.SPECIAL_ORDER) {
        return FULFILLMENT_STATUSES.ORDERED
      } else {
        return FULFILLMENT_STATUSES.PICKED
      }
    case FULFILLMENT_STATUSES.PICKED:
      if (fulfillmentType === FULFILLMENT_TYPES.TRANSFER) {
        return FULFILLMENT_STATUSES.SHIPPED
      } else {
        return FULFILLMENT_STATUSES.READY_FOR_PICKUP
      }
    case FULFILLMENT_STATUSES.READY_FOR_PICKUP:
      return FULFILLMENT_STATUSES.COMPLETED
    case FULFILLMENT_STATUSES.ORDERED:
      return FULFILLMENT_STATUSES.RECEIVED
    case FULFILLMENT_STATUSES.RECEIVED:
    case FULFILLMENT_STATUSES.SHIPPED:
      return FULFILLMENT_STATUSES.READY_FOR_PICKUP
    default:
      return currentStatus
  }
}