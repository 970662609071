import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { FULFILLMENT_STATUSES } from 'components/fulfillments/constants'

import NumberPicker from 'components/shared/NumberPicker'

const MAX_QUANTITY = 999

const FulfillmentItemsBlock = ({
  className,
  gridTemplateColumns = '2fr 1fr 2fr 1fr',
  fulfillmentItems = [],
  readOnly = false,
  status,
  fulfillmentUpdate,
  orderOwing
}) => {
  const [quantities, setQuantities] = useState({})

  useEffect(() => {
    const updatedQuantities = updateQuantities(fulfillmentItems)
    setQuantities(updatedQuantities)
    fulfillmentUpdate(updatedQuantities)
  }, [status, fulfillmentItems])

  function handleCount(value, item) {
    let updatedQuantities = { ...quantities }
    const currentItemQuantities = quantities[item.id]

    updatedQuantities[item.id] = {
      ...currentItemQuantities,
      adjustedQuantity: value,
      deficit: currentItemQuantities.expectedQuantity - value
    }

    setQuantities(updatedQuantities)
    fulfillmentUpdate(updatedQuantities)
  }

  function updateQuantities(items) {
    let updatedQuantities = {}

    items.forEach((item) => {
      // Deficit is zero since by default current quantity is the selected value for the number picker
      updatedQuantities[item.id] = {
        adjustedQuantity: item.quantity,
        deficit: 0,
        expectedQuantity: item.quantity,
      }
    })

    return updatedQuantities
  }

  function renderLineItem(item, index) {
    const width = (item.quantity < MAX_QUANTITY) ? 40 : 50
    const itemQuantities = quantities[item.id]

    if (itemQuantities === undefined) return null

    return (
      <div
        key={item.id}
        className="grid"
        style={{ gridTemplateColumns: gridTemplateColumns, gridGap: "5px", marginBottom: "15px" }}
        data-testid={`fulfillment-item-${item.id}`}
      >
        <span data-testid="item-description" className="flex align-items-center">{item.description}</span>
        <span data-testid="item-expected-quantity" className="flex align-items-center justify-center">
          {item.quantity}
        </span>
        {readOnly === false && (
          <span className="flex align-items-center justify-center">
            {status !== FULFILLMENT_STATUSES.COMPLETED.toUpperCase() &&
              <NumberPicker
                max={item.quantity}
                defaultValue={itemQuantities.adjustedQuantity}
                disabled={orderOwing}
                width={width}
                tabIndex={index + 1}
                focusOnLoad={index === 0}
                handleCount={(val) => handleCount(val, item)}
              />
            }
          </span>
        )}
        {readOnly === false && (
          <span className="flex align-items-center justify-center">
            <span className={itemQuantities.deficit > 0 ? "theme-notify-3 ps-2 pe-1 rounded-2" : ""}>
              {itemQuantities.deficit}
            </span>
          </span>
        )}
      </div>
    )
  }

  return (
    <div className={classNames({
      'sc-receipt': true,
      [className]: className,
    })}>
      <ul data-order-item id="order-items" className='overflow-hidden'>
        <header
          className={classNames({
            'grid pt-4 pb-3 ps-4': true,
            'border-bottom color-shade-5 font-size-2 font-weight-2': true,
          })}
          style={{ gridTemplateColumns: gridTemplateColumns }}>
          <span>Product Name</span>
          <span className="text-center">{(status.toLowerCase() === FULFILLMENT_STATUSES.COMPLETED || readOnly) ? 'Quantity' : 'Qty Expected'}</span>
          {readOnly === false && (
            <span className="text-center">Qty Available</span>
          )}
          {readOnly === false && (
            <span className="text-center">Missing</span>
          )}
        </header>
        {Object.keys(quantities).length > 0 && (
          <form className='fulfillment-line-items grid pt-2 pb-2 ps-4'>
            {fulfillmentItems.map((item, index) => {
              return renderLineItem(item, index)
            })}
          </form>
        )}
      </ul>
    </div>
  )
}

export default FulfillmentItemsBlock