import React, { useRef } from 'react'
import classNames from 'classnames'

import ProductCardSelector from './ProductCardSelector'
import getProductVariants from 'components/products/helpers/getProductVariants'

const ProductGrid = ({
  className,
  products,
  items,
  scroll = false,
  outlet,
  showOverlay,
  onSelect,
}) => {
  const container = useRef(null)

  return (
    <ul
      id='product-grid'
      ref={container}
      className={classNames({
        'grid align-items-start gap-2 cols-2 md:cols-3 lg:cols-4 ps-3 pb-4': true,
        'overflow-y-auto': scroll === true,
        [className]: className,
      })}
    >
      {items.map((product) => {
        return (
          <li
            key={product.id}
            data-product-card-selector={product.id}
            className='relative expand flex justify-center'
          >
            <ProductCardSelector
              product={product}
              outlet={outlet}
              disabled={showOverlay}
              context={container.current}
              variants={getProductVariants(product, products)}
              onSelect={(product) => onSelect(product)}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default ProductGrid
