import { v4 as uuidv4 } from 'uuid'
import { REGULAR_PRODUCT } from 'components/constants'
import {
  ADD_TO_CART,
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  INCREMENT_CART_ITEM,
  DECREMENT_CART_ITEM,
  SET_CART_ITEMS,
  RESET_CART_ITEMS,
  DISCOUNT_CART_ITEM,
  SET_CART_ITEM_SERIALS,
  SET_CART_ITEM_PRICE,
  SET_CART_ITEM_FULFILLMENT,
  DELETE_CART_ITEM_FULFILLMENT,
} from 'components/checkout/constants'

import createCartItem from 'components/checkout/helpers/createCartItem'
import calculateTaxes from 'components/orders/helpers/calculateItemTaxes'

export default function (state = [], action) {
  switch (action.type) {
    case ADD_TO_CART: {
      const newItem = createCartItem({
        id: uuidv4(),
        product: action.product,
        taxes: action.taxes,
        taxInclusive: action.taxInclusive,
        type: REGULAR_PRODUCT,
      })
      return [...state, { ...newItem, type: REGULAR_PRODUCT, taxes: calculateTaxes(newItem) }]
    }
    case ADD_CART_ITEM:
      return [...state, { ...action.item, type: REGULAR_PRODUCT, quantity: 1 }]

    case REMOVE_CART_ITEM:
      return state.filter((item) => item.id !== action.id)

    case INCREMENT_CART_ITEM:
      return state.map((item) => {
        if (item.id === action.itemId) {
          const newItem = { ...item, quantity: item.quantity + 1 }
          return { ...newItem, taxes: calculateTaxes(newItem) }
        }
        return item
      })

    case DECREMENT_CART_ITEM:
      return state.map((item) => {
        if (item.id === action.itemId) {
          const newItem = { ...item, quantity: item.quantity - 1 }
          return { ...newItem, taxes: calculateTaxes(newItem) }
        }
        return item
      })

    case SET_CART_ITEMS:
      return action.items.map((item) => {
        return { ...item, taxes: calculateTaxes(item) }
      })

    case RESET_CART_ITEMS:
      return []

    case DISCOUNT_CART_ITEM:
      return state.map((item) => {
        if (item.id !== action.payload.id) return item
        const newItem = { ...item, discount: parseFloat(action.payload.amount) }
        return { ...newItem, taxes: calculateTaxes(newItem) }
      })

    case SET_CART_ITEM_PRICE:
      return state.map((item) => {
        if (item.id !== action.payload.id) return item
        const newItem = { ...item, price: parseFloat(action.payload.amount) }
        return { ...newItem, taxes: calculateTaxes(newItem) }
      })

    case SET_CART_ITEM_SERIALS:
      return state.map((item) => {
        if (item.id !== action.payload.id) return item
        return { ...item, serial_numbers: action.payload.serials }
      })

    case SET_CART_ITEM_FULFILLMENT:
      return state.map((item) => {
        if (item.id === action.payload.orderItemId) {
          return { ...item, fulfillment: action.payload }
        }
        return item
      })

    case DELETE_CART_ITEM_FULFILLMENT:
      return state.map((item) => {
        if (item.id === action.payload.orderItemId) {
          const updatedItem = { ...item }
          delete updatedItem.fulfillment
          return updatedItem
        }
        return item
      })

    default:
      return state
  }
}