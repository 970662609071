// Settings

export const COUNTRY_CODE = 'US'
export const LOCALE = 'en-US'
export const PREFERRED_PRODUCT_LAYOUT = 'GRID'

// Timing (milliseconds)

export const DEBOUNCE_DELAY = 300
export const LOADER_TIMEOUT = 300
export const DEFAULT_SYNC_INTERVAL = 60 * 1000
export const DEFAULT_SYNC_TIMEOUT = 35 * 1000

// Developer tools

export const DEV_MODE = (process.env.NODE_ENV === 'development')
export const USE_DUMMY_DATA = false
export const SHOW_DEV_LOGS = true


export const CASH_ROUNDING_RULE = {
  nearest_up: 'nearest_up',
  nearest_down: 'nearest_down',
  always_up: 'always_up',
  always_down: 'always_down',
}
export const MIDWAY_ROUNDING_UNIT = 2.001

// Endpoints

export const TAXES = 'TAXES'

// Themes

export const BRAND = 'BRAND'
export const CUSTOMER = 'CUSTOMER'
export const LAYBY = 'LAYBY'
export const NEUTRAL = 'NEUTRAL'
export const WARNING = 'WARNING'
export const NOTIFY = 'NOTIFY'
export const SUCCESS = 'SUCCESS'

// Sizes

export const TINY = 'TINY'
export const SMALL = 'SMALL'
export const BASE = 'BASE'
export const MEDIUM = 'MEDIUM'
export const LARGE = 'LARGE'
export const XLARGE = 'XLARGE'
export const XXLARGE = 'XXLARGE'
export const HUGE = 'HUGE'

// Layout

export const CARD = 'CARD'
export const CENTER = 'CENTER'
export const COLUMN = 'COLUMN'
export const GRID = 'GRID'
export const LEFT = 'LEFT'
export const LINE_ITEM = 'LINE_ITEM'
export const LIST = 'LIST'
export const RIGHT = 'RIGHT'
export const ROW = 'ROW'

// Contact

export const EMAIL = 'EMAIL'
export const LOGIN = 'LOGIN'
export const PHONE = 'PHONE'

// Icons

export const UPDATE = 'UPDATE'
export const DATE = 'DATE'
export const BANK = 'BANK'
export const CASH = 'CASH'
export const CHECK_MARK = 'CHECK_MARK'
export const CARD_INTEGRATED = 'CARD_INTEGRATED'
export const CHEQUE = 'CHEQUE'
export const CHEVRON = 'CHEVRON'
export const CLOSE = 'CLOSE'
export const DECREMENT = 'DECREMENT'
export const DOLLAR = 'DOLLAR'
export const EXPAND = 'EXPAND'
export const INCREMENT = 'INCREMENT'
export const LOCK = 'LOCK'
export const MENU = 'MENU'
export const MINIMISE = 'MINIMISE'
export const NUMBER = 'NUMBER'
export const REGISTER = 'REGISTER'
export const SEARCH = 'SEARCH'
export const VOUCHER = 'VOUCHER'
export const PERCENTAGE = 'PERCENTAGE'

// Misc

export const STYLE_GUIDE = 'STYLE_GUIDE'
export const DASHBOARD = 'DASHBOARD'
export const CONFIRM = 'CONFIRM'
export const TRANSFER = 'TRANSFER'
export const CART = 'CART'

// Product

export const REGULAR_PRODUCT = 'product'

export const RESERVED_PRODUCTS = {
  shipping: 'shipping',
  promo: 'promo',
  cash_rounding: 'cash_rounding',
  tyro_surcharge: 'tyro_surcharge',
  tipping: 'tipping',
}

export const VIEWABLE_ORDER_ITEMS = [
  REGULAR_PRODUCT,
]

// Animation

export const BACK = 'BACK'
export const FADE = 'FADE'
export const NEXT = 'NEXT'
export const SCALE_IN = 'SCALE_IN'
export const SLIDE_NEXT = 'SLIDE_NEXT'
export const SLIDE_PREV = 'SLIDE_PREV'
export const SLIDE_UP = 'SLIDE_UP'
export const SLIDE_DOWN = 'SLIDE_DOWN'

// Keys

export const ENTER = 'Enter'
export const DELETE = 'Backspace'
