export const FULFILLMENTS = 'FULFILLMENTS'

export const CURRENT_OUTLET_ROLE = {
  SOURCE: 'source',
  DESTINATION: 'destination',
}

export const FULFILLMENT_TYPES = {
  TAKEAWAY: 'takeaway',
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  HOLD: 'hold',
  TRANSFER: 'transfer',
  SPECIAL_ORDER: 'special_order',
}

export const SUPPORTED_FULFILLMENT_TYPES = [
  FULFILLMENT_TYPES.HOLD,
  FULFILLMENT_TYPES.TRANSFER,
  FULFILLMENT_TYPES.SPECIAL_ORDER
]

export const FULFILLMENT_STATUSES = {
  COMPLETED: 'complete',
  DELIVERED: 'delivered',
  ORDERED: 'ordered',
  OUT_OF_STOCK: 'out_of_stock',
  MISSING: 'missing',
  PICKED: 'picked',
  PACKED: 'packed',
  REQUESTED: 'requested',
  RECEIVED: 'received',
  READY_FOR_PICKUP: 'ready_for_pickup',
  SHIPPED: 'shipped',
}

export const FULFILLMENT_CATEGORIES = [
  FULFILLMENT_TYPES.CLICK_AND_COLLECT, 
  FULFILLMENT_TYPES.HOLD,
  FULFILLMENT_TYPES.TRANSFER,
  FULFILLMENT_TYPES.SPECIAL_ORDER
]

export const READ_ONLY_STATUSES = [
  FULFILLMENT_STATUSES.OUT_OF_STOCK,
  FULFILLMENT_STATUSES.MISSING,
  FULFILLMENT_STATUSES.COMPLETED,
]

export const modalCopy = {
  COMPLETE: {
    allFilled: "You have indicated that the following items have been picked:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br><strong class='font-weight-3'>You cannot undo this</strong>.<br>Please check the box next to each missing item to confirm:"
  },
  REQUESTED: {
    allFilled: "You have indicated that the following items have been picked:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br><strong class='font-weight-3'>You cannot undo this</strong>.<br>Please check the box next to each missing item to confirm:"
  },
  PICKED: {
    allFilled: "You have indicated that the following items have been packed:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
  SHIPPED: {
    allFilled: "You have indicated that the following items have been shipped:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
  ORDERED: {
    allFilled: "You have indicated that the following items have been ordered:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
  RECEIVED: {
    allFilled: "You have indicated that the following items have been received:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
  READY_FOR_PICKUP: {
    allFilled: "You have indicated that the following items have been released to the customer:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
}
