import React from 'react'

import {
  XLARGE,
  BRAND,
  EMAIL,
  COLUMN,
  SCALE_IN,
} from 'components/constants'

import { COMPLETE } from 'components/orders/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import CustomerEmailForm from 'components/checkout/shared/CustomerEmailForm'

const MAX_WIDTH = 600

const OrderActionsModal = ({
  canReturn = false,
  canAdjust = false,
  customer = {},
  id = null,
  animation = SCALE_IN,
  theme = BRAND,
  shade = 3,
  printDisabled = false,
  onReturn,
  onAdjust,
  onAbandon,
  onBack,
  onEmail,
  onPrint,
  onSetId,
  canEmail = true,
  orderType,
}) => {

  switch (id) {
    case EMAIL:
      return (
        <CustomerEmailForm
          className='z-2'
          onSubmit={(value) => onEmail(value)}
          onBack={onBack}
          theme={theme}
          customer={customer}
        />
      )
    default:
      return (
        <ButtonMenu
          className='shadow-3 relative overflow-hidden z-2'
          rounded={3}
          maxWidth={MAX_WIDTH}
          direction={COLUMN}
          animation={animation}
        >
          {(canEmail && orderType !== COMPLETE) && (
            <Button
              onClick={() => onSetId(EMAIL)}
              theme={theme}
              size={XLARGE}
              rounded={0}
              fixedHeight={false}
              shade={shade}
              padding={6}
              className='justify-start'
            >
              Email receipt
            </Button>
          )}
          {(onPrint && orderType !== COMPLETE) && (
            <Button
              onClick={() => onPrint()}
              disabled={printDisabled}
              theme={theme}
              size={XLARGE}
              rounded={0}
              fixedHeight={false}
              shade={shade}
              padding={6}
              className='justify-start'
            >
              Print receipt
            </Button>
          )}
          {onAbandon && (
            <Button
              onClick={() => onAbandon()}
              text='Abandon parked order'
            />
          )}
          {canReturn && (
            <Button
              onClick={onReturn}
              theme={theme}
              size={XLARGE}
              rounded={0}
              fixedHeight={false}
              shade={shade}
              padding={6}
              className='justify-start'
            >
              Return items
            </Button>
          )}
          {canAdjust && (
            <Button
              onClick={onAdjust}
              theme={theme}
              size={XLARGE}
              rounded={0}
              fixedHeight={false}
              shade={shade}
              padding={6}
              className='justify-start'
            >
              Adjust layby
            </Button>
          )}
        </ButtonMenu>
      )
  }
}

export default OrderActionsModal
