
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { BRAND, TINY, INCREMENT, DECREMENT } from 'components/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import usePrevious from 'hooks/usePrevious'

const NumberPicker = ({
  min = 0,
  max = 999999999,
  step = 1,
  width = 30,
  defaultValue = null,
  gridTemplateColumns = `58px ${width}px 58px`,
  theme = BRAND,
  handleCount,
  reset = false,
  tabIndex,
  focusOnLoad = false,
  disabled
}) => {
  const [count, setCount] = useState(defaultValue !== null ? defaultValue : min)

  const prevCount = usePrevious(count)
  useEffect (() => {
    if (prevCount === undefined) return
    handleCount(count)
  }, [count, prevCount])

  useEffect(()=> {
    setCount(defaultValue !== null ? defaultValue : min)
  }, [reset])

  const incrementValue = (adding) => {
    let currentVal = count
    if (adding && (currentVal + step) <= max) {
      currentVal += step
    } else if (!adding && (currentVal - step) >= min) {
      currentVal -= step
    }
    currentVal !== count && setCount(currentVal)
  }

  const handleInput = (value) => {
    setCount((prevCount) => {
      const currentVal = value !== ""
        ? Math.min(Math.max(parseInt(value), min), max)
        : ""
      return currentVal !== prevCount ? currentVal : prevCount
    })
  }

  return (
    <div className={"grid ms-auto"} style={{gridTemplateColumns: gridTemplateColumns}}>
      <Button
        dataTestId="decrement-button"
        className="rounded-left-2"
        shade={5}
        theme={theme}
        size={TINY}
        disabled={disabled}
        aria-label="Decrement value"
        onClick={() => incrementValue(false)}
        rounded={0}
      >
        <Icon type={DECREMENT} size={20} />
      </Button>
      <input 
        role="spinbutton" 
        inputMode="numeric"
        data-testid="picker-input"
        disabled={disabled}
        className={classNames({
          'pe-1 expand text-center bg-brand-5 border-left border-right': true,
        })}
        type="text"
        id={"numberpickerInput_" + tabIndex}
        name="picker-input"
        value={count}
        style={{width: "auto", borderWidth: "3px", color: "#fff"}}
        tabIndex={tabIndex}
        autoFocus={focusOnLoad}
        onChange={(input) => handleInput(input.target.value)}
      />
      <Button
        dataTestId="increment-button"
        className="rounded-right-2"
        shade={5}
        theme={theme}
        size={TINY}
        disabled={disabled}
        aria-label="Increment value"
        onClick={() => incrementValue(true)}
        rounded={0}>
        <Icon type={INCREMENT} size={20} />
      </Button>
    </div>
  )
}

export default NumberPicker