import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { SMALL, MEDIUM, NEXT, BACK, BRAND } from 'components/constants'
import { ADD_USER_TO_SHIFT } from 'components/shifts/constants'
import { PASSCODE } from 'components/users/constants'

import { fetchUsers } from 'data/api/fetchUsers'
import { syncShift } from 'data/api/syncShift'

import Article from 'components/shared/Article'
import ButtonMenu from 'components/shared/ButtonMenu'
import Button from 'components/shared/Button'
import Container from 'components/shared/Container'
import Loader from 'components/shared/Loader'
import PasscodeForm from 'components/users/PasscodeForm'
import ShiftAddUser from 'components/shifts/ShiftAddUser'
import UserCard from 'components/users/shared/UserCard'
import StoreCard from 'components/stores/shared/StoreCard'

import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import ResetViewParents from 'components/navigation/actions/ResetViewParents'
import ResetUser from 'components/users/actions/ResetUser'
import SetUser from 'components/users/actions/SetUser'
import SetUsers from 'components/users/actions/SetUsers'
import SetShift from 'components/shifts/actions/SetShift'
import Unlock from 'components/navigation/actions/Unlock'

import getUserById from 'components/users/helpers/getUserById'

const LockTerminalFlow = ({
  initialAnimation = NEXT,
  theme = BRAND,
  shade = 5,
}) => {
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users)
  const store = useSelector((state) => state.store)
  const register = useSelector((state) => state.register)
  const shift = useSelector((state) => state.shift)
  const outlet = useSelector((state) => state.outlet)

  const [user, setUser] = useState({})
  const [viewId, setViewId] = useState(null)
  const [animation, setAnimation] = useState(initialAnimation)
  const [loading, setLoading] = useState(false)

  // Fetch users when component mounts and sets them to client state
  // We do not poll for new server-side users because we assume these
  // will not change during a shift.
  useEffect(() => {
    fetchUsers((users) => dispatch(SetUsers(users)))
  }, [])

  function select(user) {
    setUser(user)
    setViewId(PASSCODE)
    setAnimation(NEXT)
  }

  function add(user) {
    setLoading(true)
    dispatch(SetUsers({...users, [user.id]: user}))
    dispatch(SetShift({...shift, users: [...shift.users, user.id]}))
    syncShift(
      register,
      { ...shift, users: [...shift.users, user.id] },
      () => {
        setLoading(false)
      }
    )
  }

  switch (viewId) {
    case PASSCODE:
      return (
        <PasscodeForm
          onBack={() => {
            setViewId(null)
            setAnimation(BACK)
            dispatch(ResetUser())
          }}
          locked={true}
          outlet={outlet}
          onSuccess={() => {
            dispatch(ResetViewParents())
            dispatch(SetViewAnimation(NEXT))
            dispatch(SetUser(user))
            dispatch(Unlock())
          }}
          user={user}
          transition={animation}
        />
      )
    case ADD_USER_TO_SHIFT:
      return (
        <ShiftAddUser
          onBack={() => {
            setViewId(null)
            setAnimation(BACK)
          }}
          theme={BRAND}
          shade={5}
          onSubmit={(user) => add(user)}
          users={users}
          shift={shift}
          transition={animation}
        />
      )
    default:
      return (
        <Article
          className='expand'
          theme={theme}
          shade={shade}
          animation={initialAnimation}
        >
          <header className='absolute top-0 x-0 p-3 flex justify-space-between'>
            <span className='flex row align-items-center gap-5 ps-3'>
              <StoreCard size={SMALL} store={store} />
              <strong className='opacity-07 font-weight-3'>
                Terminal Locked
              </strong>
            </span>
            <Button
              onClick={() => {
                setViewId(ADD_USER_TO_SHIFT)
                setAnimation(NEXT)
              }}
              theme={BRAND}
              size={MEDIUM}
              shadow={3}
              shade={3}
              rounded={4}
            >
              Add user to shift
            </Button>
          </header>
          <Container center={true}>
            <ButtonMenu className='justify-center overflow-x-scroll gap-2 p-6'>
              {shift.users.map((userId) => {
                const user = getUserById(userId, users)
                return (
                  <li key={userId}>
                    <UserCard
                      onClick={() => select(user)}
                      orientation={null}
                      user={user}
                    />
                  </li>
                )
              })}
            </ButtonMenu>
            {loading && <Loader />}
          </Container>
        </Article>
      )
  }
}

export default LockTerminalFlow
