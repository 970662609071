import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnimatePresence } from 'framer-motion'

import { NEXT } from 'constants/motion'
import { MEDIUM, HUGE } from 'constants/size'
import { BRAND, LAYBY, NOTIFY, COLUMN, SLIDE_UP } from 'components/constants'
import { EMAIL, DASHBOARD, RESERVED_PRODUCTS } from 'components/constants'
import { PARKED, ORDERS, INCOMPLETE, COMPLETE } from 'components/orders/constants'
import { CHECKOUT } from 'components/checkout/constants'
import { PRINTER_TYPES, PRINTABLE_TYPES } from 'constants/settings'

import Article from 'components/shared/Article'
import Banner from 'components/shared/Banner'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Overlay from 'components/shared/Overlay'
import Tag from 'components/shared/Tag'

import useHandlePrinting, { FAILED_CONNECTION } from 'hooks/useHandlePrinting'

import OrderAmountLineItem from './OrderAmountLineItem'
import OrderActionsModal from './OrderActionsModal'
import OrderCustomerBlock from './OrderCustomerBlock'
import OrderDiscountLineItem from './OrderDiscountLineItem'
import OrderNotesBlock from './OrderNotesBlock'
import OrderPaymentsBlock from './OrderPaymentsBlock'
import OrderSummaryBlock from './OrderSummaryBlock'
import OrderTaxesBlock from './OrderTaxesBlock'
import OrderItemsBlock from './OrderItemsBlock'
import OrderItemAdjustmentModal from './OrderItemAdjustmentModal'

import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetViewParents from 'components/navigation/actions/SetViewParents'
import SetCartItems from 'components/checkout/actions/SetCartItems'
import SetOrder from 'components/orders/actions/SetOrder'

import canAbandonOrder from 'components/orders/helpers/canAbandonOrder'
import canAdjustOrder from 'components/orders/helpers/canAdjustOrder'
import canCheckout from 'components/orders/helpers/canCheckout'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import getChangeDue from 'components/orders/helpers/getChangeDue'
import getDateLocalTimezone from 'components/helpers/getDateLocalTimezone'
import getOrderStatusText from 'components/orders/helpers/getOrderStatusText'
import getOrderTheme from 'components/orders/helpers/getOrderTheme'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import ResetCartItems from 'components/checkout/actions/ResetCartItems'
import hasRefund from 'components/order/helpers/hasRefund'
import canReturnOrder from 'components/orders/helpers/canReturnOrder'
import SetCustomer from 'components/checkout/actions/SetCustomer'
import isFullySettled from '../orders/helpers/isFullySettled'
import prepareItemsForReturn from 'components/order/helpers/prepareItemsToNegativeQuantity'
import prepareOrderForReceiptPrinting from 'components/order/helpers/prepareOrderForReceiptPrinting'
import getTotal from 'components/orders/helpers/getTotal'
import getTotalRefunded from 'components/orders/helpers/getTotalRefunded'
import getRemainingRefundAmount from '../orders/helpers/getRemainingRefundAmount'
import getRefundAmount from 'components/orders/helpers/getRefundAmount'
import getAdjustmentByTypeTotal from 'components/orders/helpers/getAdjustmentByTypeTotal'
import getReservedItemsTotal from 'components/orders/helpers/getReservedItemsTotal'

// Will use products, taxes or adding positive quantities during adjustments
// We want to add positive quantities with correct prices and taxes
const Order = ({
  order = {},
  store = {},
  taxes = [], // Will use this for adding positive quantities during adjustments
  products, // Will use this for adding positive quantities during adjustments
  viewId,
  animation,
  onAbandon,
  onBack,
  onEmail,
  taxInclusive = false,
  onApplyAdjustment,
  onMakePayment,
  syncingOrder = false,
  showConfirmationMsg = false,
}) => {
  const dispatch = useDispatch()

  const [adjustableOrder, setAdjustableOrder] = useState({ ...order })
  const [orderActionId, setOrderActionId] = useState(null)
  const [showOrderAction, setShowOrderAction] = useState(false)
  const [showAdjustmentModal, setShowAdjustmentModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isAdjusting, setIsAdjusting] = useState(false)
  const [adjustmentItems, setAdjustmentItems] = useState([])
  const [theme, setTheme] = useState(getOrderTheme(adjustableOrder.orderType))

  const { subtotal, discount, total, taxes: _taxes, orderDiscount } = getPaymentData(adjustableOrder.items)
  const owed = getAmountOwed(adjustableOrder)
  const paid = getAmountPaid(adjustableOrder)
  const cashAdjustmentTotal = getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.cash_rounding)
  const tyroSurchargeTotal = getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.tyro_surcharge)
  const tippingTotal = getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.tipping)
  const reservedItemsTotal = getReservedItemsTotal(order.items)

  const {
    deviceError,
    isDeviceAvailable,
    print,
  } = useHandlePrinting(PRINTER_TYPES.RECEIPT, PRINTABLE_TYPES.ORDER)
  const printerAvailable = isDeviceAvailable(PRINTER_TYPES.RECEIPT)

  // This will make sure that updates in order from checkout will
  // also reflect here. i.e theme
  useEffect(() => {
    setTheme(getOrderTheme(order.orderType))
    setAdjustableOrder(order)
  }, [order])

  const showOrderActions = adjustableOrder.orderType === LAYBY || (adjustableOrder.orderType === COMPLETE && canReturnOrder(adjustableOrder))
  const isRefund = hasRefund(adjustableOrder)

  function checkout() {
    if (viewId !== CHECKOUT) {
      dispatch(SetViewParents([ORDERS]))
      dispatch(SetViewAnimation(NEXT))
      dispatch(SetViewId(CHECKOUT))
    }

    // Setting cartItems makes everything easier going back to checkout payment
    dispatch(SetCartItems(adjustableOrder.items))

    // We only pass onMakePayment from a checkout flow
    if (onMakePayment) {
      onMakePayment()
    }
  }

  function continueOrder(order) {
    dispatch(SetCustomer(order.customer))
    dispatch(SetOrder(order))
    dispatch(SetViewParents([ORDERS]))
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewId(DASHBOARD))
  }

  function onPrintReceipt() {
    print({ ...prepareOrderForReceiptPrinting(order) })
  }

  function confirmationHeading() {
    const changeDue = getChangeDue(adjustableOrder)
    const refundAmount = getRemainingRefundAmount(adjustableOrder)

    if (refundAmount > 0) {
      return (
        <h1 className='font-size-9 hide-print'>
          Give {getFormattedPrice(refundAmount)} refund
        </h1>
      )
    }
    if (changeDue !== 0)
      return (
        <h1 className='font-size-9 hide-print'>
          Give {getFormattedPrice(changeDue)} change
        </h1>
      )
    if (!isFullySettled(adjustableOrder) && adjustableOrder.orderType === LAYBY) return (
      <h1 className='font-size-9 hide-print'>
        Payment processed
      </h1>
    )
    return <h1 className='font-size-9 hide-print'>Order complete</h1>
  }

  function hasAddedAdjustments() {
    return order.items.length !== adjustableOrder.items.length
  }

  return (
    <Article
      className='bg-gradient relative expand z-0'
      gridTemplateRows='1fr'
      animation={animation}
    >
      <div className='flex col overflow-y-auto'>
        <ButtonMenu className='absolute top-0 x-0 p-2 z-1 justify-space-between'>
          <Button
            shade={0}
            rounded={4}
            size={MEDIUM}
            theme={theme}
            disabled={syncingOrder}
            onClick={() => {
              if (isAdjusting) {
                setAdjustableOrder(order)
                setIsAdjusting(false)
              } else {
                onBack()
              }
            }}
          >
            {!isAdjusting && <>Back</>}
            {isAdjusting && <>Cancel adjustment</>}
          </Button>
          {!isAdjusting && showOrderActions && (
            <Button
              size={MEDIUM}
              theme={theme}
              shade={3}
              shadow={3}
              onClick={() => setShowOrderAction(true)}
            >
              Order actions
            </Button>
          )}
          {canAbandonOrder(adjustableOrder) && (
            <Button
              size={MEDIUM}
              theme={NOTIFY}
              shade={3}
              shadow={3}
              onClick={() => onAbandon()}
            >
              Abandon Order
            </Button>
          )}
        </ButtonMenu>
        <Container
          scroll={true}
          gap={5}
          maxWidth={1000}
          className='expand pe-8'
        >
          <header className='flex col gap-3'>
            {showConfirmationMsg && confirmationHeading()}
            {showConfirmationMsg === false && (
              <h1 className='font-size-9 hide-print'>
                {adjustableOrder.reference_number}
              </h1>
            )}
            <div className='sc-receipt-heading hide-non-print'>
              {adjustableOrder.reference_number}
            </div>
            <div className='flex align-items-center gap-3'>
              <Tag
                className='hide-print'
                theme={theme}
                text={getOrderStatusText(adjustableOrder.orderType)}
              />
              <strong className='font-size-3 color-shade-5'>
                Created {getDateLocalTimezone(adjustableOrder.created)}
              </strong>
            </div>
          </header>
          <section className='flex gap-3'>
            <OrderSummaryBlock
              className='bg-shade-1 rounded-2 p-4 grow'
              withRefund={isRefund}
              type={adjustableOrder.orderType}
              total={adjustableOrder.total}
              refundAmount={getRefundAmount(adjustableOrder)}
              refunded={getTotalRefunded(adjustableOrder)}
              owed={owed}
              paid={paid}
              orderNumber={showConfirmationMsg && adjustableOrder.reference_number}
            />
            {adjustableOrder.customer && Object.keys(adjustableOrder.customer).length > 0 && (
              <OrderCustomerBlock
                className='bg-shade-1 rounded-2 p-4'
                customer={adjustableOrder.customer}
              />
            )}
          </section>

          <div className='flex col bg-shade-0 shadow-2 rounded-2'>
            <OrderItemsBlock
              items={adjustableOrder.items}
              adjustmentItems={adjustmentItems}
              onSelect={isAdjusting === false ? undefined : (item) => {
                // This will prevent adjustment item to be duplicated for selected order items
                const matchedItem = adjustmentItems.find((_item) => item.id === _item.id)
                if (matchedItem !== undefined) {
                  setSelectedItem(matchedItem)
                } else {
                  setSelectedItem(item)
                }
                setShowAdjustmentModal(true)
              }}
              onDelete={(_item) => {
                const items = adjustableOrder.items.filter((item) => item.id !== _item.id)
                const { subtotal, discount, orderDiscount, taxTotal } = getPaymentData(items)
                setAdjustmentItems(items)
                setAdjustableOrder({
                  ...adjustableOrder,
                  total: getTotal(subtotal, taxTotal, discount, store.taxInclusive, orderDiscount),
                  items,
                })
                setShowAdjustmentModal(false)
              }}
              actionable={isAdjusting}
            />
            <div className='border-top p-4 font-weight-2 font-size-2 color-shade-5 flex col gap-1'>
              <OrderAmountLineItem
                label="Subtotal"
                amount={subtotal - reservedItemsTotal}
                dataAttributeName={{ 'data-subtotal': 'data-subtotal' }}
              />
              <OrderTaxesBlock taxInclusive={taxInclusive} taxes={_taxes} />
              <OrderDiscountLineItem discount={discount} orderDiscount={subtotal * (orderDiscount / 100)}/>
              {cashAdjustmentTotal !== 0 && (
                <OrderAmountLineItem
                  label="Cash rounding"
                  amount={cashAdjustmentTotal}
                  dataAttributeName={{ 'data-cash-rounding-total': 'data-cash-rounding-total' }}
                />
              )}
              {tyroSurchargeTotal !== 0 && (
                <OrderAmountLineItem
                  label="Tyro surcharge"
                  amount={tyroSurchargeTotal}
                  dataAttributeName={{ 'data-tyro-surcharge-total': 'data-tyro-surcharge-total' }}
                />
              )}
              {tippingTotal !== 0 && (
                <OrderAmountLineItem
                  label="Tipping"
                  amount={tippingTotal}
                  dataAttributeName={{ 'data-tipping-total': 'data-tipping-total' }}
                />
              )}
              <OrderAmountLineItem
                label="Total"
                amount={total}
                dataAttributeName={{ 'data-order-total': 'data-order-total' }}
                className="font-weight-3 font-size-3 color-shade-7"
              />
            </div>
          </div>
          {adjustableOrder.payments.length > 0 && (
            <OrderPaymentsBlock
              payments={adjustableOrder.payments}
              className='bg-shade-0 shadow-2 rounded-2'
            />
          )}
          <OrderNotesBlock notes={adjustableOrder.notes} />
        </Container>
        {(deviceError !== null || printerAvailable === false) && adjustableOrder.orderType === COMPLETE && (
          <Banner
            direction={COLUMN}
            animation={SLIDE_UP}
            theme={NOTIFY}
            shade={3}
            message={deviceError || FAILED_CONNECTION}
          />
        )}
        {canCheckout(adjustableOrder) === true && (
          <ButtonMenu id='footer' theme={theme}>
            <Button
              theme={order.orderType === INCOMPLETE ? BRAND : theme}
              onClick={() => {

                if (order.orderType === PARKED) {
                  continueOrder(order)
                  return
                }

                // Confirm adjustments
                if (isAdjusting) {
                  // This makes sure that on next adjustment flow (if needed) will treat
                  // previously added adjustments as persisted items already. Only add new
                  // items for every adjustment transaction
                  const items = [...adjustmentItems].map((item) => {
                    delete item.newAdjustment
                    return { ...item }
                  })
                  const updatedOrder = { ...adjustableOrder, sync: true, items }
                  onApplyAdjustment(updatedOrder, () => setIsAdjusting(false))
                } else {
                  checkout()
                }
              }}
              disabled={(isAdjusting && !hasAddedAdjustments()) || syncingOrder}
              size={HUGE}
              rounded={0}
              shade={5}
              padding={7}
            >
              {isAdjusting && 'Apply adjustments'}
              {!isAdjusting && adjustableOrder.orderType === LAYBY && owed < 0 && 'Process refund'}
              {!isAdjusting && adjustableOrder.orderType === LAYBY && owed > 0 && 'Make a payment'}
              {!isAdjusting && adjustableOrder.orderType === INCOMPLETE && 'Continue incomplete order'}
              {!isAdjusting && adjustableOrder.orderType === PARKED && 'Continue parked order'}
            </Button>
          </ButtonMenu>
        )}
        {canCheckout(adjustableOrder) === false && (
          <ButtonMenu
            id='footer'
            className='gap-1'
            shade={6}
            theme={theme}
          >
            <Button
              className='half'
              rounded={0}
              shade={5}
              size={HUGE}
              theme={theme}
              disabled={window.mockPrinter === undefined && (deviceError !== null || printerAvailable === false)}
              onClick={onPrintReceipt}
            >
              Print receipt
            </Button>
            <Button
              className='half'
              rounded={0}
              shade={5}
              size={HUGE}
              theme={theme}
              onClick={() => {
                setOrderActionId(EMAIL)
                setShowOrderAction(true)
              }}
            >
              Email receipt
            </Button>
          </ButtonMenu>
        )}
      </div>
      <AnimatePresence>
        {showOrderAction && (
          <Overlay
            onClose={() => {
              setShowOrderAction(false)
              setOrderActionId(null)
            }}
          >
            <OrderActionsModal
              canReturn={canReturnOrder(adjustableOrder)}
              canAdjust={canAdjustOrder(adjustableOrder)}
              canSetNotes={false}
              theme={theme}
              id={orderActionId}
              onPrint={onPrintReceipt}
              printDisabled={printerAvailable === false}
              customer={adjustableOrder.customer}
              onSetId={setOrderActionId}
              orderType={adjustableOrder.orderType}
              onAdjust={() => {
                setIsAdjusting(true)
                setShowOrderAction(false)
              }}
              onReturn={() => {
                dispatch(ResetCartItems())
                dispatch(SetOrder({}))
                dispatch(SetCustomer(adjustableOrder.customer))
                dispatch(SetCartItems(prepareItemsForReturn(adjustableOrder.items)))
                dispatch(SetViewId(DASHBOARD))
              }}
              onEmail={(address) => {
                onEmail(address)
                setShowOrderAction(false)
              }}
              onBack={() => {
                setShowOrderAction(false)
                setOrderActionId(null)
              }}
              onAbandon={canAbandonOrder(adjustableOrder) ? () => {
                onAbandon()
              } : null}
            />
          </Overlay>
        )}
        {showAdjustmentModal && (
          <Overlay
            onClose={() => {
              setShowAdjustmentModal(false)
            }}
          >
            <OrderItemAdjustmentModal
              theme={LAYBY}
              products={products}
              taxes={taxes}
              store={store}
              selectedItem={selectedItem}
              onSelect={(item) => setSelectedItem(item)}
              orderItems={order.items} // always pass orginal order items
              onClose={() => {
                setShowAdjustmentModal(false)
                setSelectedItem(null)
              }}
              onCreate={(adjustmentItem) => {
                // add or replace item
                let items = [...adjustableOrder.items]

                const matchedIndex = items.findIndex(item => item.id === adjustmentItem.id)
                if (matchedIndex !== -1) {
                  // We will remove/not add adjustment if the end quantity is zero
                  if (adjustmentItem.quantity === 0) {
                    items = adjustmentItems.filter((_adjustmentItem) => _adjustmentItem.originalItemId !== adjustmentItem.originalItemId)
                  } else {
                    items[matchedIndex] = adjustmentItem
                  }
                } else {
                  // We will only add ajustment with quantity not zero
                  if (adjustmentItem.quantity !== 0) {
                    items = [...items, adjustmentItem]
                  }
                }

                const { subtotal, discount, taxTotal } = getPaymentData(items)
                setAdjustmentItems(items)
                setAdjustableOrder({
                  ...adjustableOrder,
                  total: getTotal(subtotal, taxTotal, discount, store.taxInclusive),
                  items
                })
                setShowAdjustmentModal(false)
                setSelectedItem(null)
              }}
            />
          </Overlay>
        )}
      </AnimatePresence>
    </Article>
  )
}

export default Order
