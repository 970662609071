import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { COLUMN, ROW } from 'components/constants'
import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Banner = ({
  animation,
  className,
  delay = 0,
  direction = ROW,
  id,
  maxWidth,
  rounded = 0,
  shade,
  style,
  theme,
  message,
}) => {
  return (
    <motion.menu data-banner-menu
      id={id}
      {...getAnimation(animation, delay)}
      style={{ ...style, maxWidth: maxWidth }}
      className={classNames({
        'border': true,
        'p-4': true,
        'text-center': true,
        'relative no-shrink': true,
        'expand-x': direction === COLUMN,
        'offset-button-animation': !!animation && direction === ROW,
        ['rounded-' + rounded]: rounded > 0,
        [getThemeStyleClass(theme, shade)]: theme,
        [className]: className,
      })}
    >
      {message}
    </motion.menu>
  )
}

export default Banner