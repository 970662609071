import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useErrorHandler, { ERROR_CODES } from 'hooks/useErrorHandler'

import { NEXT } from 'constants/motion'
import { BRAND, NEUTRAL, LARGE, COLUMN, SLIDE_DOWN, NOTIFY } from 'components/constants'
import { REGISTER } from 'components/registers/constants'

import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'

import AppVersion from 'components/shared/AppVersion'
import Banner from 'components/shared/Banner'
import Container from 'components/shared/Container'
import Loader from 'components/shared/Loader'
import Field from 'components/shared/Field'
import Form from 'components/shared/Form'
import Button from 'components/shared/Button'

import authenticate from 'data/api/authenticate'

const Authentication = ({
  theme = NEUTRAL,
  shade = 1,
}) => {
  const dispatch = useDispatch()
  const [username, setUsername] = useState(null)
  const [secret, setSecret] = useState(null)
  const [pin, setPin] = useState(null)
  const [loading, setLoading] = useState(false)

  const {
    errorCode,
    errorMessage,
    processError,
    resetErrors
  } = useErrorHandler()

  const userNameRef = useRef()
  const registerCodeRef = useRef()
  const pinCodeRef = useRef()

  useEffect(() => {
    if (errorCode === null) return

    if (errorCode === ERROR_CODES.userNotFound) {
      userNameRef.current.focus()
    } else if (errorCode === ERROR_CODES.unknownRegister) {
      registerCodeRef.current.focus()
    } else if (errorCode === ERROR_CODES.wrongPin) {
      pinCodeRef.current.focus()
    }
  }, [errorCode])

  function handleSubmit() {
    setLoading(true)
    resetErrors()
    authenticate(
      { username, secret, pin },
      () => {
        setLoading(false)
        dispatch(SetViewAnimation(NEXT))
        dispatch(SetViewId(REGISTER))
      },
      (error) => {
        const callback = () => {
          setLoading(false)
        }

        processError(error, callback) 
      }
    )
  }

  if (loading) return <Loader />

  return (
    <Form
      className='expand'
      theme={theme}
      shade={shade}
      onSubmit={(event) => {
        event.preventDefault()
        handleSubmit()
      }}
    >
      <Container maxWidth={700} center={true} gap={5}>
        {errorCode === ERROR_CODES.unknownError && (
          <Banner
            direction={COLUMN}
            animation={SLIDE_DOWN}
            theme={NOTIFY}
            shade={3}
            rounded={3}
            message={errorMessage}
          />
        )}
        <fieldset className='flex col gap-3'>
          <Field
            label='Username'
            type='email'
            id='username'
            inputRef={userNameRef}
            shade={0}
            theme={BRAND}
            onChange={(value) => setUsername(value)}
            defaultValue={username}
            shadow={1}
            valid={errorCode !== ERROR_CODES.userNotFound}
            errorMsg={errorMessage}
            required={true}
          />
          <div className='flex gap-3'>
            <Field
              label='Register code'
              type='text'
              id='secret'
              inputRef={registerCodeRef}
              shade={0}
              shadow={1}
              required={true}
              theme={BRAND}
              defaultValue={secret}
              onChange={(value) => setSecret(value)}
              valid={errorCode !== ERROR_CODES.unknownRegister}
              errorMsg={errorMessage}
            />
            <Field
              id='pin'
              inputRef={pinCodeRef}
              type='password'
              label='Pin code'
              shade={0}
              shadow={1}
              required={true}
              theme={BRAND}
              defaultValue={pin}
              onChange={(value) => setPin(value)}
              valid={errorCode !== ERROR_CODES.wrongPin}
              errorMsg={errorMessage}
            />
          </div>
        </fieldset>
        <Button
          type='submit'
          shade={5}
          theme={BRAND}
          size={LARGE}
          autoFocus={true}
        >
          Log in
        </Button>
      </Container>
      <AppVersion className="m-4" />
    </Form>
  )
}

export default Authentication
