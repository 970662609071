import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid'

import { MEDIUM, HUGE } from 'constants/size'
import { BRAND, NOTIFY, NEUTRAL, SUCCESS } from 'components/constants'
import {
  FULFILLMENT_STATUSES,
  FULFILLMENT_TYPES,
} from 'components/fulfillments/constants'
import { isReadOnly as getIsReadOnly } from 'components/fulfillments/helpers/isReadOnly'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Overlay from 'components/shared/Overlay'
import Tag from 'components/shared/Tag'

import FulfillmentStatusBlock from 'components/fulfillments/FulfillmentStatusBlock'
import FulfillmentCustomerBlock from 'components/fulfillments/FulfillmentCustomerBlock'
import FulfillmentItemsBlock from 'components/fulfillments/FulfillmentItemsBlock'
import FulfillmentSummaryModal from 'components/fulfillments/FulfillmentSummaryModal'
import FulfillmentLineItem from 'components/fulfillments/FulfillmentLineItem'

import getDateLocalTimezone from 'components/helpers/getDateLocalTimezone'
import getFriendlyName from 'components/fulfillments/helpers/getFriendlyName'
import getNextFulfillmentStatus from 'components/fulfillments/helpers/getNextFulfillmentStatus'

const Fulfillment = ({
  errorSyncing = false,
  currentOutlet = {},
  fulfillment = {},
  relatedFulfillments = [],
  isReadOnly = false,
  syncing,
  onSyncFulfillment,
  onCancel,
  onBack,
  onPrintPackingSlip,
  onSwitchFulfillment,
}) => {
  const [data, setData] = useState(fulfillment)
  const [readOnly, setReadOnly] = useState(isReadOnly)
  // Will be handled when we handle payments
  const [orderOwing, setOrderOwing] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [overlayActive, setOverlayActive] = useState(false)
  const [notes, setNotes] = useState(fulfillment.notes)
  const [noteError, setNoteError] = useState(false)
  const [fulfillmentItems, setFulfillmentItems] = useState(fulfillment.fulfillment_items)

  useEffect(() => {
    setData(fulfillment)
    setFulfillmentItems(fulfillment.fulfillment_items)
  }, [fulfillment])

  useEffect(() => {
    setReadOnly(getIsReadOnly(data, currentOutlet))
  }, [data])

  function handleItemsUpdate(updatedItems) {
    setButtonActive(false)
    setNoteError(false)

    const updatedFulfillmentItems = [...fulfillmentItems]
    for (const itemId in updatedItems) {
      const fulfillmentItem = updatedFulfillmentItems.find((item) => item.id === itemId)
      if (fulfillmentItem) {
        fulfillmentItem.quantity > 0 && setButtonActive(true)
        fulfillmentItem['adjustedQuantity'] = updatedItems[itemId].adjustedQuantity
        fulfillmentItem['deficit'] = updatedItems[itemId].deficit
      }
    }
    setFulfillmentItems(updatedFulfillmentItems)
  }

  function buildFulfillmentPayload() {
    const missingItems = []
    const fulfillmentItemsCopy = [...fulfillmentItems]
    const parentFulfillment = {
      ...data,
      fulfillment_items: fulfillmentItemsCopy,
      status: getNextFulfillmentStatus(data.status, data.type)
    }

    fulfillmentItemsCopy.forEach((originalItem, i) => {
      let missingItem = originalItem

      if (originalItem.deficit !== undefined && originalItem.deficit > 0) {
        missingItem = {
          ...originalItem,
          id: uuidv4(),
          quantity: originalItem.deficit,
        }
        fulfillmentItemsCopy[i] = { ...originalItem, quantity: originalItem.adjustedQuantity || originalItem.quantity }
        missingItems.push(missingItem)
      }
    })

    const updatedParentFulfillment = {
      ...parentFulfillment,
      fulfillment_items: fulfillmentItemsCopy,
      notes
    }

    let childFulfillment = {}

    if (missingItems.length > 0) {
      childFulfillment = {
        ...updatedParentFulfillment,
        id: uuidv4(),
        parent_fulfillment_id: updatedParentFulfillment.id,
        type: updatedParentFulfillment.type,
        status: data.status === FULFILLMENT_STATUSES.REQUESTED
          ? FULFILLMENT_STATUSES.OUT_OF_STOCK
          : FULFILLMENT_STATUSES.MISSING,
        sourceOutletId: updatedParentFulfillment.source.id,
        destinationOutletId: updatedParentFulfillment.source.id,
        created: Date.now(),
        fulfillment_items: missingItems,
      }
    }

    return { parentFulfillment: updatedParentFulfillment, childFulfillment }
  }

  function hasValidNotes() {
    if (!notes && hasDeficitInFulfillmentItems()) return false
    if (data.notes === notes && hasDeficitInFulfillmentItems()) return false

    return true
  }

  // TODO: Refactor this when working on other fulfillment types
  function onConfirm(confirmationOccurred, sucessCallback = () => null) {
    if (confirmationOccurred === false) {
      if (!hasValidNotes()) {
        setNoteError(true)
        focusAndScrollToNotes()
      } else {
        setNoteError(false)
        setOverlayActive(true)
      }
    } else {
      const fulfillmentPayload = buildFulfillmentPayload()
      const hasChildFulfillment = fulfillmentPayload.childFulfillment.id !== undefined

      const apiCallsSuccessCallback = () => {
        setData(fulfillmentPayload.parentFulfillment)
        if (fulfillmentPayload.parentFulfillment.status === FULFILLMENT_STATUSES.COMPLETED) {
          setOverlayActive(false)
        }

        setButtonActive(true)
        sucessCallback()
        scrollToTopOfFulfillmentDetail()
      }

      const onSuccessParentUpdateCallback = () => {
        // Create child/split fulfillment
        if (hasChildFulfillment) {
          onSyncFulfillment(
            { fulfillment: fulfillmentPayload.childFulfillment },
            apiCallsSuccessCallback
          )
        } else {
          apiCallsSuccessCallback()
        }
      }

      onSyncFulfillment(
        { fulfillment: fulfillmentPayload.parentFulfillment },
        onSuccessParentUpdateCallback
      )
    }
  }

  function hasDeficitInFulfillmentItems() {
    return fulfillmentItems.some(item => item.deficit > 0)
  }

  function focusAndScrollToNotes() {
    const notesElement = document.getElementById('notes')
    if (notesElement) {
      notesElement.focus()
      notesElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  function scrollToTopOfFulfillmentDetail() {
    const fulfillmentDetailElement = document.getElementById('fulfillment-detail')
    if (fulfillmentDetailElement) {
      fulfillmentDetailElement.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  function handleCloseModal() {
    setOverlayActive(false)
    document.getElementById('numberpickerInput_1').focus()
  }

  function handleChangeNote(e) {
    setNotes(e.target.value)
    setNoteError(false)
    console.log(errorSyncing)
  }

  function actionButtonCopy() {
    if (data.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP) {
      return "Release items"
    } else {
      return "Next"
    }
  }

  return (
    <Article
      className='bg-gradient relative expand z-0'
      gridTemplateRows='1fr'
    >
      {overlayActive &&
        <AnimatePresence>
          <Overlay
            opacity={.75}
          >
            <FulfillmentSummaryModal
              errorSyncing={errorSyncing}
              syncing={syncing}
              fulfillmentItems={fulfillmentItems}
              type={data.type}
              status={data.status.toUpperCase()}
              onUpdateFulfillmentItems={(items) => setFulfillmentItems(items)}
              onCloseFulfillment={() => onSwitchFulfillment(null)}
              onConfirm={(successCallback) => onConfirm(true, successCallback)}
              onClose={() => {
                handleCloseModal()
                if (onCancel) onCancel()
              }}
            >
            </FulfillmentSummaryModal>
          </Overlay>
        </AnimatePresence>
      }
      <div className='flex col overflow-y-scroll'>
        <ButtonMenu className='absolute top-0 x-0 p-2 z-1 justify-space-between'>
          <Button
            shade={0}
            rounded={4}
            size={MEDIUM}
            theme={BRAND}
            onClick={() => onBack()}
          >
            Back
          </Button>
          {(data.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP || data.status === FULFILLMENT_STATUSES.COMPLETED) && (
            <Button
              shade={3}
              rounded={4}
              size={MEDIUM}
              theme={FULFILLMENT_TYPES.TRANSFER}
              onClick={() => onPrintPackingSlip()}
            >
              Print Packing Slip
            </Button>
          )}
        </ButtonMenu>
        <Container
          scroll={true}
          gap={5}
          maxWidth={1000}
          className='expand pe-8'
          id="fulfillment-detail"
        >
          <header className='flex col gap-3'>
            <h1 className='font-size-9 hide-print'>
              {data.order_reference_number}
            </h1>
            <div className='flex align-items-center gap-3'>
              <Tag
                className='hide-print rounded-3 ps-2 pulse'
                theme={data.type}
                text={getFriendlyName(data.type)}
              />
              <strong className='font-size-3 color-shade-5'>
                Created {getDateLocalTimezone(data.created)}
              </strong>
            </div>
          </header>
          <section className='flex gap-3'>
            <FulfillmentStatusBlock
              data={data}
              orderOwing={orderOwing}
              className='bg-shade-1 rounded-2 p-4 grow'
            />
            <FulfillmentCustomerBlock
              className='bg-shade-1 rounded-2 p-4'
              customerName={data.contact_name}
            />
          </section>
          <div className='flex col bg-shade-0 shadow-2 rounded-2'>
            <FulfillmentItemsBlock
              fulfillmentItems={data.fulfillment_items}
              type={data.type.toUpperCase()}
              status={data.status.toUpperCase()}
              orderOwing={orderOwing}
              fulfillmentUpdate={(values) => handleItemsUpdate(values)}
              gridTemplateColumns={readOnly ? '2fr 1fr' : '2fr 1fr 2fr 1fr'}
              readOnly={readOnly}
            />
          </div>
          <div className='bg-shade-0 shadow-2 rounded-2' id="notes">
            <h3 className="font-size-2 text-center me-2">Notes:</h3>
            <div className="font-size-2 flex col ps-3 pb-3">
              <textarea
                name="notes_input"
                disabled={readOnly}
                className={`bg-shade-1 border-x p-5 font-size-2 rounded-3 expand-x ${noteError && "theme-notify-3"}`}
                style={{ appearance: 'none', outline: 'none', borderRadius: !noteError ? "12px" : "12px 12px 0 0" }}
                defaultValue={notes}
                theme={noteError ? NOTIFY : NEUTRAL}
                shade={3}
                onChange={(e) => {
                  setNoteError(false)
                  handleChangeNote(e)
                }}
                rows={3}
              ></textarea>
              {noteError &&
                <h3 className="font-size-2 theme-notify-2 text-center p-2 rounded-bottom-3">
                  {!notes ?
                    'You must enter a note to continue'
                    : 'You must update your note to continue'
                  }
                </h3>
              }
            </div>
          </div>
          {relatedFulfillments.length > 0 &&
            <div data-testid="related-fulfillments" className='flex col bg-shade-0 shadow-2 rounded-2'>
              <h3 className="font-size-2 text-center me-2">Other items in this order:</h3>
              {
                relatedFulfillments.map((ffment) => (
                  <FulfillmentLineItem
                    key={ffment.id}
                    fulfillment={ffment}
                    onSetFulfillment={() => onSwitchFulfillment(ffment)}
                  />
                ))
              }
            </div>
          }
        </Container>
        {(!readOnly && buttonActive) &&
          <ButtonMenu id='footer' theme={BRAND} className={"flex"}>
            <Button
              dataTestId="next-button"
              theme={data.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP ? SUCCESS : BRAND}
              disabled={!buttonActive}
              className="shrink"
              onClick={() => onConfirm(false)}
              size={HUGE}
              rounded={0}
              shade={5}
            padding={7}
            >
              {data.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP ? "Release items" : "Next"}
            </Button>
          </ButtonMenu>
        }
      </div>
    </Article>
  )
}

export default Fulfillment