import ThermalPrinterEncoder from 'thermal-printer-encoder/dist/thermal-printer-encoder.esm.js';

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  PRINTER_TYPES,
  PRINTABLE_TYPES,
  SUPPORTED_PRINTER_MODELS,
  RECEIPT_CONTEXT,
  LOCALES,
} from 'constants/settings'
import { log } from 'dev/log'
import { fetchPrintableLabels } from 'data/api/fetchPrintableLabels'
import { LiquidRenderer } from 'helpers/liquid/liquidRenderer.mjs';

import UpdateDevices from 'components/settings/actions/UpdateDevices'
import SetPrintableLabels from 'components/printableLabels/actions/SetPrintableLabels'
import ReceiptPrinter from 'components/settings/helpers/receiptPrinter';

export const FAILED_CONNECTION = 'Oops! Something went wrong. Please check your printer connection settings and try again.'
import useSyncProducts from 'hooks/useSyncProducts'

import getOrderDrop from '../liquid/getOrderDrop'
import getOutletDrop from '../liquid/getOutletDrop'
import getStoreDrop from '../liquid/getStoreDrop'

// ************************************

// printerTypes are label, receipt
// printableTypes are product, order

// ************************************

const useHandlePrinting = (printerType = null, printableType = null) => {
  const dispatch = useDispatch()
  const { products } = useSyncProducts({ poll: false })

  const currentOutlet = useSelector((state) => state.outlet)
  const devices = useSelector((state) => state.settings.devices)
  const drawer = useSelector((state) => state.settings.drawer)
  const printers = useSelector((state) => state.settings.printers)
  const printingContexts = useSelector((state) => state.settings.contexts)
  const printingTemplates = useSelector((state) => state.printableLabels)
  const liquidSchemas = useSelector((state) => state.schemas)
  const store = useSelector((state) => state.store)

  const [deviceError, setDeviceError] = useState(null)
  const [currentPrinter, setCurrentPrinter] = useState(null)
  const [deviceFailed, setDeviceFailed] = useState(false)
  const [deviceAvailable, setDeviceAvailable] = useState(true)

  let receiptPrintQueue = []

  const liquidRenderer = new LiquidRenderer(LOCALES.EN_AU, store.currency)

  // Web USB Support
  const devicesSource = navigator.usb

  // Fetch printable templates.
  // Check printer connections based on printer type
  // and setup current printer for easy access.
  useEffect(() => {
    fetchPrintableLabels((labels) => dispatch(SetPrintableLabels(labels)))

    // Inform users that the current browser does not support WebUSB
    if (browserNotSupported()) {
      handleFaileDevice('Ooops! Printing is not supported for this browser.')
      return
    }

    if (printerType === null || printableType === null) return
    loadDevices()

    setCurrentPrinterDetails(printerType)
    checkPrinterDeviceStatus(printerType)

    // Check if there is a printer assigned for that type
    const printerAvailable = isDeviceAvailable(printerType)
    if (!printerAvailable) {
      setDeviceAvailable(false)
      handleFaileDevice('Printer not available. Please go to settings and set it up first.')
      return
    }

    // Check if default template for receipt printing is set
    if (printerType === PRINTER_TYPES.RECEIPT) {
      const receiptContext = printingContexts.find((context) => context.key === RECEIPT_CONTEXT);
      if (receiptContext === null || receiptContext.template === null) {
        handleFaileDevice('Receipt template not set or may be invalid. Go to settings and set it up first.')
      }
    }

    // This makes sure that we have an updated list of devices
    // during unmount, so that we can check if selected devices are still available.
    return () => loadDevices()
  }, [])

  /******* METHODS ******/

  function browserNotSupported() {
    return devicesSource === undefined
  }

  function setCurrentPrinterDetails(key) {
    const matchedPrinter = printers.find(printer => printer.key === key)
    if (matchedPrinter === null || matchedPrinter.device === null) return
    const device = JSON.parse(matchedPrinter.device)
    setCurrentPrinter(SUPPORTED_PRINTER_MODELS[device.productName])
  }

  // Data: can be a single object or array of objects
  async function print(data, template = null, quantity = 1) {
    if (printerType === null) return;

    switch (printerType) {
      case PRINTER_TYPES.LABEL:
        const content = await processLabelPrintableContent(data, template, quantity);
        executeLabelPrint(content);
        break;

      case PRINTER_TYPES.RECEIPT:
        const receiptContext = printingContexts.find((context) => context.key === RECEIPT_CONTEXT);
        if (receiptContext !== null && receiptContext.template !== null) {
          executeReceiptPrint(data, template || receiptContext.template);
        } else {
          handleFaileDevice('Receipt template not set or maybe invalid. Go to settings and set it up first.')
        }
        break;
      // Add more cases as needed for other printer types
      default:
        // Handle unsupported printer type or do nothing
        break;
    }
  }

  // ************* LABELS FUNCTIONS **************************************

  async function processLabelPrintableContent(data, template, quantity) {
    const _data = !Array.isArray(data) ? [data] : data;
    const renderPromises = _data.flatMap(data => {
      const renderArgs = printableType === PRINTABLE_TYPES.PRODUCT
        ? { product: data }
        : printableType === PRINTABLE_TYPES.ORDER
          ? { order: data }
          : {};

      return Array.from({ length: quantity }, () =>
        liquidRenderer.render(template.label_template, renderArgs)
      );
    });

    try {
      const renderedResults = await Promise.all(renderPromises);
      return renderedResults.join(' ');
    } catch (error) {
      log(error);
    }
  }

  function executeLabelPrint(printableLabels) {
    let printer = printers.find(printer => printer.key === PRINTER_TYPES.LABEL)

    if (printer) {
      const doPrint = (connectedDevice) => {
        let encoder = new TextEncoder("utf-8")
        let commands = encoder.encode(printableLabels)
        connectedDevice.transferOut(1, commands)
          .catch(error => {
            log(error)
            handleFaileDevice(FAILED_CONNECTION)
          })
      }

      connectToDeviceAndPrint(printer.device, (connectedDevice) => doPrint(connectedDevice))
    }
  }

  // ************* RECEIPT FUNCTIONS *************************************

  // This can accept a parameter to build receipt content
  function executeReceiptPrint(data, template) {
    let printer = printers.find(printer => printer.key === PRINTER_TYPES.RECEIPT)
    if ((printer === null || printer === undefined) && window.mockPrinter === undefined) return

    receiptPrintQueue.push({ data: data, template: template })
    if (receiptPrintQueue.length === 1) {
      executeReceiptPrintQueue(printer)
    }
  }

  function executeReceiptPrintQueue(printer) {
    if (receiptPrintQueue.length === 0) return

    const printJob = receiptPrintQueue[0]
    const data = printJob.data
    const template = printJob.template
    const storeDrop = getStoreDrop(liquidSchemas, store)

    const executePrint = (connectedDevice) => {
      liquidRenderer
        .render(template, {
          data: data,
          current_order: getOrderDrop(liquidSchemas, () => storeDrop, data, store.orderUrl, products),
          current_outlet: getOutletDrop(liquidSchemas, () => storeDrop, currentOutlet)
        })
        .then(rendered => {
          if (window.mockPrinter) return window.mockPrinter.print(rendered)

          const receiptPrinter = new ReceiptPrinter(
            rendered,
            currentPrinter,
            connectedDevice,
            () => handleFaileDevice(FAILED_CONNECTION)
          )
          receiptPrinter.print(
            () => {
              receiptPrintQueue.shift()
              executeReceiptPrintQueue(printer)
            }
          )
        });
    }

    if (window.mockPrinter) {
      executePrint(null)
    } else {
      connectToDeviceAndPrint(printer.device, (connectedDevice) => executePrint(connectedDevice))
    }
  }

  function executeOpenDrawer() {
    let printer = printers.find(printer => printer.key === PRINTER_TYPES.RECEIPT)
    const encoder = new ThermalPrinterEncoder({ language: 'star-prnt' })

    if (printer) {
      const executePrint = (connectedDevice) => {
        let commands = encoder
          .pulse()
          .encode()

        connectedDevice.transferOut(1, commands)
          .catch(error => {
            log(error)
            handleFaileDevice(FAILED_CONNECTION)
          })
      }

      connectToDeviceAndPrint(printer.device, (connectedDevice) => executePrint(connectedDevice))
    }
  }

  // ********************** UTILITIES ************************************

  function connectToDeviceAndPrint(_device, callback) {
    if (browserNotSupported()) return

    const failedMessage = 'Oops! Previously connected device not found. Go to settings.'
    devicesSource.getDevices()
      .then(devices => {
        if (devices.length > 0) {
          let device = devices.find(d => deviceFingerPrint(d) === _device);
          if (device !== undefined) setupDevice(device, callback);
          else handleFaileDevice(failedMessage)
        } else handleFaileDevice(failedMessage)
      })
      .catch(_error => {
        log(_error)
        handleFaileDevice(FAILED_CONNECTION)
      });
  }

  async function setupDevice(device, successCallback = () => null) {
    try {
      return device.open()
        .then(() => device.selectConfiguration(1))
        .then(() => {
          device.claimInterface(0)
            .then(() => {
              resetDeviceFailedStates()
              successCallback(device)
            })
            .catch(error => {
              log(error)
              handleFaileDevice(FAILED_CONNECTION)
            })
        })
        .catch(error => {
          log(error)
          handleFaileDevice(FAILED_CONNECTION)
        })
    } catch (error) {
      log(error)
      handleFaileDevice(FAILED_CONNECTION)
    }
  }

  function requestDevices(successCallback = () => null, failCallback = () => null) {
    if (devicesSource === undefined) return

    devicesSource.requestDevice({ filters: [] })
      .then((device) => {
        resetDeviceFailedStates()
        setupDevice(device, () => successCallback(device))

        // Set devices in redux
        loadDevices()
      })
      .catch(error => {
        log(error)

        // Hardcoded check so that we can skip failcallback
        // when the user does not select a device
        if (error.message.toLowerCase().includes("no device selected")) return
        handleFaileDevice(FAILED_CONNECTION)
        failCallback()
      })
  }

  function loadDevices() {
    if (browserNotSupported()) return

    devicesSource.getDevices()
      .then(_devices => {
        if (devices !== _devices) {
          dispatch(UpdateDevices(stringifyDevices(_devices)))
        }
      })
      .catch((error) => log(error))
  }

  function drawerPrinter() {
    if (drawer.printerKey === null) return null

    return printers.find((printer) => printer.key === drawer.printerKey)
  }

  function interpolateDeviceLabel(device) {
    return `${device.manufacturerName} - ${device.productName}`
  }

  function parseDevice(device) {
    return JSON.parse(device)
  }

  function resetDeviceFailedStates() {
    setDeviceError(null)
    setDeviceFailed(false)
  }

  function handleFaileDevice(errorMessage) {
    setDeviceFailed(true)
    setDeviceError(errorMessage)
  }

  function stringifyDevices(devices) {
    return devices.map((device) => deviceFingerPrint(device))
  }

  function checkPrinterDeviceStatus(key) {
    setDeviceError(null)
    if (window.mockPrinter) return

    const printer = printers.find((printer) => printer.key === key)
    if (printer && printer.device === null) {
      handleFaileDevice('Printer not available. Please go to settings and set it up first.')
    }
  }

  function isDeviceAvailable(deviceKey) {
    if (window.mockPrinter !== undefined) return true
    const matchedPrinter = printers.find(printer => printer.key === deviceKey)
    if (matchedPrinter === undefined) return false
    if (matchedPrinter.device === null) return false

    const _device = devices.find(d => d === matchedPrinter.device)
    return _device !== undefined
  }

  function deviceFingerPrint(device) {
    return JSON.stringify({
      deviceClass: device.deviceClass,
      deviceProtocol: device.deviceProtocol,
      deviceSubclass: device.deviceSubclass,
      deviceVersionMajor: device.deviceVersionMajor,
      deviceVersionMinor: device.deviceVersionMinor,
      deviceVersionSubminor: device.deviceVersionSubminor,
      manufacturerName: device.manufacturerName,
      productId: device.productId,
      productName: device.productName,
      serialNumber: device.serialNumber,
      usbVersionMajor: device.usbVersionMajor,
      usbVersionMinor: device.usbVersionMinor,
      usbVersionSubminor: device.usbVersionSubminor,
      vendorId: device.vendorId,
    });
  }

  function filterPrintingTemplates() {
    if (printerType !== null && printableType !== null) {
      return printingTemplates.filter((printingTemplate) => {
        return printingTemplate.printer_type?.toLowerCase() === printerType.toLowerCase() &&
          printingTemplate.type?.toLowerCase() === printableType.toLowerCase()
      })
    }

    return printingTemplates
  }

  return {
    checkPrinterDeviceStatus,
    deviceFailed,
    deviceError,
    drawer,
    drawerPrinter,
    deviceAvailable,
    executeLabelPrint,
    executeReceiptPrint,
    executeOpenDrawer,
    print,
    printers,
    printingContexts,
    printingTemplates: filterPrintingTemplates(),
    devicesSource,
    devices,
    parseDevice,
    setupDevice,
    deviceFingerPrint,
    requestDevices,
    loadDevices,
    isDeviceAvailable,
    interpolateDeviceLabel
  }
}

export default useHandlePrinting