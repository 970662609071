import React from 'react'
import classNames from 'classnames'
import htmlParser from 'html-react-parser'

import { BRAND, NEUTRAL, SCALE_IN, LARGE, SLIDE_UP } from 'components/constants'

import Overlay from 'components/shared/Overlay'
import Button from 'components/shared/Button'
import Form from 'components/shared/Form'

const MAX_WIDTH = 600

const ConfirmationModal = ({
  id = 'confirmation-modal',
  className,
  zIndex = 2,
  message,
  onClose,
  onSubmit,
}) => {
  return (
    <Overlay onClose={onClose}>
      <Form
        id={id}
        shade={0}
        theme={BRAND}
        shadow={0}
        rounded={3}
        animation={SCALE_IN}
        maxWidth={MAX_WIDTH}
        expand={false}
        onSubmit={() => onSubmit()}
        className={classNames({
          ['z-' + zIndex]: !!zIndex,
          [className]: className
        })}
      >
        <>
          <div className="p-6">
            {htmlParser(message)}
          </div>
          <div className="flex">
            <Button
              className='flex-basis-0 grow gap'
              onClick={onClose}
              size={LARGE}
              theme={NEUTRAL}
              shade={2}
              animation={SLIDE_UP}
              rounded={0}
            >Close</Button>
            <Button
              className='flex-basis-0 grow gap'
              size={LARGE}
              theme={BRAND}
              shade={5}
              animation={SLIDE_UP}
              rounded={0}
              onClick={onSubmit}
              type='submit'
            >Continue</Button>
          </div>
        </>
      </Form>
    </Overlay>
  )
}

export default ConfirmationModal