import { DELETE_CART_ITEM_FULFILLMENT } from '../constants'

export default function DeleteCartItemFulfillment(payload) {
  return {
    type: DELETE_CART_ITEM_FULFILLMENT,
    payload
  }
}


