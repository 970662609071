import React from 'react'
import classNames from 'classnames'
import { AnimatePresence } from 'framer-motion'

import { BRAND, NEUTRAL, MEDIUM, NEXT, LARGE, CHECK_MARK } from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import SettingLineItem from 'components/settings/SettingLineItem'
import Icon from 'components/shared/Icon'

const EftPosDetails = ({
  animation = NEXT,
  className,
  theme = BRAND,
  shadow = 0,
  rounded = 0,
  width,
  height,
  printTyroReceipt = false,
  alwaysPrintMerchantReceipt = false,
  onSetPrintTyroReceipt,
  onSetAlwaysPrintMerchantReceipt,
  onBack,
  onConnect
}) => {
  return (
    <Article
      gridTemplateRows='auto auto 1fr auto'
      className={classNames({
        'overflow-y-auto expand': true,
        [className]: className,
      })}
      shade={1}
      theme={NEUTRAL}
      rounded={rounded}
      shadow={shadow}
      maxWidth={width}
      maxHeight={height}
      animation={animation}
    >
      <ButtonMenu
        shade={1}
        theme={NEUTRAL}
        className='gap-1px p-4 mb-6 justify-space-between align-items-center'
      >
        <Button
          shade={1}
          rounded={4}
          size={MEDIUM}
          theme={theme}
          onClick={onBack}
        >
          <>Back</>
        </Button>
        <span className='grow font-weight-3 font-size-5 text-center'>
          Tyro
        </span>
        <span style={{ width: 100 }}></span>
      </ButtonMenu>

      <div className="ps-8 mb-4">
        <iframe id="iframe" style={{ display: 'none', width: '100%', height: 400 }}></iframe>
      </div>

      <div className="ps-8">
        <section className="mb-2 flex col">
          <SettingLineItem
            first={true}
            last={false}
            padding={4}
            className="flex border-ends font-weight-2 justify-space-between items-center align-items-center"
            onClick={() => {
              onSetPrintTyroReceipt(!printTyroReceipt)
            }}
          >
            <div>Print Tyro receipts using StoreConnect printer</div>
            {printTyroReceipt && (
              <span className="theme-brand color-brand-5">
                <Icon type={CHECK_MARK} />
              </span>
            )}
          </SettingLineItem>
          <SettingLineItem
            first={false}
            last={true}
            padding={4}
            className="flex border-ends font-weight-2 justify-space-between items-center align-items-center"
            onClick={() => {
              onSetAlwaysPrintMerchantReceipt(!alwaysPrintMerchantReceipt)
            }}
          >
            <div>Always Print Tyro Merchant Receipt</div>
            {alwaysPrintMerchantReceipt && (
              <span className="theme-brand color-brand-5">
                <Icon type={CHECK_MARK} />
              </span>
            )}
          </SettingLineItem>
        </section>
      </div>

      <AnimatePresence>
        <ButtonMenu
          className='absolute z-4 flex justify-center pe-5 pointer-events-x'
          style={{ bottom: 'var(--sc-spacing-5)', left: 0, right: 0 }}
        >
          <Button
            shade={5}
            rounded={4}
            theme={BRAND}
            size={LARGE}
            className='shadow-3 pointer-events-all'
            style={{ width: 380 }}
            onClick={onConnect}
          >
            Connect
          </Button>
        </ButtonMenu>
      </AnimatePresence>
    </Article>
  )
}

export default EftPosDetails