import { useState } from 'react'

export const ERROR_CODES = {
  userNotFound: 'POS01001',
  wrongPin: 'POS01002',
  unknownRegister: 'POS02001',
  unknownError: 'unknownError'
}

const useErrorHandler = () => {
  const [errorCode,  setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const processError = async (error, callback = null) => {
    try {
      const error_json = await error.json()
      setErrorCode(error_json.error_code)
      setErrorMessage(`${error_json.error_code}: ${error_json.message}`)
    } catch {
      setErrorCode(ERROR_CODES.unknownError)
      setErrorMessage('Sorry, we encountered an error. Please try again and contact support if problem persists.')
    }

    if (callback) callback()
  }

  const resetErrors = () => {
    setErrorCode(null)
    setErrorMessage(null)
  }

  return {
    errorCode,
    errorMessage,
    resetErrors,
    setErrorCode,
    setErrorMessage,
    processError,
  }
}

export default useErrorHandler