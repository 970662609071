import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { NEUTRAL } from 'components/constants'
import { INCREMENT, DECREMENT } from 'components/constants'

import Icon from 'components/shared/Icon'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Accordion = ({
  children,
  className,
  headline = '',
  style,
  theme = NEUTRAL,
  shade = 0,
  rounded = 2,
  shadow = 0,
  gap = 0,
  open = false,
  openCallback,
  ...data
}) => {
  const [isOpen, setOpen] = useState(open)

  useEffect(() => {
    if (openCallback) openCallback(isOpen)
  }, [isOpen])

  return (
    <li
      {...data}
      style={style}
      className={classNames({
        'flex col no-shrink z-0': true,
        ['overflow-hidden rounded-' + rounded]: rounded,
        [getThemeStyleClass(theme, shade)]: theme !== undefined,
        [className]: className,
      })}>
      <button
        onClick={() => setOpen(!isOpen)}
        style={{
          borderBottomLeftRadius: isOpen ? 0 : 'inherit',
          borderBottomRightRadius: isOpen ? 0 : 'inherit',
        }}
        className={classNames({
          'flex align-items-center justify-space-between ps-4': true,
          'bg-transparent rounded-2 input-height-2 font-size-2 font-weight-3': true,
          'border-top shadow-1': shadow === true,
          ['rounded-' + rounded]: rounded,
        })}>
        <span>{headline}</span>
        <span className='opacity-05 flex align-items-center'>
          <Icon
            type={INCREMENT}
            size={18}
            className={classNames({ hide: isOpen === true })}
          />
          <Icon
            type={DECREMENT}
            size={18}
            className={classNames({ hide: isOpen === false })}
          />
        </span>
      </button>
      {isOpen && children}
    </li>
  )
}

export default Accordion
