import getSearchString from 'components/helpers/getSearchStr'
import isSellable from './isSellable'

export default function getFilteredProducts(products = [], string = '') {
  const keywords = getKeywordsArray(string)

  if (keywords.length === 0) return products.filter((product) => isSellable(product))
  return products.filter((product) => isMatch(keywords, product) && isSellable(product))
}

function isMatch(keywords = [], product = {}) {
  const { name, category, code, barcode } = getSearchProps(product)
  // Return true if all keywords are matched
  return keywords.filter((keyword) => {
    if (name.includes(keyword)) return true
    if (category && category.includes(keyword)) return true
    if (code && code.includes(keyword)) return true
    if (barcode && barcode.includes(keyword)) return true
    return false
  }).length === keywords.length
}

function getSearchProps(product = {}) {
  return {
    name: getSearchString(product.name),
    category: getSearchString(product.category),
    code: getSearchString(product.product_code),
    barcode: getSearchString(product.barcode),
  }
}

function getKeywordsArray(string = '') {
  if (string.length === 0) return []
  return string.split(' ').map(k => getSearchString(k))
}
