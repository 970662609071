export default async function authenticate(payload, onSuccess, onError) {
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {'Content-Type': 'application/json'},
  }
  fetch('/v1/session', options)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        return Promise.reject(response)
      }
    })
    .then(data => {
      const { token } = data;
      window.localStorage.setItem('apiToken', token)
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}
