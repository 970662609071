import React from 'react'

import { NOTIFY, BRAND } from 'components/constants'
import { SMALL } from 'components/constants'

import Tag from 'components/shared/Tag'
import ButtonLineItem from 'components/shared/ButtonLineItem'
import Checkbox from 'components/shared/Checkbox'
import ProductHeadline from './shared/ProductHeadline'
import ProductPrice from './shared/ProductPrice'

import getStockCountByOutlet from 'components/products/helpers/getStockCountByOutlet'

const ProductTableRow = ({
  limitedColumns = false,
  selectable = false,
  selected = false,
  gridTemplateColumns,
  outlets = [],
  product = {},
  onClick,
}) => {
  return (
    <ButtonLineItem
      padding={4}
      className='align-items-center gap-3 focus:outline'
      gridTemplateColumns={gridTemplateColumns}
      onClick={(event) => onClick(event)}
      canHover={false}
    >
      <span className='flex row gap-1'>
        {selectable === true && (
          <Checkbox
            checked={selected}
            dataTestId={`product-checkbox-${product.id}`}
          />
        )}
        <ProductHeadline product={product} />
      </span>
      <ProductPrice product={product} />
      {limitedColumns === false && outlets.slice(0, 4).map((outlet) => {
        const stockCount = getStockCountByOutlet(outlet.id, product.stock)
        if (product.track_inventory === false) return null
        return  (
          <div key={outlet.id}>
            <Tag
              shade={3}
              dataTag='stock-count'
              size={SMALL}
              theme={stockCount === 0 ? NOTIFY : BRAND}
              text={stockCount}
            />
          </div>
        )
      })}
    </ButtonLineItem>
  )
}

export default ProductTableRow
