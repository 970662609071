// Empty arrays means there will be no actions and will be read only

import { CURRENT_OUTLET_ROLE } from "components/fulfillments/constants"

const COMMON_ACTIONS = {
  REQUESTED: ['source'],
  PICKED: ['source'],
  PACKED: ['source'],
  READY_FOR_PICKUP: ['source'],
  SHIPPED: ['destination'],
  ORDERED: ['source'],
  RECEIVED: ['source'],
  COMPLETE: [],
  OUT_OF_STOCK: [],
  MISSING: []
}

const OUTLET_ROLE_STATUS_ACTIONS = {
  PICKUP: COMMON_ACTIONS,
  HOLD: COMMON_ACTIONS,
  TAKEAWAY: { COMPLETE: [] }, // FINALIZE ONCE NEEDED
  DELIVERY: COMMON_ACTIONS, // FINALIZE ONCE NEEDED
  TRANSFER: {
    ...COMMON_ACTIONS,
    SHIPPED: ['destination'],
    READY_FOR_PICKUP: ['destination'],
  },
  SPECIAL_ORDER: {
    ...COMMON_ACTIONS,
  }
}

export const isReadOnly = (fulfillment, currentOutlet) => {
  // If the current outlet is not the source or destination, they will be read only.
  // This will happen if the current outlet is the creator only of the fulfillment.
  if (currentOutlet.id !== fulfillment.source.id
    && currentOutlet.id !== fulfillment.destination.id
  ) {
    return true
  }

  const outletRole = fulfillment.source.id === currentOutlet.id ?
    CURRENT_OUTLET_ROLE.SOURCE :
    CURRENT_OUTLET_ROLE.DESTINATION

  const fulfillmentType = fulfillment.type.toUpperCase()
  const status = fulfillment.status.toUpperCase()
  
  return !OUTLET_ROLE_STATUS_ACTIONS[fulfillmentType][status].includes(outletRole)
}