import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { NEUTRAL } from 'components/constants'
import { FULFILLMENT_CATEGORIES, FULFILLMENT_STATUSES, READ_ONLY_STATUSES } from 'components/fulfillments/constants'
import { BACK, NEXT, COLUMN, SLIDE_UP, NOTIFY, SMALL, BRAND } from 'components/constants'
import { FulFillmentsContext } from 'components/fulfillments/Provider'
import { isReadOnly } from 'components/fulfillments/helpers/isReadOnly'

import useSyncFulfillments from 'hooks/useSyncFulfillments'

import Button from 'components/shared/Button'
import Banner from 'components/shared/Banner'
import Empty from 'components/shared/Empty'
import Loader from 'components/shared/Loader'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Main from 'components/shared/Main'

import Fulfillment from 'components/fulfillments/Fulfillment'
import FulfillmentFilters from 'components/fulfillments/FulfillmentFilters'
import FulfillmentsList from 'components/fulfillments/FulfillmentsList'

const Fulfillments = ({ }) => {
  const [animation, setAnimation] = useState(NEXT)
  const [selectedFulfillment, setSelectedFulfillment] = useState(null)
  const [filteredFulfillments, setFilteredFulfillments] = useState([])
  const outlets = useSelector((state) => state.outlets)

  // It needs constant checking for fulfillments and so we are enabling polling
  const {
    updateFulfillment,
    fulfillments,
    fetching,
    syncing,
    errorFetching,
    errorSyncing,
    resetApiStates,
  } = useSyncFulfillments(true)

  const {
    statusFilters,
    keywordFilter,
    clearFilters,
    setStatusFilters
  } = useContext(FulFillmentsContext)

  useEffect(() => {
    if (selectedFulfillment === null) return

    const updatedSelectedFulfillment = fulfillments.find((fulfillment) => fulfillment.id === selectedFulfillment.id)
    if (updatedSelectedFulfillment) setSelectedFulfillment(updatedSelectedFulfillment)
  }, [fulfillments])

  // This will set the initial actionable statuses selected
  useEffect(() => {
    const initialStatusesSelected = Object.values(FULFILLMENT_STATUSES)
      .filter(value => typeof value === 'string' && !READ_ONLY_STATUSES.includes(value))

    setStatusFilters(initialStatusesSelected)
  }, [])

  // Check if needed to apply filter
  useEffect(() => {
    let filteredData = fulfillments

    if (keywordFilter.length > 0) {
      const filterWord = keywordFilter.toLowerCase()
      filteredData = filteredData.filter(item =>
        item.order_reference_number.toLowerCase().includes(filterWord) ||
        item.type.toLowerCase().includes(filterWord) ||
        item.status.toLowerCase().includes(filterWord)
      )
    }
    if (statusFilters.length > 0) {
      filteredData = filteredData.filter(item => statusFilters.includes(item.status))
    }
    setFilteredFulfillments(filteredData)
  }, [keywordFilter, statusFilters, fulfillments])

  function switchFulfillment(fulfillment) {
    setAnimation(NEXT)
    setSelectedFulfillment(fulfillment)
  }

  function hasFilterValues() {
    return keywordFilter.length > 0 || statusFilters.length > 0
  }

  function currentOutletIsReadOnly() {
    const currentOutlet = outlets.find((outlet) => outlet.current === true)
    if (selectedFulfillment && currentOutlet) {
      return isReadOnly(selectedFulfillment, currentOutlet)
    }
    return false
  }

  return (
    <>
      {selectedFulfillment !== null && (
        <View shade={1} theme={NEUTRAL} animation={animation}>
          <Fulfillment
            syncing={syncing}
            currentOutlet={outlets.find((outlet) => outlet.current === true)}
            errorSyncing={errorSyncing}
            fulfillment={selectedFulfillment}
            relatedFulfillments={
              fulfillments.filter((fulfillment) =>
                fulfillment.order_id === selectedFulfillment.order_id && fulfillment.id !== selectedFulfillment.id
              )
            }
            isReadOnly={currentOutletIsReadOnly()}
            onSyncFulfillment={updateFulfillment}
            onCancel={resetApiStates}
            onBack={() => {
              setAnimation(BACK)
              setSelectedFulfillment(null)
            }}
            onSwitchFulfillment={(ffment) => switchFulfillment(ffment)}
            onPrintPackingSlip={() => alert('PRINT ME')}
          />
        </View>
      )}
      {selectedFulfillment === null && (
        <View className='bg-gradient-2' theme={NEUTRAL} animation={animation}>
          <Topbar className='border-bottom' />
          <Main className='expand-x overflow-x-auto'>
            <FulfillmentFilters
              className='bg-shade-1 pe-2 ps-3 flex wrap'
              fulfillmentCategories={FULFILLMENT_CATEGORIES}
            />
            {fetching === true && fulfillments.length === 0 && <Loader />}
            {fetching === false && fulfillments.length === 0 && (
              <div className="text-center me-3 mt-4">
                <Empty message={'No records found'} />
              </div>
            )}
            {fetching === false &&
              (hasFilterValues() &&
              fulfillments.length > 0 &&
              filteredFulfillments.length === 0) && (
                <div className="text-center me-3 mt-4">
                  <p>Sorry... no records match your search filters</p>
                  <Button
                    className="ms-auto me-2"
                    shade={5}
                    theme={BRAND}
                    size={SMALL}
                    onClick={clearFilters}
                  >Reset Filters</Button>
                </div>
              )}
            {filteredFulfillments.length > 0 && (
              <FulfillmentsList
                fulfillments={filteredFulfillments}
                onSetFulfillment={(fulfillment) => {
                  setAnimation(NEXT)
                  setSelectedFulfillment(fulfillment)
                }}
              />
            )}
          </Main>
          {errorFetching === true && (
            <Banner
              direction={COLUMN}
              animation={SLIDE_UP}
              theme={NOTIFY}
              shade={3}
              message={'Oops, failed to fetch records. Auto-retry in progress. Please wait.'}
            />
          )}
        </View>
      )}
    </>
  )
}

export default Fulfillments