import { SYDNEY_OUTLET, MELBOURNE_OUTLET, NEW_YORK_OUTLET } from 'data/dummy/outlets'
import { FULFILLMENT_STATUSES, FULFILLMENT_TYPES } from '../../components/fulfillments/constants'
import { SWEATER_S, SWEATER_M, LARGE_CERAMIC_VASE } from './products'

export const TAKE_AWAY_LARGE_CERAMIC_VASE = {
  id: 1,
  order_id: "ABCDEFG123456",
  fulfillment_id: "A",
  created: new Date('2023-12-31').valueOf(),
  type: FULFILLMENT_TYPES.CLICK_AND_COLLECT,
  status: FULFILLMENT_STATUSES.REQUESTED,
  origin: SYDNEY_OUTLET,
  destination: MELBOURNE_OUTLET,
  order_items: [
    // Note: Should we be fetching the order items
    // separately from the order or fulfillment and
    // referencing it by ID?
    { ...LARGE_CERAMIC_VASE, quantity: 20 },
    { ...SWEATER_M, quantity: 5 },
  ],
  read: false,
  quantity: 2,
}

export const TAKE_AWAY_LARGE_CERAMIC_VASE2 = {
  id: 11,
  order_id: "ABCDEFG123456",
  fulfillment_id: "B",
  created: new Date('2023-12-31').valueOf(),
  type: FULFILLMENT_TYPES.CLICK_AND_COLLECT,
  status: FULFILLMENT_STATUSES.PICKED,
  origin: SYDNEY_OUTLET,
  destination: MELBOURNE_OUTLET,
  order_items: [
    { ...LARGE_CERAMIC_VASE, quantityPicked: 20, quantity: 20 },
  ],
  read: false,
  quantity: 2,
}

export const TAKE_AWAY_LARGE_CERAMIC_VASE3 = {
  id: 111,
  order_id: "ABCDEFG123456",
  fulfillment_id: "C",
  created: new Date('2023-12-31').valueOf(),
  type: FULFILLMENT_TYPES.CLICK_AND_COLLECT,
  status: FULFILLMENT_STATUSES.READY_FOR_PICKUP,
  origin: SYDNEY_OUTLET,
  destination: MELBOURNE_OUTLET,
  order_items: [
    { ...LARGE_CERAMIC_VASE, quantityPicked: 15, quantityPacked: 15, quantity: 20 },
  ],
  read: false,
  quantity: 2,
}

export const TRANSFER_SWEATER_M_SYD_TO_MEL = {
  id: 2,
  order_id: "SC16975818372937",
  fulfillment_id: "A",
  created: new Date('2023-12-30').valueOf(),
  type: FULFILLMENT_TYPES.TRANSFER,
  status: FULFILLMENT_STATUSES.COMPLETED,
  origin: SYDNEY_OUTLET,
  destination: MELBOURNE_OUTLET,
  order_items: [
    { ...SWEATER_M, quantity: 50 },
  ],
  read: false,
}

export const TRANSFER_SWEATERS_SYD_TO_NY = {
  id: 3,
  order_id: "SC16975818372937",
  created: new Date('2023-12-29').valueOf(),
  fulfillment_id: "B",
  type: FULFILLMENT_TYPES.TRANSFER,
  status: FULFILLMENT_STATUSES.SHIPPED,
  origin: SYDNEY_OUTLET,
  destination: NEW_YORK_OUTLET,
  order_items: [
    { ...SWEATER_M, quantity: 50 },
    { ...SWEATER_S, quantity: 20 },
  ],
  read: false,
}

export const SPECIAL_ORDER_SWEATER = {
  id: 4,
  order_id: "BC876877777",
  created: new Date('2023-12-28').valueOf(),
  fulfillment_id: "A",
  type: FULFILLMENT_TYPES.SPECIAL_ORDER,
  status: FULFILLMENT_STATUSES.REQUESTED,
  destination: SYDNEY_OUTLET,
  order_items: [
    { ...SWEATER_M, quantity: 50 },
    { ...SWEATER_S, quantity: 20 },
  ],
  read: false,
}

export const HOLD_SWEATER = {
  id: 5,
  order_id: "BCsfsfsfgsf876877777",
  created: new Date('2023-12-27').valueOf(),
  fulfillment_id: "A",
  type: FULFILLMENT_TYPES.HOLD,
  status: FULFILLMENT_STATUSES.REQUESTED,
  destination: SYDNEY_OUTLET,
  order_items: [
    { ...SWEATER_M, quantity: 50 },
    { ...SWEATER_S, quantity: 20 },
  ],
  read: false,
}

export const CLICK_AND_COLLECT_SWEATER = {
  id: 6,
  order_id: "YeTAn0tH3r0rd3r",
  created: new Date('2023-12-27').valueOf(),
  fulfillment_id: "A",
  type: FULFILLMENT_TYPES.CLICK_AND_COLLECT,
  status: FULFILLMENT_STATUSES.REQUESTED,
  destination: SYDNEY_OUTLET,
  order_items: [
    { ...SWEATER_M, quantity: 20 }
  ],
  read: false,
}

export default [
  TAKE_AWAY_LARGE_CERAMIC_VASE,
  TAKE_AWAY_LARGE_CERAMIC_VASE2,
  TAKE_AWAY_LARGE_CERAMIC_VASE3,
  HOLD_SWEATER,
  TRANSFER_SWEATER_M_SYD_TO_MEL,
  TRANSFER_SWEATERS_SYD_TO_NY,
  SPECIAL_ORDER_SWEATER,
  CLICK_AND_COLLECT_SWEATER,
]
