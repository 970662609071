import { SET_LIQUID_SCHEMA } from 'components/liquid/constants'

export default function (state = true, action) {
  switch (action.type) {
    case SET_LIQUID_SCHEMA:
      const schemas = { ...state, [action.object]: action.schema }
      window.localStorage.setItem('schemas', JSON.stringify(schemas))
      return schemas
    default:
      return state
  }
}
