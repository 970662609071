export default function getPaymentDrops(liquidSchemas, getOrder, payments) {
  if (payments === undefined) return []

  return payments.map((payment) => {
    return getPaymentDrop(getOrder, payment)
  })
}

function getPaymentDrop(getOrder, payment) {
  return { 
    __data: {},
    account_credit_path: "",
    amount: payment.amount,
    currency_code: "",
    description: payment.details,
    id: payment.id,
    instructions_content: "",
    invoice_reference: "",
    invoice_url: "",
    order: getOrder,
    paid_at: new Date(payment.paid_at),
    points_amount: 0,
    status: payment.status,
    transaction_number: "",
    type: payment.type, // ADDITIONAL FIELD
  }
}