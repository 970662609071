import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClearSyncInProgress from 'components/online/actions/ClearSyncInProgress'
import SetLiquidSchema from 'components/liquid/actions/SetLiquidSchema'
import SetStore from 'components/stores/actions/SetStore'
import SetStores from 'components/stores/actions/SetStores'
import SetSyncInProgress from 'components/online/actions/SetSyncInProgress'
import SetTaxes from 'components/taxes/actions/SetTaxes'
import SetUsers from 'components/users/actions/SetUsers'
import ForceSync from 'components/system/actions/ForceSync'

import { fetchStores } from 'data/api/fetchStores'
import { fetchTaxes } from 'data/api/fetchTaxes'
import { fetchUsers } from 'data/api/fetchUsers'

import useSyncOutlets from 'hooks/useSyncOutlets'
import useSyncProducts from 'hooks/useSyncProducts'
import useSyncPrintableTemplates from 'hooks/useSyncPrintableTemplates'

const useMasterSync = () => {
  const dispatch = useDispatch()
  const forceSync = useSelector(state => state.forceSync)
  const currentOutlet = useSelector((state) => state.outlet)

  const {
    fetchAndSync: fetchProducts
  } = useSyncProducts({ executeOnLoad: false })
  const {
    syncOutlets: fetchOutlets,
    triggerPolling: pollOutlets
  } = useSyncOutlets({ executeOnLoad: false })
  const {
    fetchPrintableTemplates
  } = useSyncPrintableTemplates({ executeOnLoad: false })

  // We trigger outlets polling after currentOutlet gets set 
  useEffect(() => {
    if (Object.keys(currentOutlet).length > 0) {
      pollOutlets()
    }
  }, [currentOutlet])

  useEffect(() => {
    if (forceSync === true) {
      triggerForceSync()
      dispatch(ForceSync(false))
    }
  }, [forceSync])

  function triggerForceSync() {
    dispatch(ClearSyncInProgress())
    const resources = ['outlets', 'products', 'stores', 'taxes', 'users', 'printable_labels']
    resources.forEach((resource) => {
      dispatch(SetSyncInProgress(resource, true))
    })
    // Fulfillments?
    // Orders?
    fetchOutlets(() => {
      dispatch(SetSyncInProgress('outlets', false))
    })
    fetchProducts(1, () => {
      dispatch(SetSyncInProgress('products', false))
    })
    // Shifts?
    fetchStores((stores, liquid_fields) => {
      dispatch(SetStores(stores))
      dispatch(SetLiquidSchema('store', liquid_fields))
      const store = stores.find((store) => store.current)
      if (store) dispatch(SetStore(store))
      dispatch(SetSyncInProgress('stores', false))
    })
    //TODO: suppliers?
    fetchTaxes((taxes) => {
      dispatch(SetTaxes(taxes))
      dispatch(SetSyncInProgress('taxes', false))
    })
    fetchUsers((users) => {
      dispatch(SetUsers(users))
      dispatch(SetSyncInProgress('users', false))
    })
    fetchPrintableTemplates(() => {
      dispatch(SetSyncInProgress('printable_labels', false))
    })
  }

  return { forceSync }
}

export default useMasterSync