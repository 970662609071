export default function getMaximumRefundAmount(amount, maximum, isRefund) {
  if (amount === null || amount === undefined || amount === '') {
    return null
  }

  if (!isRefund || maximum == null || maximum === undefined) {
    return parseFloat(amount)
  }

  return Math.min(parseFloat(amount), parseFloat(maximum))
}