import React from 'react'

import FulfillmentLineItem from 'components/fulfillments/FulfillmentLineItem'

const FulfillmentsList = ({
  fulfillments,
  onSetFulfillment,
}) => {
  return (
    <div data-testid="fulfillments-list" className="flex col no-shrink z-0 overflow-y-scroll ps-3 pb-3">
      <div className="rounded-2 theme-neutral-0">
        <div className="gap-2 p-3">
          {fulfillments.map((ffment) => (
              <FulfillmentLineItem
                key={ffment.id}
                fulfillment={ffment}
                onSetFulfillment={() => onSetFulfillment(ffment)}
              />
          ))}
        </div>
      </div>
    </div>
  )
}

export default FulfillmentsList