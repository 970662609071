import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'

import { SLIDE_UP } from 'constants/motion'
import {
  BRAND,
  COLUMN,
  DASHBOARD,
  NEUTRAL,
  NOTIFY,
  CHEVRON,
  NEXT,
  MEDIUM,
} from 'components/constants'
import { PRINTER_TYPES, PRODUCT_LABEL_CONTEXT, PACKING_SLIP_CONTEXT } from 'constants/settings'

import useHandlePrinting from 'hooks/useHandlePrinting'

import Banner from 'components/shared/Banner'
import ButtonMenu from 'components/shared/ButtonMenu'
import Button from 'components/shared/Button'
import SettingLineItem from 'components/settings/SettingLineItem'
import View from 'components/shared/View'
import Main from 'components/shared/Main'
import Icon from 'components/shared/Icon'
import PrinterDetails from 'components/settings/PrinterDetails'
import TemplateDetails from 'components/settings/TemplateDetails'
import EftPosDetails from 'components/settings/EftPosDetails'
import Overlay from 'components/shared/Overlay'
import Loader from 'components/shared/Loader'

import UpdateContext from 'components/settings/actions/UpdateContext'
import UpdatePrinter from 'components/settings/actions/UpdatePrinter'
import UpdateDrawer from 'components/settings/actions/UpdateDrawer'
import ConnectDrawerToPrinter from 'components/settings/actions/ConnectDrawerToPrinter'
import UpdateTyro from './actions/UpdateTyro'
import SetViewId from 'components/navigation/actions/SetViewId'

const Settings = ({ previousViewId }) => {
  const dispatch = useDispatch()
  const tyroSettings = useSelector((state) => state.settings.tyro)
  const viewId = useSelector((state) => state.viewId)

  const [animation, _setAnimation] = useState(NEXT)
  const [showPrinterDetails, setShowPrinterDetails] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [showTemplateDetails, setShowTemplateDetails] = useState(null)
  const [showEftDetails, setShowEftDetails] = useState(null)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
  let loaderTimer = null

  const {
    deviceFailed,
    deviceError,
    drawer,
    printers,
    printingContexts,
    printingTemplates,
    deviceFingerPrint,
    requestDevices,
    interpolateDeviceLabel
  } = useHandlePrinting()

  useEffect(() => {
    return () => clearTimeout(loaderTimer)
  }, [])

  function setPrinter(deviceDesignation, device) {
    const printer = { ...deviceDesignation, device: deviceFingerPrint(device) }
    dispatch(UpdatePrinter(printer))
    setSelectedObject(printer)
  }

  function printerOnClick(deviceDesignation) {
    requestDevices((device) => {
      setShowLoadingOverlay(true)
      loaderTimer = setTimeout(() => {
        setPrinter(deviceDesignation, device)
        setShowLoadingOverlay(false)
      }, 2000)
    })
  }

  function disconnectPrinter(printer) {
    setShowLoadingOverlay(true)
    loaderTimer = setTimeout(() => {
      let printerValues = { ...printer, device: null }
      if (printer.key === PRINTER_TYPES.RECEIPT) {
        printerValues = { ...printerValues, drawerPresent: false }
      }
      dispatch(UpdatePrinter(printerValues))
      setSelectedObject(printerValues)
      resetDrawerSettings()
      setShowLoadingOverlay(false)
    }, 2000)
  }

  function resetDrawerSettings() {
    const rules = drawer.openDrawerRules.map((rule) => { return { ...rule, openDrawer: false } })
    dispatch(UpdateDrawer({ ...drawer, openDrawerRules: rules }))
  }

  let component = null

  if (showPrinterDetails) {
    component =
      <PrinterDetails
        printer={selectedObject}
        drawer={drawer}
        onBack={() => {
          setShowPrinterDetails(false)
          setSelectedObject(null)
        }}
        onDrawerUpdate={(drawerPresent) => {
          const updatedPrinter = { ...selectedObject, drawerPresent }
          if (!drawerPresent) resetDrawerSettings()
          dispatch(ConnectDrawerToPrinter(updatedPrinter))
          setSelectedObject(updatedPrinter)
        }}
        onConnect={(printer) => {
          printerOnClick(printer)
        }}
        onDisconnect={(printer) => disconnectPrinter(printer)}
        onDrawerOptionClick={(rule) => {
          const rules = [...drawer.openDrawerRules]
          const matchedRuleIndex = rules.findIndex((_rule) => _rule.paymentMethod === rule.paymentMethod)
          rules[matchedRuleIndex] = { ...rules[matchedRuleIndex], openDrawer: !rule.openDrawer }
          dispatch(UpdateDrawer({ ...drawer, openDrawerRules: rules }))
        }}
        interpolateDeviceLabel={interpolateDeviceLabel}
      />
  } else if (showTemplateDetails) {
    component =
      <TemplateDetails
        templates={printingTemplates}
        templateContext={selectedObject}
        onTemplateSelect={(template) => {
          const updatedContext = {
            ...selectedObject,
            templateId: template.id,
            template: template.label_template,
            templateName: template.name,
          }
          dispatch(UpdateContext(updatedContext))
          setSelectedObject(updatedContext)
        }}
        onBack={() => {
          setShowTemplateDetails(false)
          setSelectedObject(null)
        }}
      />
  } else if (showEftDetails) {
    component =
      <EftPosDetails
        onConnect={() => {
          const iframe = document.getElementById("iframe");
          iframe.src = `${window.tyroBaseUrl}/configuration.html`;
          iframe.style.display = "block";
        }}
        printTyroReceipt={tyroSettings.printTyroReceipt}
        alwaysPrintMerchantReceipt={tyroSettings.alwaysPrintMerchantReceipt}
        onSetPrintTyroReceipt={(enabled) => {
          dispatch(UpdateTyro(enabled, tyroSettings.alwaysPrintMerchantReceipt))
        }}
        onSetAlwaysPrintMerchantReceipt={(enabled) => {
          dispatch(UpdateTyro(tyroSettings.printTyroReceipt, enabled))
        }}
        onBack={() => {
          setShowEftDetails(false)
        }}
      />
  } else {
    component =
      <View theme={NEUTRAL} shade={1} animation={animation}>
        <ButtonMenu
          shade={1}
          theme={NEUTRAL}
          className='gap-1px p-4 justify-space-between align-items-center'
        >
          <Button
            shade={1}
            rounded={4}
            size={MEDIUM}
            theme={BRAND}
            onClick={() => {
              if (previousViewId === undefined || previousViewId === viewId) {
                dispatch(SetViewId(DASHBOARD))
              } else {
                dispatch(SetViewId(previousViewId))
              }
            }}
          >
            <>Back</>
          </Button>
          <span className='grow font-weight-3 font-size-5 text-center'>
            Settings
          </span>
          <span style={{ width: 100 }}></span>
        </ButtonMenu>
        <Main className="ps-6" scroll={true}>
          <section id="printers" className='p-6'>
            <div className="font-size-2 mb-2">
              Printers
            </div>
            {printers.map((printer, index) => {
              return (
                <SettingLineItem
                  key={printer.key}
                  first={index === 0}
                  last={index === printers.length - 1}
                  padding={4}
                  className="flex font-weight-2 border-ends justify-space-between items-center align-items-center"
                  onClick={() => {
                    setShowPrinterDetails(true)
                    setSelectedObject(printer)
                  }}
                >
                  <div>{printer.label}</div>
                  <div className="flex gap-2 items-center align-items-center">
                    <span className="color-shade-3">{printer.device === null ? 'Device not connected' : interpolateDeviceLabel(JSON.parse(printer.device))}</span>
                    <Icon className='opacity-06 color-shade-3' type={CHEVRON} size={14} rotate={270} />
                  </div>
                </SettingLineItem>
              )
            })}
          </section>
          <section id="templates" className='pl-6 pr-6'>
            <div className="font-size-2 mb-2">
              Templates
            </div>

            {printingContexts.map((context) => {
              // For now we are hiding these two until such time we need them
              if (context.key === PRODUCT_LABEL_CONTEXT || context.key === PACKING_SLIP_CONTEXT) return null
              return (
                <SettingLineItem
                  key={context.key}
                  first={true}
                  last={true}
                  padding={4}
                  className="flex border-ends font-weight-2 justify-space-between items-center align-items-center"
                  onClick={() => {
                    setShowTemplateDetails(true)
                    setSelectedObject(context)
                  }}
                >
                  <div>{context.label}</div>
                  <div className="flex gap-2 items-center align-items-center">
                    <span className="color-shade-3">{context.templateName}</span>
                    <Icon className='opacity-06 color-shade-3' type={CHEVRON} size={14} rotate={270} />
                  </div>
                </SettingLineItem>
              )
            })}
          </section>
          {window.tyroClient &&
            <section id="eftpos" className='p-6'>
              <div className="font-size-2 mb-2">
                EFTPOS
              </div>
              <SettingLineItem
                first={true}
                last={true}
                padding={4}
                className="flex border-ends font-weight-2 justify-space-between items-center align-items-center"
                onClick={() => setShowEftDetails(true)}
              >
                <span>Tyro</span>
                <Icon className='opacity-06 color-shade-3' type={CHEVRON} size={14} rotate={270} />
              </SettingLineItem>
            </section>
          }
        </Main>
        {
          deviceFailed === true && (
            <Banner
              direction={COLUMN}
              animation={SLIDE_UP}
              theme={NOTIFY}
              shade={3}
              message={deviceError}
            />
          )
        }
      </View >
  }

  return (
    <div className="expand">
      {component}
      {showLoadingOverlay && (
        <AnimatePresence>
          <Overlay
            zIndex={3}
            onClose={() => {
              setShowLoadingOverlay(false)
            }}
          >
            <Loader background="bg-opacity-4" />
          </Overlay>
        </AnimatePresence>
      )}
    </div>
  )
}

export default Settings