import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LARGE, HUGE } from 'components/constants'
import { BRAND, COLUMN } from 'components/constants'
import { SLIDE_UP } from 'components/constants'
import { BANK, DOLLAR, NUMBER } from 'components/constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const BankTransferForm = ({
  animation,
  drawerError = null,
  center = true,
  className,
  onSubmit,
  theme = BRAND,
  syncingOrder = false,
  owed,
}) => {
  const [amount, setAmount] = useState(null)
  const [refNum, setRefNum] = useState(null)
  const [refError, setRefError] = useState(false)

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('reference').focus()
  }, [])

  function submit(amountSubmitted) {
    if (!refNum || refNum === "") {
      setRefNum(null)
      setRefError(true)
      document.getElementById('reference').focus()
      return
    }
    onSubmit({
      amountTendered: amountSubmitted || owed,
      paymentDetails: refNum,
      paymentType: BANK,
    })
  }

  return (
    <Form
      gridTemplateRows='1fr auto'
      animation={animation}
      className={classNames({'expand': true, [className]: className})}
      onSubmit={() => submit()}
    >
      <Container
        center={center}
        maxWidth={800}
        padding={5}
        gap={3}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon
            type={BANK}
            size={LARGE}
            theme={theme}
            shade={3}
          />
          Bank transfer
        </span>
        <Heading>
          Amount received
        </Heading>
        <div className='flex gap-2'>
          <span>
            <Field
              valid={!refError}
              width={340}
              id='reference'
              border={true}
              name='reference'
              required={true}
              label='Reference number'
              errorMsg={"Reference number is required"}
              onChange={(value) => { setRefNum(value); setRefError(false) }}
            />
          </span>
          <span>
            <Field
              valid={true}
              name='amount'
              type='number'
              step={.01}
              inputMode='decimal'
              pattern='[0-9]*'
              border={true}
              label="Custom Amount"
              width={300}
              icon={DOLLAR}
              onChange={value => setAmount(parseFloat(value))}
            />
          </span>
        </div>
      </Container>
      {drawerError}
      <ButtonMenu animation={SLIDE_UP} direction={COLUMN}>
        <Button
          disabled={syncingOrder}
          onClick={() => submit(amount)}
          theme={theme}
          shade={5}
          size={HUGE}>
          Accept bank transfer&nbsp;
          {getFormattedPrice(amount || owed)}
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default BankTransferForm
