import React from 'react'
import classNames from 'classnames'

import getTruncatedText from 'components/helpers/getTruncatedText'

const AppVersion = ({ className }) => {
  return (
    <div
      className={classNames({
        "font-size-1 color-shade-4 flex gap-2": true,
        [className]: className
      })}>
      <span>{window.appVersion}</span>
      <span>{getTruncatedText(window.appVersionCommit, 7, false)}</span>
    </div>
  )
}

export default AppVersion