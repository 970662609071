export default function getTruncatedText(text, num, withEllipsis = true) {
  const charLimit = num || 30

  if (!text) return
  if (text.length > charLimit) {
    if (withEllipsis) {
      return text.substring(0, Math.min(charLimit - 3, 0)) + '...'
    } else {
      return text.substring(0, charLimit)
    }
  } else return text
}
