import { SET_ONLINE } from 'components/online/constants'

export default function (state = true, action) {
  switch (action.type) {
    case SET_ONLINE:
      return action.online
    default:
      return state
  }
}
