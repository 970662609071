import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { startCase } from 'lodash'

import { BRAND, COLUMN, NEUTRAL, NEXT, MEDIUM, CHECK_MARK, NOTIFY, SLIDE_UP } from 'components/constants'

import Article from 'components/shared/Article'
import Banner from 'components/shared/Banner'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import SettingLineItem from 'components/settings/SettingLineItem'
import Icon from 'components/shared/Icon'

const TemplateDetails = ({
  templates = [],
  templateContext,
  animation = NEXT,
  className,
  theme = BRAND,
  shadow = 0,
  rounded = 0,
  width,
  height,
  onBack,
  onTemplateSelect,
}) => {
  const [hasError, setHasError] = useState(false)

  const filteredTemplates = templates.filter((template) => template.printer_type === templateContext.printerType)

  useEffect(() => {
    if (templateContext.templateId !== null && templateContext.template === null) setHasError(true)
  }, [])

  function templateInvalid(template) {
    return template.label_template === null || template.printer_type === null || template.type === null
  }

  return (
    <Article
      gridTemplateRows='auto auto 1fr auto'
      className={classNames({
        'expand': true,
        [className]: className,
      })}
      shade={1}
      theme={NEUTRAL}
      rounded={rounded}
      shadow={shadow}
      maxWidth={width}
      maxHeight={height}
      animation={animation}
    >
      <ButtonMenu
        shade={1}
        theme={NEUTRAL}
        className='gap-1px p-4 mb-6 justify-space-between align-items-center'
      >
        <Button
          shade={1}
          rounded={4}
          size={MEDIUM}
          theme={theme}
          onClick={onBack}
        >
          <>Back</>
        </Button>
        <span className='grow font-weight-3 font-size-5 text-center'>
          {templateContext.label}
        </span>
        <span style={{ width: 100 }}></span>
      </ButtonMenu>
      <div className="ps-8" style={{ maxHeight: 500, overflow: 'auto' }}>
        <section className="mb-2 flex col">
          {filteredTemplates.map((template, index) => {
            return (
              <SettingLineItem
                key={template.id}
                first={index === 0}
                last={index === templates.length - 1}
                padding={4}
                className="flex color-shade-5 border-ends font-weight-2 justify-space-between items-center align-items-center"
                onClick={() => {
                  setHasError(templateInvalid(template))
                  onTemplateSelect(template)
                }}
              >
                <div>{startCase(template.name)}</div>
                {templateContext.templateId === template.id && (
                  <span className="theme-brand color-brand-5">
                    <Icon type={CHECK_MARK} fillcolor="green" />
                  </span>
                )}
              </SettingLineItem>
            )
          })}
        </section>
        {hasError && (
          <div className="ps-4 color-notify-4 font-size-2">Must have a template, type and printer type set.</div>
        )}
      </div>
      {hasError && (
        <div className="absolute" style={{ bottom: 0, left: 0, right: 0 }} >
          <Banner
            direction={COLUMN}
            animation={SLIDE_UP}
            theme={NOTIFY}
            shade={3}
            message="This template is invalid. Please check your data."
          />
        </div>
      )}
    </Article>
  )
}

export default TemplateDetails