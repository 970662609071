import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { SMALL } from 'constants/size'
import { BRAND } from 'constants/theme'

import ButtonMenu from 'components/shared/ButtonMenu'
import OnlineStatus from 'components/shared/OnlineStatus'
import UserCard from 'components/users/shared/UserCard'
import StoreCard from 'components/stores/shared/StoreCard'

import LockButton from './LockButton'
import SubtleButton from './SubtleButton'
import SidebarInitButton from './SidebarInitButton'

import Lock from 'components/navigation/actions/Lock'
import ShowSidebar from 'components/navigation/actions/ShowSidebar'
import ForceSync from 'components/system/actions/ForceSync'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Topbar = ({
  className,
  shade = 0,
  theme = BRAND,
  transitional = false,
  onBack,
  onNext,
}) => {
  const dispatch = useDispatch()
  const online = useSelector((state) => state.online)
  const syncInProgress = useSelector((state) => state.syncInProgress)
  const syncing = Object.values(syncInProgress).some((v) => v)
  const store = useSelector((state) => state.store)
  const user = useSelector((state) => state.user)
  const currentOutlet = useSelector((state) => state.outlet)

  function lock() {
    dispatch(Lock())
  }

  function triggerForceSync() {
    if (syncing) return
    dispatch(ForceSync(true))
  }

  return (
    <header id='topbar' className={getStyleClasses()}>
      <ButtonMenu className='grow align-items-center gap-3'>
        {transitional === false && (
          <SidebarInitButton onClick={() => dispatch(ShowSidebar())} />
        )}
        {onBack && transitional && (
          <SubtleButton onClick={() => onBack()}>Back</SubtleButton>
        )}
        <StoreCard
          size={SMALL}
          store={store}
          outlet={currentOutlet}
        />
      </ButtonMenu>
      <ButtonMenu className='justify-end align-items-center'>
        <OnlineStatus online={online} syncing={syncing} onClick={triggerForceSync} />
        {Object.keys(user).length > 0 && (
          <UserCard id='top-bar-user' user={user} />
        )}
        {transitional === false && <LockButton onLock={lock}/>}
        {onNext && transitional && (
          <SubtleButton onClick={() => onNext()}>Next</SubtleButton>
        )}
      </ButtonMenu>
    </header>
  )

  function getStyleClasses() {
    return classNames({
      'topbar-height expand-x ps-2 z-2 flex row align-items-center justify-space-between relative no-shrink': true,
      [getThemeStyleClass(theme, shade)]: theme,
      [className]: className,
    })
  }
}

export default Topbar
