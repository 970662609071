import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const KEYS_TO_MONITOR = ['user', 'locked', 'viewId', 'forceSync', 'outlets', 'outlet']

// This code listens for changes in local storage and updates
// relevant state variables accordingly.
const useMonitorLocaleStorage = () => {
  // Get values from redux since it's almost impossible
  // for a normal user to manipulate redux....
  const viewId = useSelector((state) => state.viewId)
  const locked = useSelector((state) => state.locked)
  const forceSync = useSelector((state) => state.forceSync)
  const outlets = useSelector((state) => state.outlets)
  const outlet = useSelector((state) => state.outlet)
  const user = useSelector((state) => state.user)

  // Used refs here so that we can still get
  // the latest state values inside the listener.
  const viewIdRef = useRef(viewId)
  const lockedRef = useRef(locked)
  const forceSyncRef = useRef(forceSync)
  const outletsRef = useRef(outlets)
  const outletRef = useRef(outlet)
  const userRef = useRef(user)

  const refs = {
    'viewId': viewIdRef,
    'locked': lockedRef,
    'forceSync': forceSyncRef,
    'outlets': outletsRef,
    'outlet': outletRef,
    'user': userRef,
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    viewIdRef.current = viewId
    lockedRef.current = locked
    forceSyncRef.current = forceSync
    outletsRef.current = outlets
    outletRef.current = outlet
    userRef.current = user
  }, [user, viewId, locked, forceSync, outlets, outlet])

  // Even if JS was disabled and locale storage was manipulated
  // we can still put back the source values from redux to locale storage
  // by catching before unload event
  function handleBeforeUnload(_event) {
    // It can be disabled by doing an early return here

    const user = window.localStorage.getItem('user')
    const register = window.localStorage.getItem('register')
    const apiToken = window.localStorage.getItem('apiToken')

    if (apiToken === null || user === null || register === null) return

    Object.keys(refs).forEach((key) => {
      window.localStorage.setItem(key, JSON.stringify(refs[key].current))
    })
  }

  function handleStorageChange(event) {
    // It can be disabled by doing an early return here
    if (!KEYS_TO_MONITOR.includes(event.key)) return

    const ref = refs[event.key]
    if (ref && event) {
      window.localStorage.setItem(event.key, JSON.stringify(ref.current))
    }
  }
}

export default useMonitorLocaleStorage