import { DEV_MODE, SHOW_DEV_LOGS } from 'components/constants'

export function log(message, theme = null) {
  if (DEV_MODE && SHOW_DEV_LOGS) {
    console.log('%c' + message, getThemeProps(theme))
  }
}

export function dir(message) {
  if (DEV_MODE && SHOW_DEV_LOGS) console.dir(message)
}

function getThemeProps(theme = null) {
  switch (theme) {
    default:
      return 'background: hsl(215, 100%, 80%); color: hsl(215, 100%, 20%);' + getDefaultProps()
  }
}

function getDefaultProps() {
  return 'border-radius: 5px; padding: 2px 4px; font-weight: 600; font-size: 14px;'
}
