import React, { useState, createContext } from 'react'

export const FulFillmentsContext = createContext();

const FulfillmentsProvider = ({ children }) => {
  const [typeFilters, setTypeFilters] = useState([])
  const [statusFilters, setStatusFilters] = useState([])
  const [keywordFilter, setKeywordFilter] = useState('')

  function updateTypeFilters(typeFilter) {
    if (typeFilters.includes(typeFilter)) {
      setTypeFilters(typeFilters.filter((_typeFilter) => _typeFilter !== typeFilter))
    } else {
      setTypeFilters([...typeFilters, typeFilter])
    }
  }

  function updateStatusFilters(statusFilter) {
    if (statusFilters.includes(statusFilter)) {
      setStatusFilters(statusFilters.filter((_statusFilter) => _statusFilter !== statusFilter))
    } else {
      setStatusFilters([...statusFilters, statusFilter])
    }
  }

  function clearFilters() {
    setTypeFilters([])
    setStatusFilters([])
    setKeywordFilter('')
  }

  return (
    <FulFillmentsContext.Provider value={{
      typeFilters,
      statusFilters,
      keywordFilter,
      setStatusFilters,
      updateStatusFilters,
      updateTypeFilters,
      clearFilters,
      setKeywordFilter
    }}>
      {children}
    </FulFillmentsContext.Provider>
  )
}

export default FulfillmentsProvider