import React from 'react'

import { BRAND, NEUTRAL } from 'components/constants'
import { MEDIUM, HUGE } from 'components/constants'
import { SCALE_IN } from 'components/constants'

import AutocompleteSearch from 'components/shared/AutocompleteSearch'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Fieldset from 'components/shared/Fieldset'
import Form from 'components/shared/Form'
import Loader from 'components/shared/Loader'

import getCustomerFullName from 'components/customers/helpers/getCustomerFullName'

const OrderSearchForm = ({
  customers = [],
  animation = SCALE_IN,
  height,
  loading = false,
  rounded = 0,
  shadow = 0,
  stores = [],
  theme = NEUTRAL,
  shade = 0,
  onSearch,
  onBack,
  storeKeyword,
  customerKeyword,
  filters = {},
  fetchingCustomer = false,
  showDateRange = false,
  showNoResultsButton = false,
  onSetShowDateRange,
  onUpdateStoreKeyword,
  onUpdateCustomerKeyword,
  onSetFilters,
  width,
}) => {
  const date = new Date()
  const dateFrom = new Date(date.setDate(date.getDate() - 1))

  function withFilters() {
    return filters.startDate !== null ||
      filters.endDate !== null ||
      filters.customerId.length > 0 ||
      filters.storeId.length > 0
  }

  function searchButtonDisabled() {
    if (fetchingCustomer ||
      Object.keys(filters).length === 0 ||
      !withFilters() ||
      (showDateRange && (
        filters.startDate === null ||
        filters.startDate.length === 0 ||
        filters.endDate === null ||
        filters.endDate.length === 0
      ))
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Form
      id='order-search'
      className='expand rounded-3 z-3 overflow-auto'
      gridTemplateRows='auto 1fr auto'
      shade={shade}
      shadow={shadow}
      rounded={rounded}
      theme={theme}
      maxHeight={height}
      maxWidth={width}
      animation={animation}
      onSubmit={onSearch}
    >
      <ButtonMenu>
        <Button
          shade={0}
          rounded={4}
          size={MEDIUM}
          theme={BRAND}
          onClick={() => onBack()}
        >
          Back
        </Button>
      </ButtonMenu>
      <Container gap={3}>
        {loading && <Loader background='bg-shade-0' />}
        <Fieldset className='align-items-end wrap'>
          <Field
            type='date'
            id='date_from'
            label={showDateRange ? 'Date from' : 'Order date'}
            width={235}
            shadow={2}
            size={MEDIUM}
            max={filters.endDate}
            defaultValue={filters.startDate || dateFrom.toLocaleDateString()}
            onChange={(startDate) => onSetFilters({ ...filters, startDate })}
          />
          {showDateRange && (
            <Field
              type='date'
              label='Date to'
              id='date_to'
              shadow={2}
              width={235}
              size={MEDIUM}
              defaultValue={filters.endDate}
              min={filters.startDate}
              onChange={(endDate) => onSetFilters({ ...filters, endDate })}
            />
          )}
          <ButtonMenu className='mt-2'>
            {showDateRange === false && (
              <Button
                className='flex gap-2'
                shadow={3}
                shade={3}
                rounded={3}
                theme={BRAND}
                animation={SCALE_IN}
                onClick={onSetShowDateRange}
              >
                Date range
              </Button>
            )}
            {showDateRange && (
              <Button
                className='flex gap-2'
                shadow={3}
                shade={3}
                rounded={3}
                theme={BRAND}
                animation={SCALE_IN}
                onClick={() => {
                  onSetFilters({ ...filters, endDate: null })
                  onSetShowDateRange()
                }}
              >
                Specific date
              </Button>
            )}
          </ButtonMenu>
        </Fieldset>
        <AutocompleteSearch
          defaultValue={customerKeyword}
          defaultValueId={filters.customerId}
          placeholder='Find customer by name, phone or email'
          id='customer_search'
          results={getResultsFromCustomers(customers)}
          onChange={(value) => {
            if (value?.length === 0) {
              onSetFilters({ ...filters, customerId: '' })
            }
            onUpdateCustomerKeyword(value)
          }}
          onClick={(customerId) => onSetFilters({ ...filters, customerId })}
        />
        {stores.length > 0 && (
          <AutocompleteSearch
            defaultValue={storeKeyword}
            defaultValueId={filters.storeId}
            placeholder='Filter stores'
            id='store_search'
            results={storeKeyword.length > 0 ? getResultsFromStores(stores) : []}
            onChange={(value) => {
              if (value?.length === 0) {
                onSetFilters({ ...filters, storeId: '' })
              }
              onUpdateStoreKeyword(value)
            }}
            onClick={(storeId) => {
              onSetFilters({ ...filters, storeId })
            }}
          />
        )}
      </Container>
      {!showNoResultsButton && (
        <Button
          type='submit'
          rounded={0}
          shade={5}
          delay={0.2}
          size={HUGE}
          theme={BRAND}
          disabled={searchButtonDisabled()}
        >
          Search order records
        </Button>
      )}
    </Form>
  )

  function getResultsFromStores(stores = []) {
    let filteredStores = []

    if (storeKeyword.length === 0) {
      filteredStores = stores
    } else {
      const pattern = new RegExp(storeKeyword, 'i')
      filteredStores = stores.filter(store => {
        return pattern.test(store.name)
      })
    }

    return filteredStores.map(store => {
      return { id: store.id, headline: store.name }
    })
  }

  function getResultsFromCustomers(customers) {
    return customers.map(customer => {
      return {
        id: customer.id,
        headline: getCustomerFullName(customer),
        subheadline: customer.phone || customer.email,
      }
    })
  }
}

export default OrderSearchForm
