import { USE_DUMMY_DATA } from 'constants/settings'
import FULFILLMENTS from 'data/dummy/fulfillments'

import authenticatedFetch from './authenticatedFetch'

export function fetchFulfillments(
  onSuccess,
  onError = () => alert('Error fetching fulfillments'),
) {
  if (USE_DUMMY_DATA) return onSuccess(FULFILLMENTS)

  if (navigator.onLine) {
    authenticatedFetch('fulfillments',
      { method: 'GET' },
      // Success
      ({ fulfillments }) => onSuccess(fulfillments),
      // Error
      () => onError()
    )
  }
}