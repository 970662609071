import { FORCE_SYNC } from 'components/system/constants'

export default function (state = false, action) {
  switch (action.type) {
    case FORCE_SYNC:
      window.localStorage.setItem('forceSync', action.value)
      return action.value
    default:
      return state
  }
}
