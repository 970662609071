import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { log } from 'dev/log'
import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchFulfillments } from 'data/api/fetchFulfillments'
import { syncFulfillment } from 'data/api/syncFulfillment'

import SetOnline from 'components/online/actions/SetOnline'
import getLocalStorage from 'data/helpers/getLocalStorage'
import getSyncTimeout from 'data/helpers/getSyncTimeout'
import setLocalStorage from 'data/helpers/setLocalStorage'

const useSyncFulfillments = (poll = false) => {
  const dispatch = useDispatch()
  const syncTimeout = getSyncTimeout()

  const [fulfillments, setFulfillments] = useState(getLocalStorage('fulfillments', []))
  const [fetching, setFetching] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [errorSyncing, setErrorSyncing] = useState(false)
  const [errorFetching, setErrorFetching] = useState(false)
  let syncInterval = null

  // Fetch on load and set polling
  useEffect(() => {
    const onError = () => {
      setFetching(false)
      setErrorFetching(true)
    }

    const handleFetchFulfillments = () => {
      setErrorFetching(false)
      fetchFulfillments(
        (fulfillments) => {
          setFulfillments(fulfillments)
          setFetching(false)
          setErrorFetching(false)
        },
        onError
      )
    }

    const handleSyncFulfillments = () => {
      setFetching(true)
      setErrorFetching(false)
      fetchCacheVersions(
        'fulfillments',
        handleFetchFulfillments,
        (online) => {
          dispatch(SetOnline(online))
          setFetching(false)
        },
        onError,
        onError
      )
    }

    if (navigator.onLine) {
      setFetching(true)
      handleFetchFulfillments()
      if (poll === false) return
      syncInterval = setInterval(handleSyncFulfillments, syncTimeout)
    }

    return () => clearInterval(syncInterval)
  }, [])

  // Update locale storage when fulfillments updates
  useEffect(() => {
    setLocalStorage('fulfillments', fulfillments)
  }, [fulfillments])

  function updateFulfillment(payload, onSuccess, onError) {
    setSyncing(true)
    setErrorSyncing(false)

    const handleSuccess = () => {
      log('Fulfillment synced:')
      if (onSuccess) onSuccess()

      setFulfillments(prevFulfillments => {
        const updatedFulfillments = [...prevFulfillments]
        const index = updatedFulfillments.findIndex((fulfillment) => fulfillment.id === payload.fulfillment.id)

        if (index >= 0) {
          updatedFulfillments[index] = payload.fulfillment;
        } else {
          updatedFulfillments.push(payload.fulfillment);
        }

        return updatedFulfillments;
      })

      setSyncing(false)
    }

    const handleError = (_error) => {
      log('Error syncing fulfillment')
      setSyncing(false)
      setErrorSyncing(true)
      if (onError) onError()
    }

    syncFulfillment(payload, handleSuccess, handleError, () => {
      setSyncing(false)
      setErrorSyncing(true)
    })
  }

  function resetApiStates() {
    setFetching(false)
    setSyncing(false)
    setErrorSyncing(false)
    setErrorFetching(false)
  }

  return {
    fulfillments,
    fetching,
    syncing,
    errorSyncing,
    errorFetching,
    resetApiStates,
    updateFulfillment,
  }
}

export default useSyncFulfillments