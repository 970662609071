import React from 'react'
import classNames from 'classnames'

import { BRAND, SUCCESS } from 'components/constants'
import { FULFILLMENT_STATUSES } from 'components/fulfillments/constants'

import Record from 'components/shared/Record'
import Tag from 'components/shared/Tag'

import getFriendlyName from 'components/fulfillments/helpers/getFriendlyName'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const FulfillmentStatusBlock = ({
  className,
  data,
  orderOwing
}) => {
  return (<div   
    className={classNames({
      'sc-order-summary flex align-items-center gap-6 pe-4 ps-5': true,
      'bg-shade-1 rounded-3': true,
      [className]: className,
    })}>
      <Record label='Status'>
        <Tag
          className='hide-print rounded-3 ps-2'
          theme={data.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP
            || data.status === FULFILLMENT_STATUSES.COMPLETED ? SUCCESS : BRAND
          }
          text={getFriendlyName(data.status)}
        />
      </Record>
      <Record label='Reference'>
        {data.fulfillment_id}
      </Record>
      {(orderOwing > 0) && 
        <Record label='Amount left to pay'>
          {getFormattedPrice(orderOwing)}
        </Record>
      }
  </div>
)}

export default FulfillmentStatusBlock