import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

const RadioInput = ({
  checked = false,
  className,
  children,
  name,
  value,
  onBlur,
}) => {
  const containerRef = useRef({})
  const inputRef = useRef(null)

  useEffect(() => {
    containerRef.current.addEventListener('keyup', handleKeyup)
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('keyup', handleKeyup)
      }
    }
  }, [])

  function handleKeyup(event) {
    switch (event.key) {
      case 'Enter':
        return inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true })
        )
    }
  }

  function cleanId(name, value) {
    return `${name}_${value}`.replace(/[^a-z0-9-_]/ig, '')
  }

  return (
    <div
      ref={containerRef}
      className='relative flex'
      data-id={value}
    >
      <input
        ref={inputRef}
        type='radio'
        className='absolute'
        id={cleanId(name, value)}
        name={name}
        value={value}
        defaultChecked={checked}
        // Prevent synthetic React event from firing
        onChange={(event) => event.preventDefault()}
        onBlur={onBlur}
      />
      <label
        htmlFor={cleanId(name, value)}
        data-option={value}
        className={classNames({
          'expand flex align-items-center cursor-pointer': true,
          [className]: className,
        })}
      >
        {children}
      </label>
    </div>
  )
}

export default RadioInput
