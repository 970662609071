import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import * as bcrypt from 'bcryptjs'

import { USE_DUMMY_DATA } from 'constants/settings'
import { LARGE, XLARGE, BRAND, CHEVRON, NEXT, ENTER, DELETE } from 'components/constants'

import Loader from 'components/shared/Loader'
import Container from 'components/shared/Container'
import Icon from 'components/shared/Icon'
import Topbar from 'components/navigation/Topbar'
import Form from 'components/shared/Form'
import isNumeric from 'components/helpers/isNumeric'
import PasscodeButton from 'components/users/shared/PasscodeButton'

const MAX_LENGTH = 6

const PasscodeForm = ({
  onSuccess,
  onBack,
  user,
  locked = false,
  theme = BRAND,
  shade = 5,
  animation = NEXT,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [inputCode, setInputCode] = useState([])

  const inputCodeRef = useRef()
  
  useEffect(() => {
    inputCodeRef.current = inputCode;
  }, [inputCode])

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (isNumeric(event.key)) { set(parseInt(event.key)) }
      if (event.key === ENTER) validate()
      if (event.code === DELETE) back()
    }, true)
    return () => {
      document.removeEventListener('keydown', {});
    };
  }, [])

  function validate(event) {
    if (USE_DUMMY_DATA) return onSuccess()
    if (event) event.preventDefault()
    setLoading(true)
    const passcode = inputCodeRef.current ?  inputCodeRef.current.join('') : null
    const isValid = bcrypt.compareSync(passcode, user.pin)
    setInputCode([]);
    if (isValid === true) {
      onSuccess()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  function set(value, event = null) {
    setError(false)
    if (event && event.clientX === 0) return
    if ((inputCodeRef.current.length + 1) <= MAX_LENGTH) {
      const currentValue = [...structuredClone(inputCodeRef.current), value]
      setInputCode(currentValue)
    }
  }

  function back() {
    setError(false)
    const currentValue = structuredClone(inputCode);
    setInputCode(currentValue.slice(0,-1))
  }

  return (
    <Form
      className='expand'
      shade={5}
      animation={animation}
      theme={BRAND}
      onSubmit={validate}>
      <Topbar
        className='absolute x-0 top-0'
        transitional={true}
        onBack={onBack}
        locked={locked}
        theme={theme}
        shade={shade}
      />
      <Container center={true}>
        <div className='grid'>
          <strong className={`text-center input-height-3 font-weight-3 font-size-6 flex align-items-center ${error ? 'opacity-07' : 'opacity-0'}`}
                  style={{gridArea: '1/1/2/2', margin: '0 auto'}}>
            Try again
          </strong>
          <div className={`justify-center ${!error ? 'flex' : 'opacity-0'}`} style={{gridArea: '1/1/2/2'}}>
            <input className='input-height-3 color-shade-2 font-size-7 text-center' readOnly value={" \u002A ".repeat(inputCode.length)}/>
          </div>
        </div>
        {isLoading === false && (
          <motion.div
            className='grid align-items-center justify-center gap-2'
            style={{
              gridTemplateRows: 'repeat(4, 100px)',
              gridTemplateColumns: 'repeat(3, 100px)',
            }}
            {...errorAnimation()}>
            {[1,2,3,4,5,6,7,8,9].map((num) => (
              <PasscodeButton
                size={XLARGE}
                key={num}
                onClick={(event) => set(num, event)} >
                {num}
              </PasscodeButton>
            ))}
            <PasscodeButton
              size={LARGE}
              onClick={back}
              theme={theme}
              shade={shade}
              shadow={false}
              type='reset'>
              Del
            </PasscodeButton>
            <PasscodeButton onClick={(event) => set(0,)}>0</PasscodeButton>
            <PasscodeButton
              type='submit'
              shadow={false}
              size={XLARGE}
              theme={theme}
              shade={shade}
              onClick={validate}>
              <Icon type={CHEVRON} rotate={270} />
            </PasscodeButton>
          </motion.div>
        )}
        {isLoading && <Loader />}
      </Container>
    </Form>
  )

  function errorAnimation() {
    if (error) {
      return {
        animate: { x: [0, 40, -40, 40, -40, 0] },
        transition: {
          type: 'spring',
          duration: 0.4,
          bounce: 0.5,
          delay: 0.1,
        },
      }
    } else {
      return null
    }
  }
}

export default PasscodeForm