import React from 'react'

import USERS from 'data/dummy/users'
import STORES from 'data/dummy/stores'
import ORDERS from 'data/dummy/orders'
import PRODUCTS from 'data/dummy/products'

import { LAYBY } from 'components/constants'
import { CASH, CARD, CHEQUE, TRANSFER } from 'components/constants'
import { PAYMENT } from 'components/checkout/constants'

import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'

import AddCustomerFlow from 'components/checkout/shared/AddCustomerFlow'
import OrderActionsModal from 'components/shared/OrderActionsModal'
import Checkout from 'components/checkout'
import CheckoutNavMenu from 'components/checkout/CheckoutNavMenu'
import CheckoutPaymentForm from 'components/checkout/CheckoutPaymentForm'
import CheckoutPaymentMenu from 'components/checkout/CheckoutPaymentMenu'
import CheckoutSummaryBlock from 'components/checkout/CheckoutSummaryBlock'
import CheckoutSummaryModal from 'components/checkout/CheckoutSummaryModal'
import CustomerEmailForm from 'components/checkout/shared/CustomerEmailForm'
import LaybyModal from 'components/checkout/shared/LaybyModal'
import OrderNotesModal from 'components/order/OrderNotesModal'

import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'

export default function CheckoutComponents() {
  return (
    <Container heading='9. Checkout'>
      <Stage
        height={700}
        heading='Checkout Flow'
        subheading='Path: checkout/index.jsx'
      >
        <Checkout
          className='bg-shade-0 shadow-3'
          initialOrder={ORDERS[0]}
        />
      </Stage>
      <Stage height={700}>
        <Checkout
          className='bg-shade-0 shadow-3'
          initialOrder={ORDERS[1]}
        />
      </Stage>
      <Stage
        heading='Nav menu'
        subheading='checkout/CheckoutNavMenu.jsx'
      >
        <CheckoutNavMenu
          className='bg-shade-0 rounded-3 shadow'
          view={PAYMENT}
          user={USERS[0]}
          customer={ORDERS[2].customer}
          onShowOrderActions={() => null}
        />
      </Stage>
      <Stage
        width={500}
        heading='Payment menu'
        subheading='Path: checkout/shared/CheckoutPaymentMenu.jsx'
      >
        <CheckoutPaymentMenu onClick={() => null} />
        <CheckoutPaymentMenu theme={LAYBY} onClick={() => null} />
      </Stage>
      <Stage
        width={400}
        heading='Summary block'
        subheading='checkout/CheckoutSummaryBlock.jsx'
      >
        <CheckoutSummaryBlock
          total={ORDERS[0].total}
          items={ORDERS[0].items}
          owed={getAmountOwed(ORDERS[0])}
          paid={getAmountPaid(ORDERS[0])}
          customer={ORDERS[0].customer}
          store={STORES[0]}
        />
      </Stage>
      <Stage
        heading='Actions modal'
        subheading='Path: checkout/OrderActionsModal.jsx'
      >
        <OrderActionsModal
          customer={ORDERS[0].customer}
          cartItems={ORDERS[0].cartItems}
          order={ORDERS[0]}
          products={PRODUCTS}
        />
      </Stage>
      <Stage
        heading='Layby modal'
        subheading='Path: checkout/shared/LaybyModal.jsx'
      >
        <LaybyModal
          maxWidth={600}
          rounded={4}
          customer={ORDERS[0].customer}
          items={ORDERS[0].cartItems}
          payments={ORDERS[0].payments}

        />
      </Stage>
      <Stage
        heading='Order notes'
        subheading='Path: order/OrderNotesModal.jsx'
      >
        <OrderNotesModal
          maxWidth={600}
          rounded={4}
        />
      </Stage>
      <Stage
        heading='Email receipt'
        subheading='Path: checkout/shared/CustomerEmailForm.jsx'
      >
        <CustomerEmailForm
          maxWidth={600}
          onSubmit={() => null}
          onBack={() => null}
        />
      </Stage>
      <Stage
        heading='Add customer flow'
        subheading='Path: checkout/shared/AddCustomerFlow.jsx'
      >
        <AddCustomerFlow
          className='rounded-4 overflow-hidden'
          showBackButton={true}
          onBack={() => null}
        />
      </Stage>
      <Stage
        heading='Checkout summary modal'
        subheading='Path: checkout/shared/AddCustomerFlow.jsx'
      >
        <CheckoutSummaryModal
          maxWidth={600}
          order={ORDERS[0]}
          products={ORDERS[0].items}
          store={STORES[0]}
        />
      </Stage>
      <Stage
        height={600}
        heading='Cash payment'
        subheading='Path: checkout/CheckoutPaymentForm/CashPaymentForm.jsx'
      >
        <CheckoutPaymentForm
          className='bg-shade-0 shadow-3'
          type={CASH}
          order={ORDERS[0]}
          onSubmit={() => null}
        />
      </Stage>
      <Stage
        height={600}
        heading='Cash payment'
        subheading='Path: checkout/CheckoutPaymentForm/CardPaymentForm.jsx'
      >
        <CheckoutPaymentForm
          className='bg-shade-0 shadow-3'
          type={CARD}
          order={ORDERS[0]}
          onSubmit={() => null}
        />
      </Stage>
      <Stage
        height={600}
        heading='Bank transfer'
        subheading='Path: checkout/CheckoutPaymentForm/BankTransferForm.jsx'
      >
        <CheckoutPaymentForm
          className='bg-shade-0 shadow-3'
          type={TRANSFER}
          order={ORDERS[0]}
          onSubmit={() => null}
        />
      </Stage>
      <Stage
        height={600}
        heading='Cheque'
        subheading='Path: checkout/CheckoutPaymentForm/ChequeForm.jsx'
      >
        <CheckoutPaymentForm
          className='bg-shade-0 shadow-3'
          type={CHEQUE}
          order={ORDERS[0]}
          onSubmit={() => null}
        />
      </Stage>
    </Container>
  )
}
