import { USE_DUMMY_DATA } from 'constants/settings'

import authenticatedFetch from './authenticatedFetch'
import { log } from 'dev/log'

export function syncFulfillment(
  params = {},
  onSuccess,
  onError = () => alert('Error syncing fulfillment'),
  onOffline = () => alert('Oops, looks like you are offline'),
) {
  log('Syncing fulfillment...')

  if (USE_DUMMY_DATA) return onSuccess()

  if (navigator.onLine) {
    authenticatedFetch('fulfillments',
      {
        method: 'POST',
        body: JSON.stringify(params),
      },
      onSuccess,
      onError,
      onOffline
    )
  }

  // Offline
  else onOffline()
}

