import React, { useEffect, useContext, useState } from 'react'
import classNames from 'classnames'

import { TINY, MEDIUM, BRAND, NEUTRAL, CLOSE, COLUMN } from 'components/constants'
import { FULFILLMENT_STATUSES } from 'components/fulfillments/constants'
import { FulFillmentsContext } from 'components/fulfillments/Provider'

import getFriendlyName from './helpers/getFriendlyName'

import Button from 'components/shared/Button' //mebbe remove
import Checkbox from 'components/shared/Checkbox'
import ButtonMenu from 'components/shared/ButtonMenu'
import CheckboxSelector from 'components/shared/CheckboxSelector'
import Icon from 'components/shared/Icon'
import Tag from 'components/shared/Tag'
import SearchInput from 'components/shared/SearchInput'
import StickyHeader from 'components/shared/StickyHeader'

const FulfillmentFilters = ({ className }) => {
  const [filterExpanded, setFilterExpanded] = useState(false)
  const {
    typeFilters,
    statusFilters,
    updateStatusFilters,
    clearFilters,
    keywordFilter,
    setKeywordFilter
  } = useContext(FulFillmentsContext)
  const checkboxClasses = "flex align-items-center ps-4 pe-3"

  useEffect(() => {
    setFilterExpanded(typeFilters.length > 0)
  }, [typeFilters, statusFilters])

  return (
    <StickyHeader
      className={classNames({
        [className]: className,
        'gap-2': true,
        'bg-shade-1 pe-2 ps-3': true,
      })}
    >
      <SearchInput
        id='keyword'
        className='grow'
        defaultValue={keywordFilter}
        onChange={(value) => setKeywordFilter(value)}
        placeholder='Filter by keyword'
      />
      <CheckboxSelector
        id='fulfillment-statuses'
        label={typeFilters.length ? `Filters Applied: ${typeFilters.length}` : 'Filter by status'}
        theme={BRAND}
        isExpanded={filterExpanded}
        shadow={2}
        expandFromRightToLeft={true}
      >
        <ButtonMenu
          dataTestId="fulfillment-statuses-menu"
          direction={COLUMN}
        >
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            size={TINY}
            onClick={() => clearFilters()}>
            <span className='text-left grow'>
              Reset filters
            </span>
            <Icon type={CLOSE} size={17} />
          </Button>
          {Object.entries(FULFILLMENT_STATUSES).map(([_, value]) => (
            <Checkbox
              key={value}
              id={value}
              label={(<Tag text={getFriendlyName(value)} size={MEDIUM} theme={value} />)}
              onChange={(e) => { updateStatusFilters(value) }}
              checked={statusFilters.includes(value)}
              className={checkboxClasses}
            />
          ))}
        </ButtonMenu>
      </CheckboxSelector>
    </StickyHeader>
  )
}

export default FulfillmentFilters
