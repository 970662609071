import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import htmlParser from 'html-react-parser'

import getDatabase from 'data/getDatabase'

import { BRAND, COLUMN, SLIDE_UP, NEUTRAL, NOTIFY, BACK, NEXT, LARGE } from 'components/constants'
import { DISCONNECT_REGISTER } from 'constants/views'
import { START_SHIFT } from 'components/shifts/constants'
import { PARKED } from 'components/orders/constants'
import { SHIFT_USER } from 'components/shifts/constants'
import { PASSCODE } from 'components/users/constants'

import authenticatedFetch from 'data/api/authenticatedFetch'
import getLocalStorage from 'data/helpers/getLocalStorage'

import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import ButtonMenu from 'components/shared/ButtonMenu'
import Banner from 'components/shared/Banner'
import SetViewId from 'components/navigation/actions/SetViewId'
import ShiftUser from 'components/shifts/StartShiftFlow/ShiftUser'
import PasscodeForm from 'components/users/PasscodeForm'
import Loader from 'components/shared/Loader'
import Main from 'components/shared/Main'
import Button from 'components/shared/Button'
import ConfirmationModal from 'components/shared/ConfirmationModal'

const DisconnectRegister = ({ initialAnimation }) => {
  const dispatch = useDispatch()
  const online = useSelector((state) => state.online)
  const users = useSelector((state) => state.users)
  const register = useSelector((state) => state.register)

  const [animation, setAnimation] = useState(initialAnimation)
  const [viewId, setViewId] = useState(null)
  const [user, setUser] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)
  const [errorDisconnecting, setErrorDisconnecting] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [orders] = useState(getLocalStorage('orders', []))
  const [shifts] = useState(getLocalStorage('shifts', []))

  const ordersNotSynced = orders.filter((order) => order.sync === true)
  const parkedOrders = orders.filter((order) => order.orderType === PARKED)
  const shiftsNotSynced = shifts.filter((shift) => shift.sync === true)

  function disconnectRegister() {
    if (hasUnsynedData()) {
      if (online) disconnectRequest()
    } else {
      if (online) {
        disconnectRequest()
      } else {
        setConfirmationMessage(
          `You are currently offline, so we can't fully disconnect this register. <br /><br />
          If you continue to disconnect this register, the connection will be reset on this end, but the register's connection status will have to be reset manually in the backend before you can connect to it again. <br /><br />
          Do you want to continue to disconnect this register?`
        )
        setShowConfirmation(true)
      }
    }
  }

  function disconnectRequest() {
    setDisconnecting(true)
    setErrorDisconnecting(false)
    authenticatedFetch(
      'registers/' + register.id,
      {
        method: 'PATCH',
        body: JSON.stringify({ connected: false }),
      },
      (_json) => {
        wipeJSData()
      },
      handleErrorDisconnecting, // Error
      handleFailure, // Offline
      handleFailure, // Auth failue
    )
  }

  function wipeJSData() {
    window.localStorage.clear()
    getDatabase().delete()
    window.location.reload()
  }

  function handleFailure() {
    setConfirmationMessage(
      `This register has lost its connection to the backend, so we can't fully disconnect this register. <br /><br />
      If you continue to disconnect this register, the connection will be reset on this end, but the register's connection status will have to be reset manually in the backend before you can connect to it again. <br /><br />
      Do you want to continue to disconnect this register?`
    )
    setShowConfirmation(true)
    setErrorDisconnecting(true)
    setDisconnecting(false)
  }

  function handleErrorDisconnecting() {
    setErrorDisconnecting(true)
    setDisconnecting(false)
  }

  function back() {
    dispatch(SetViewId(START_SHIFT))
  }

  function hasUnsynedData() {
    return ordersNotSynced.length > 0 || shiftsNotSynced.length > 0
  }

  function warningMessage() {
    if (hasUnsynedData()) {
      return 'Warning: You have data that has not synced to the backend yet'
    } else {
      return 'Warning: Any parked orders or unsynchronised records will be lost!'
    }
  }

  function instructionMessage() {
    if (!hasUnsynedData()) return ''
    if (online) {
      return 'Please wait for the data to sync and then try again.'
    } else {
      return 'This register is offline and there is data still waiting to be synced.<br />Please go online and sync the data before first.'
    }
  }

  if (showConfirmation) {
    return (
      <ConfirmationModal
        onClose={() => {
          setErrorDisconnecting(false)
          setConfirmationMessage('')
          setShowConfirmation(false)
        }}
        onSubmit={wipeJSData}
        message={confirmationMessage}
      />
    )
  }

  switch (viewId) {
    case PASSCODE:
      return (
        <PasscodeForm
          animation={animation}
          user={user}
          onSuccess={() => setViewId(DISCONNECT_REGISTER)}
          onBack={() => {
            setViewId(SHIFT_USER)
            setAnimation(BACK)
          }}
        />
      )
    case DISCONNECT_REGISTER:
      return (
        <View theme={NEUTRAL} shade={1} animation={animation}>
          {disconnecting && <Loader zIndex={3} />}
          <Topbar
            padding={3}
            transitional={true}
            className='ps-4'
            onBack={disconnecting ? undefined : () => {
              dispatch(SetViewId(START_SHIFT))
            }}
          />
          <Main center={true} maxWidth={600} gap={3} className="flex col justify-center expand height-minus-topbar flex col">
            <div className="flex col align-center">
              <header className="mb-3">
                <h2>Disconnect Register</h2>
              </header>
              <section className='relative z-0 expand-x overflow-y-auto'>
                <h3 className="color-notify-5 mb-3 font-size-5">{warningMessage()}</h3>
                <p className="mb-4">{htmlParser(instructionMessage())}</p>
                <p className="mb-1">Parked Orders: <strong>{parkedOrders.length}</strong></p>
                <p className="mb-1">Unsynchronised Orders: <strong>{ordersNotSynced.length}</strong></p>
                <p className="mb-1">Unsynchronised Shifts: <strong>{shiftsNotSynced.length}</strong></p>
                <ButtonMenu className='gap-2 rounded mt-5'>
                  <Button
                    theme={NOTIFY}
                    shade={3}
                    shadow={true}
                    rounded={4}
                    padding={5}
                    size={LARGE}
                    disabled={hasUnsynedData()}
                    onClick={disconnectRegister}
                  >
                    Wipe data and disconnect
                  </Button>
                  <Button
                    theme={BRAND}
                    shade={5}
                    rounded={4}
                    padding={5}
                    size={LARGE}
                    onClick={back}
                  >
                    Cancel
                  </Button>
                </ButtonMenu>
              </section>
            </div>
          </Main>
          {errorDisconnecting && (
            <Banner
              direction={COLUMN}
              animation={SLIDE_UP}
              theme={NOTIFY}
              shade={3}
              message={'We encountered an error disconnecting your register. Please try again.'}
            />
          )}
        </View>
      )
    default:
      return (
        <ShiftUser
          fetchingUsers={false}
          users={users}
          onBack={() => {
            dispatch(SetViewId(START_SHIFT))
          }}
          onSetUser={(user) => {
            setUser(user)
            setAnimation(NEXT)
            setViewId(PASSCODE)
          }}
        />
      )
  }
}

export default DisconnectRegister
