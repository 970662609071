import { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'

import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchOutlets } from 'data/api/fetchOutlets'

import getSyncTimeout from 'data/helpers/getSyncTimeout'
import setLocalStorage from 'data/helpers/setLocalStorage'
import SetSyncInProgress from 'components/online/actions/SetSyncInProgress'
import SetOutlets from 'components/outlets/actions/SetOutlets'
import SetOutlet from 'components/outlets/actions/SetOutlet'

const useSyncOutlets = ({ poll = false, executeOnLoad = true }) => {
  const dispatch = useDispatch()
  const syncTimeout = getSyncTimeout()
  const outlets = useSelector((state) => state.outlets)
  const currentOutlet = useSelector((state) => state.outlet)
  const [fetching, setFetching] = useState(false)

  let syncInterval = null

  useEffect(() => {
    if (executeOnLoad === false) return

    if (navigator.onLine) {
      setFetching(true)
      syncOutlets()

      if (poll = true) triggerPolling()
    }
    return () => clearInterval(syncInterval)
  }, [])

  useEffect(() => {
    dispatch(SetSyncInProgress('outlets', fetching))
  }, [fetching])

  useEffect(() => {
    setLocalStorage('outlets', outlets)
    updateCurrentOutlet()
  }, [outlets])

  function triggerPolling() {
    syncInterval = setInterval(() => {
      fetchCacheVersions('outlets', () => {
        syncOutlets()
      })
    }, syncTimeout)
  }

  function syncOutlets(successCallback = undefined) {
    fetchOutlets((outlets) => {
      setFetching(false)
      dispatch(SetOutlets(outlets))
      setLocalStorage('outlets', outlets)
      if (successCallback) successCallback()
    }, () => {
      setFetching(false)
    })
  }

  function updateCurrentOutlet() {
    if (outlets.length === 0) return

    const currentOutlet = outlets.find((outlet) => outlet.current)
    if (currentOutlet) dispatch(SetOutlet(currentOutlet))
  }

  return {
    outlets,
    currentOutlet,
    fetching,
    triggerPolling,
    syncOutlets,
  }
}

export default useSyncOutlets
