import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { MEDIUM } from 'components/constants'
import { NEUTRAL } from 'components/constants'

import Input from 'components/shared/Input'
import FieldLabel from 'components/shared/FieldLabel'

import getAnimation from 'helpers/getAnimation'

const Field = ({
  animation,
  autoFocus,
  border,
  className,
  disabled = false,
  defaultValue = '',
  errorMsg,
  id,
  inputRef,
  icon,
  children,
  inputMode,
  label,
  max,
  name,
  min,
  step,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  onValidationCallback,
  pattern,
  placeholder,
  required,
  showRequiredLabel = true,
  size = MEDIUM,
  shade = 1,
  shadow = 1,
  theme = NEUTRAL,
  type = 'text',
  valid = true,
  width,
}) => {
  const [isValid, setValid] = useState(valid)
  useEffect(() => {
    setValid(valid)
  },[valid])
  
  return (
    <motion.div
      { ...getAnimation(animation) }
      className={classNames({
        'relative flex col gap-1': true,
        [className]: className,
      })}
    >
      <FieldLabel
        showRequiredLabel={showRequiredLabel}
        required={required}
        targetId={id}>
        {label}
      </FieldLabel>
      <Input
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        icon={icon}
        id={id}
        inputRef={inputRef}
        inputMode={inputMode}
        max={max}
        min={min}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        pattern={pattern}
        placeholder={placeholder}
        required={required}
        shade={shade}
        shadow={shadow}
        size={size}
        step={step}
        theme={theme}
        type={type}
        width={width}
        valid={isValid}
        onChange={onChange}
        onSetInvalid={() => {
          setValid(false)
          if (onValidationCallback) {
            onValidationCallback(false)
          }
        }}
        onSetValid={() => {
          setValid(true)
          if (onValidationCallback) {
            onValidationCallback(true)
          }
        }}
        className={classNames({
          'border': border === true,
        })}
      />
      {isValid === false && errorMsg && (
        <div className='color-notify-5 font-weight-1 mt-2 mb-2'>
          {errorMsg}
        </div>
      )}
      {children}
    </motion.div>
  )
}

export default Field
