import React from 'react'
import classNames from 'classnames'

import { BASE, LARGE } from 'constants/size'

import RadioInput from 'components/shared/RadioInput'
import ProductHeadline from './shared/ProductHeadline'
import ProductImage from './shared/ProductImage'
import ProductPrice from './shared/ProductPrice'

const ProductLineItem = ({
  size = BASE,
  product = {},
  name,
  onSelect,
  onClick,
  onBlur,
}) => {

  const Container = ({ children }) => {
    const commonStyleClasses = {
      'flex align-items-center gap-2 text-left': true,
      'input-height-5 font-size-3 ps-5': size === LARGE,
      'input-height-4 font-size-2 ps-4': size === BASE,
    }

    if (onSelect) return (
      <RadioInput
        className={classNames({
          ...commonStyleClasses,
          'hover:bg-shade-1': true,
        })}
        value={product.id}
        name={name}
        onBlur={() => onBlur()}
      >{children}</RadioInput>
    )
    if (onClick) return (
      <button
        className={classNames({ 
          ...commonStyleClasses, 
          'expand-x': true 
      })}
        type='button'
        dataid={product.id}
        onClick={onClick}
      >
        {children}
      </button>
    )
    else return (
      <div
        data-id={product.id}
        className={classNames({ ...commonStyleClasses })}
      >
        {children}
      </div>
    )
  }

  return (
    <Container>
      <ProductImage
        height={size === LARGE ? 100 : 70}
        imageSrc={product.image?.thumb_url}
      />
      <ProductHeadline
        size={size}
        product={product}
      />
      <ProductPrice product={product} />
    </Container>
  )
}

export default ProductLineItem
