import React from 'react'
import classNames from 'classnames'

import { BRAND, CHEVRON, SUCCESS } from 'components/constants'
import { FULFILLMENT_STATUSES } from 'components/fulfillments/constants'

import getFriendlyName from './helpers/getFriendlyName'

import Tag from 'components/shared/Tag'
import Icon from 'components/shared/Icon'
import ButtonLineItem from 'components/shared/ButtonLineItem'

const FulfillmentLineItem = ({
  fulfillment,
  first,
  last,
  gridTemplateColumns = '300px 1fr 2fr auto',
  onSetFulfillment
}) => {
  return (
    <ButtonLineItem
      dataId={fulfillment.order_reference_number}
      padding={4}
      gridTemplateColumns={gridTemplateColumns}
      onClick={onSetFulfillment}
      className={classNames({
        'bg-shade-0': true,
        'rounded-top-2': first === true,
        'rounded-bottom-2': last === true,
      })}
    >
      <span>
        <div className="font-size-1 color-shade-4">Order #</div>
        <div className="font-weight-3">{fulfillment.order_reference_number}</div>
      </span>
      <span>
        <div className="font-weight-3"><Tag text={getFriendlyName(fulfillment.type)} size={'SMALL'} theme={fulfillment.type} /></div>
      </span>
      <span>
        <div className="font-weight-3"><Tag text={getFriendlyName(fulfillment.status)} size={'SMALL'} theme={fulfillment.status === FULFILLMENT_STATUSES.READY_FOR_PICKUP || fulfillment.status == FULFILLMENT_STATUSES.COMPLETED ? SUCCESS : BRAND} /></div>
      </span>
      <Icon className='opacity-06' type={CHEVRON} size={14} rotate={270} />
    </ButtonLineItem>
  )
}

export default FulfillmentLineItem