import React from 'react'
import classNames from 'classnames'

import { TINY, SMALL } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'
import { BRAND, NEUTRAL } from 'components/constants'
import { CLOSE, COLUMN } from 'components/constants'
import { LAYBY, COMPLETE, PARKED } from 'components/orders/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import ButtonSelector from 'components/shared/ButtonSelector'
import Icon from 'components/shared/Icon'
import Tag from 'components/shared/Tag'
import SearchInput from 'components/shared/SearchInput'
import StickyHeader from 'components/shared/StickyHeader'

const OrderFiltersMenu = ({ className, keyword, onFilterType, orderType, onFilterByKeyword, }) => {
  return (
    <StickyHeader
      className={classNames({
        [className]: className,
        'gap-2': true,
        'bg-shade-1 pe-2 ps-3': true,
      })}
    >
      <SearchInput
        id='keyword'
        className='grow'
        defaultValue={keyword}
        onChange={(value) => onFilterByKeyword(value)}
        placeholder='Filter recent orders by order id, customer name, phone, email'
      />
      <ButtonSelector
        id='order-type'
        label='Order type'
        theme={orderType === LAYBY ? LAYBY : BRAND}
        selected={orderType}
        shadow={2}
        expandFromRightToLeft={true}
      >
        <ButtonMenu
          className='shadow-3 rounded-2 gap-1px overflow-hidden'
          direction={COLUMN}
        >
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            size={TINY}
            onClick={() => onFilterType(null)}>
            <span className='text-left grow'>
              Filter order type
            </span>
            <Icon type={CLOSE} size={17} />
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            onClick={() => onFilterType(null)}>
            <Tag
              text='Include all'
              size={SMALL}
            />
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            onClick={() => onFilterType(COMPLETE)}>
            <Tag text='Complete' size={SMALL} />
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            onClick={() => onFilterType(LAYBY)}>
            <Tag text='Layby' size={SMALL} theme={LAYBY} />
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            onClick={() => onFilterType(PARKED)}>
            <Tag text='Parked' size={SMALL} theme={CUSTOMER} />
          </Button>
        </ButtonMenu>
      </ButtonSelector>
    </StickyHeader>
  )
}

export default OrderFiltersMenu
