import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { BRAND, NEUTRAL } from 'constants/theme'
import { TINY } from 'constants/size'
import { COLUMN } from 'components/constants/'
import { SUPPORTED_FULFILLMENT_TYPES, FULFILLMENT_TYPES } from 'components/fulfillments/constants'

import getFriendlyName from 'components/fulfillments/helpers/getFriendlyName'

import usePrevious from 'hooks/usePrevious'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import ButtonSelector from 'components/shared/ButtonSelector'

const FulfillmentPicker = ({
  cartItem = {},
  outlets = [],
  onSetFulfillment,
  onDeleteFulfillment,
  open = false
}) => {
  const [selectedType, setSelectedType] = useState(null)
  const [selectedSourceOutlet, setSelectedSourceOutlet] = useState(null)
  const [selectedDestinationOutlet, setSelectedDestinationOutlet] = useState(null)

  useEffect(() => {
    if (cartItem.fulfillment !== undefined && open) {
      const sourceOutlet = outlets.find((outlet) => outlet.id === cartItem.fulfillment.sourceOutletId)
      const destinationOutlet = outlets.find((outlet) => outlet.id === cartItem.fulfillment.destinationOutletId)
      setSelectedType(cartItem.fulfillment.type)
      setSelectedSourceOutlet(sourceOutlet)
      setSelectedDestinationOutlet(destinationOutlet)
    }
  }, [open])

  // TODO: Improve this useEffect
  // Will improve this in a PR dedicated for frontend improvements
  // that would be once the backend is finalized
  const prevSelectedType = usePrevious(selectedType)
  useEffect(() => {
    if (prevSelectedType !== null && prevSelectedType !== undefined && selectedType === null) {
      onDeleteFulfillment({ itemId: cartItem.id })
    }

    if (selectedType !== null && selectedDestinationOutlet !== null && selectedSourceOutlet !== null) {
      onSetFulfillment({
        fulfillmentId: uuidv4(),
        itemId: uuidv4(),
        orderItemId: cartItem.id,
        sourceOutletId: selectedSourceOutlet.id,
        destinationOutletId: selectedDestinationOutlet.id,
        productSfid: cartItem.productId,
        quantity: cartItem.quantity,
        type: selectedType,
        status: 'requested'
      })
    }
  }, [selectedType, selectedDestinationOutlet, selectedSourceOutlet])

  function sourceOutlets() {
    const _outlets = outlets.filter((outlet) => outlet.has_stock_locations === true)
    if (selectedDestinationOutlet !== null) {
      return _outlets.filter((outlet) => outlet.id !== selectedDestinationOutlet.id)
    }
    return _outlets
  }

  function destinationOutlets() {
    if (selectedSourceOutlet !== null) {
      return outlets.filter((outlet) => outlet.id !== selectedSourceOutlet.id)
    }
    return outlets
  }

  function getOptionsHeadline(direction = "from") {
    if (selectedType === FULFILLMENT_TYPES.HOLD) {
      return `Pick up ${direction}`
    } else if (selectedType === FULFILLMENT_TYPES.TRANSFER) {
      return `Transfer ${direction}`
    } else if (selectedType === FULFILLMENT_TYPES.SPECIAL_ORDER) {
      return `Pick up ${direction}`
    }

    return null
  }

  function renderSourceAndTargetOutlets() {
    return (
      <div className="mt-4">
        {selectedType !== null && (
          <ButtonSelector
            id='fulfillment-type'
            shadow={2}
            theme={BRAND}
            fullWidth={true}
            label={selectedSourceOutlet ? selectedSourceOutlet.name : "Select"}
            headline={getOptionsHeadline('from')}
          >
            <ButtonMenu direction={COLUMN}>
              <Button
                className='justify-start'
                rounded={0}
                theme={NEUTRAL}
                size={TINY}
                onClick={() => {
                  setSelectedSourceOutlet(null)
                }}
              >
                Select Source
              </Button>
              {sourceOutlets().map((outlet) => (
                <Button
                  className='justify-start text-left'
                  rounded={0}
                  theme={NEUTRAL}
                  size={TINY}
                  key={outlet.id}
                  onClick={() => {
                    setSelectedSourceOutlet(outlet)
                    if (selectedType === FULFILLMENT_TYPES.HOLD || selectedType === FULFILLMENT_TYPES.SPECIAL_ORDER) {
                      setSelectedDestinationOutlet(outlet)
                    } else {
                      setSelectedDestinationOutlet(null)
                    }
                  }}
                >{outlet.name}</Button>
              ))}
            </ButtonMenu>
          </ButtonSelector>
        )}
        {selectedType === FULFILLMENT_TYPES.TRANSFER && (
          <div className="mt-2">
            <ButtonSelector
              id='fulfillment-type'
              shadow={2}
              theme={BRAND}
              fullWidth={true}
              label={selectedDestinationOutlet ? selectedDestinationOutlet.name : "Select"}
              headline="Transfer to"
            >
              <ButtonMenu direction={COLUMN}>
                <Button
                  className='justify-start'
                  rounded={0}
                  theme={NEUTRAL}
                  size={TINY}
                  onClick={() => {
                    setSelectedDestinationOutlet(null)
                  }}
                >
                  Select Destination
                </Button>
                {destinationOutlets().map((outlet) => (
                  <Button
                    className='justify-start text-left'
                    rounded={0}
                    theme={NEUTRAL}
                    size={TINY}
                    key={outlet.id}
                    onClick={() => setSelectedDestinationOutlet(outlet)}
                    headline={getOptionsHeadline('to')}
                  >{outlet.name}</Button>
                ))}
              </ButtonMenu>
            </ButtonSelector>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='flex col gap-2 ps-3 pb-3'>
      <div className="ps-2">
        <ButtonSelector
          id='fulfillment-type'
          label={selectedType == null ? "Select Type" : getFriendlyName(selectedType)}
          theme={BRAND}
          shadow={2}
          fullWidth={true}
        >
          <ButtonMenu direction={COLUMN}>
            <Button
              className='justify-start'
              rounded={0}
              theme={NEUTRAL}
              size={TINY}
              onClick={() => {
                setSelectedType(null)
                setSelectedSourceOutlet(null)
                setSelectedDestinationOutlet(null)
              }}
            >
              Select Type
            </Button>
            {Object.entries(SUPPORTED_FULFILLMENT_TYPES).map(([_, type]) => (
              <Button
                className='justify-start'
                rounded={0}
                key={type}
                theme={NEUTRAL}
                size={TINY}
                onClick={() => setSelectedType(type)}
              >
                {getFriendlyName(type)}
              </Button>))}
          </ButtonMenu>
        </ButtonSelector>
        {renderSourceAndTargetOutlets()}
      </div>
    </div>
  )
}

export default FulfillmentPicker