import {
  FULFILLMENT_STATUSES,
  FULFILLMENT_TYPES
} from 'components/fulfillments/constants'

export default function getFulfillmentFriendlyName(text) {
  switch(text) {
    case FULFILLMENT_TYPES.PICKUP:
      return "Click & Collect"
    case FULFILLMENT_TYPES.HOLD:
      return "Hold"
    case FULFILLMENT_TYPES.TRANSFER:
      return "Transfer"
    case FULFILLMENT_TYPES.SPECIAL_ORDER:
      return "Special Order"
    case FULFILLMENT_STATUSES.COMPLETED:
      return "Completed"
    case FULFILLMENT_STATUSES.PICKED:
      return "Picked"
    case FULFILLMENT_STATUSES.PACKED:
      return "Packed"
    case FULFILLMENT_STATUSES.DELIVERED:
      return "Delivered"
    case FULFILLMENT_STATUSES.OUT_OF_STOCK:
      return "Out Of Stock"
    case FULFILLMENT_STATUSES.REQUESTED:
      return "Requested"
    case FULFILLMENT_STATUSES.READY_FOR_PICKUP:
      return "Ready For Pickup"
    case FULFILLMENT_STATUSES.ORDERED:
      return "Ordered"
    case FULFILLMENT_STATUSES.SHIPPED:
      return "Shipped"
    case FULFILLMENT_STATUSES.MISSING:
      return "Missing"
    case FULFILLMENT_STATUSES.RECEIVED:
      return "Received"
    default:
      return text
  }
}