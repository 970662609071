import React from 'react'
import classNames from 'classnames'

const SettingLineItem = ({
  children,
  first = false,
  last = false,
  padding,
  className,
  active = false,
  gridTemplateColumns,
  onClick,
  dataId,
}) => {
  return (
    <button
      data-button-id={dataId}
      type='button'
      onClick={onClick}
      style={{
        gridTemplateColumns,
        minHeight: 'var(--sc-input-height-3)',
      }}
      className={classNames({
        'text-left expand-x pe-3': true,
        'font-size-2 bg-white hover:color-shade-3 transition-scale-shadow': true,
        'grid align-items-center': gridTemplateColumns,
        'bg-shade-1': active === true,
        'rounded-top-2': first === true,
        'rounded-bottom-2': last === true,
        ['ps-' + padding]: padding > 0,
        [className]: className,
      })}
    >
      {children}
    </button>
  )
}

export default SettingLineItem
