import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { BRAND } from 'constants/theme'
import { XXLARGE, HUGE } from 'constants/size'
import { NEXT, SLIDE_UP } from 'constants/motion'
import { CONFIRM, COLUMN } from 'components/constants'
import { REGISTER } from 'components/registers/constants'
import { START_SHIFT } from 'components/shifts/constants'

import { connectRegister } from 'data/api/connectRegister'
import { fetchRegisters } from 'data/api/fetchRegisters'
import { fetchStores } from 'data/api/fetchStores'

import useSyncProducts from 'hooks/useSyncProducts'
import useSyncPrintableTemplates from 'hooks/useSyncPrintableTemplates'
import useSyncOutlets from 'hooks/useSyncOutlets'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import Loader from 'components/shared/Loader'
import RegisterButtonMenu from './RegisterButtonMenu'
import StoreConnectLogo from 'components/shared/StoreConnectLogo'
import Topbar from 'components/navigation/Topbar'

import ResetViewParents from 'components/navigation/actions/ResetViewParents'
import SetRegister from 'components/registers/actions/SetRegister'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetSyncInProgress from 'components/online/actions/SetSyncInProgress'
import SetStore from 'components/stores/actions/SetStore'

import getAvailableRegisters from 'components/registers/helpers/getAvailableRegisters'

const Registers = () => {
  const dispatch = useDispatch()
  const [animation, setAnimation] = useState(NEXT)
  const [register, setRegister] = useState(useSelector((state) => state.register))
  const [viewId, setViewId] = useState(null)
  const [registers, setRegisters] = useState([])
  const [fetchingRegisters, setFetchingRegisters] = useState(true)

  const { fetchAndSync: fetchProducts } = useSyncProducts({ executeOnLoad: false })
  const { fetchPrintableTemplates } = useSyncPrintableTemplates({ executeOnLoad: false })
  const { syncOutlets: fetchOutlets } = useSyncOutlets({ executeOnLoad: false })

  useEffect(() => {
    dispatch(SetSyncInProgress('store', true))
    dispatch(SetSyncInProgress('printable_labels', true))

    // Has dispatching of products syncing inside
    fetchProducts()
    fetchStores((stores) => {
      const store = stores.find((store) => store.current)
      if (store) dispatch(SetStore(store))
      dispatch(SetSyncInProgress('store', false))
    })
    fetchOutlets((outlets) => {
      dispatch(SetOutlets(outlets))
      dispatch(SetOutlet(outlets.find((outlet) => !!outlet.current)))
    })
    fetchPrintableTemplates(() => {
      dispatch(SetSyncInProgress('printable_labels', false))
    })
    fetchRegisters((registers) => {
      setRegisters(registers)
      setFetchingRegisters(false)
    })
  }, [])

  function connect() {
    // A Register can only be connected once. We store the
    // register data in the client app state for use.
    connectRegister(register, () => {
      dispatch(SetRegister(register))
      dispatch(ResetViewParents())
      dispatch(SetViewAnimation(NEXT))
      dispatch(SetViewId(START_SHIFT))
    })
  }

  if (fetchingRegisters) return <Loader />

  switch (viewId) {
    case REGISTER:
      return (
        <Article
          className='expand'
          shade={6}
          theme={BRAND}
          animation={animation}
        >
          <Topbar
            className='absolute p-3'
            shade={6}
            transitional={true}
            theme={BRAND}
            onBack={() => setViewId(null)}
          />
          {registers.length === 0 && (
            <Container
              gap={5}
              maxWidth={700}
              center={true}
            >
              <Heading theme={BRAND}>
                All existing registers are connected
              </Heading>
              <p className='font-size-4'>
                Another register must be added in Salesforce to continue.
              </p>
            </Container>
          )}
          {registers.length > 0 && (
            <Container
              gap={5}
              maxWidth={700}
              center={true}
            >
              <Heading theme={BRAND}>
                Which register is this?
              </Heading>
              <RegisterButtonMenu
                shade={6}
                theme={BRAND}
                registers={getAvailableRegisters(registers)}
                style={{ maxHeight: 380 }}
                onClick={(register) => {
                  setRegister(register)
                  setViewId(CONFIRM)
                }}
              />
            </Container>
          )}
        </Article>
      )

    case CONFIRM:
      return (
        <Article
          className='expand'
          gridTemplateRows='auto 1fr auto'
          shade={6}
          theme={BRAND}
          animation={animation}
        >
          <Topbar
            className='p-3'
            transitional={true}
            shade={6}
            theme={BRAND}
            onBack={() => setViewId(null)}
          />
          <Container maxWidth={740} center={true}>
            <Heading theme={BRAND}>
              Are you sure you want to connect {register.name}?
            </Heading>
          </Container>
          <ButtonMenu direction={COLUMN}>
            <Button
              className='gap-4'
              rounded={0}
              shadow={true}
              size={HUGE}
              animation={SLIDE_UP}
              shade={5}
              theme={BRAND}
              disabled={fetchingRegisters}
              onClick={() => connect()}
            >
              {fetchingRegisters && <Loader />}
              {!fetchingRegisters && <><StoreConnectLogo width={50} /> Confirm connection</>}
            </Button>
          </ButtonMenu>
        </Article>
      )
    default:
      return (
        <Article
          className='expand'
          gridTemplateRows='1fr'
          shade={6}
          theme={BRAND}
          animation={animation}
        >
          <Topbar
            className='absolute p-3'
            shade={6}
            transitional={true}
          />
          <Container maxWidth={670} center={true}>
            <Button
              className='gap-4'
              size={XXLARGE}
              shadow={1}
              rounded={6}
              shade={5}
              theme={BRAND}
              onClick={() => setViewId(REGISTER)}
            >
              <StoreConnectLogo width={50} />
              Connect Register
            </Button>
          </Container>
        </Article>
      )
  }
}

export default Registers
