export default function calculateCartDiscounts(cart, maxDiscountPercent = 25, discount = false) {
  let cartDiscount = 0;
  if (discount !== false) {
    cartDiscount = discount;
  } else {
    cart.find((cartItem) => {
      if (cartItem.order_level_discount) {
        cartDiscount = cartItem.order_level_discount
      }
    })
  }
  const lineItemDiscounts = cart.reduce((num, item) => num + (item.discount * item.quantity), 0),
        cartTotal = cart.reduce((num, item) => num + (item.price * item.quantity), 0),
        lineItemTotalPercentage = (lineItemDiscounts / cartTotal) * 100;
  if((cartDiscount + lineItemTotalPercentage) > maxDiscountPercent) {
    const differenceInValue = (cartDiscount + lineItemTotalPercentage) - maxDiscountPercent;
    cartDiscount -= differenceInValue;
  }
  return cartDiscount
}