import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { NEUTRAL, BACK, NEXT, SLIDE_UP, COLUMN, NOTIFY } from 'components/constants'
import { PRINTER_TYPES, PRINTABLE_TYPES } from 'constants/settings'

import useSyncProducts from 'hooks/useSyncProducts'

import Banner from 'components/shared/Banner'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import ProductSelection from 'components/printableLabels/ProductSelection'
import PrintDetails from 'components/printableLabels/PrintDetails'

import useHandlePrinting from 'hooks/useHandlePrinting'

const PrintableLabels = ({ initialAnimation }) => {
  const { products } = useSyncProducts({ poll: false })

  const [animation, setAnimation] = useState(initialAnimation)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isLoading, setLoading] = useState(products.length === 0)
  const [showPrintDetails, setShowPrintDetails] = useState(false)
  const [selectedLabelTemplate, setSelectedLabelTemplate] = useState(null)
  const [previewBlob, setPreviewBlob] = useState(null)
  const [printQuantity, setPrintQuantity] = useState(1)
  const [keyword, setKeyword] = useState('')

  const outlets = useSelector((state) => state.outlets)

  const {
    deviceError,
    printingTemplates,
    deviceAvailable,
    print,
  } = useHandlePrinting(PRINTER_TYPES.LABEL, PRINTABLE_TYPES.PRODUCT)

  useEffect(() => {
    if (products.length > 0 && printingTemplates.length > 0) {
      setLoading(false)
    }

    // After label templates got loaded set a selecte/setd label right away
    // to avoid an empty printing details screen
    if (printingTemplates.length > 0) {
      setSelectedLabelTemplate(printingTemplates[0])
    }
  }, [products, printingTemplates])


  function onPrint() {
    print(selectedProducts, selectedLabelTemplate, printQuantity)
  }

  return (
    <View className="expand-y expand-x" theme={NEUTRAL} shade={1} animation={animation}>
      <Topbar
        className='border-bottom'
        shade={0}
        theme={NEUTRAL}
      />
      {showPrintDetails === false && (
        <ProductSelection
          cartItems={[]}
          products={products}
          outlets={outlets}
          loading={isLoading}
          currentOutlet={outlets.find((outlet) => !!outlet.current)}
          onAddToCart={() => null}
          limitedColumns={true}
          layoutSelectable={false}
          preSelectedProducts={selectedProducts}
          batchSelectable={true}
          batchSelectedAction={() => {
            setShowPrintDetails(true)
            setAnimation(NEXT)
          }}
          defaultKeyword={keyword}
          onKeywordChange={(keyword) => setKeyword(keyword)}
          onSelectedProductsChange={(selectedProducts) => setSelectedProducts(selectedProducts)}
        />
      )}
      {showPrintDetails === true && (
        <PrintDetails
          printError={deviceError}
          printerAvailable={deviceAvailable}
          animation={animation}
          labelTemplates={printingTemplates}
          selectedProducts={selectedProducts}
          selectedLabelTemplate={selectedLabelTemplate}
          setSelectedLabelTemplate={setSelectedLabelTemplate}
          printQuantity={printQuantity}
          onQuantityChange={(quantity) => {
            setPrintQuantity(quantity)
          }}
          onPrint={onPrint}
          onBack={() => {
            setAnimation(BACK)
            setShowPrintDetails(false)
            setPreviewBlob(null)
          }}
        />
      )}

      {(deviceError !== null || deviceAvailable === false) && (
        <Banner
          direction={COLUMN}
          animation={SLIDE_UP}
          theme={NOTIFY}
          shade={3}
          message={deviceError}
        />
      )}
    </View>
  )
}

export default PrintableLabels